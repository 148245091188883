import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Common/Button/Button';
import Card from '../../../components/Common/Card/Card';
import Table from '../../../components/Common/Table/Table';
import Actions from '../../../store/redux/actions';
import checkClaims from '../../../hooks/useCheckClaims';
import Claims from '../../../utils/Claims';
import HeadTags from '../../../components/Common/HeadTags/HeadTags';
import {
  ButtonSize,
  ButtonType,
  InputType,
  TextColors,
} from '../../../utils/ComponentEnums';
import InputLabelOnBorder from '../../../components/Common/Input/InputLabelOnBorder';
import DropdownWithSearch from '../../../components/Common/Dropdown/DropdownWithSearch';
import addDay from '../../../hooks/useAddDay';
import filterTimeSet from '../../../hooks/useFilterTimeSet';
import Toast from '../../../components/Common/Popup/Toast';
import SearchButton from '../../../components/Common/Button/SearchButton';
import { ActionFilterRules, Entities, UserStatus } from '../../../utils/Enum';

const List = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const companyList = useSelector((state) => state.company.getAll);
  const currentUserClaim = useSelector((state) => state.auth.claim);
  const navigate = useNavigate();
  const [filterData, setFilterData] = useState({});
  const [dateChanged, setDateChanged] = useState(false);
  const [mountStatus, setMountStatus] = useState(null);

  useEffect(() => {
    getList();
  }, [filterData, currentUserClaim]);

  useEffect(() => {
    const { strTime, endTime, strView, endView } = filterTimeSet(null, true);

    setFilterData({
      ...filterData,
      startDate: null,
      endDate: null,
      startDateView: null,
      endDateView: null,
    });
    setMountStatus(true);
  }, []);

  const getList = () => {
    if (
      checkClaims(currentUserClaim, Claims.backofficeClaims.company.getAll) &&
      Object.keys(filterData).length > 0 &&
      dateChanged === false
    ) {
      dispatch(Actions.companyActions.getAllAction(filterData));
    }
  };

  useEffect(() => {
    const onKeyPress = (e) => {
      if (e.key === 'Enter') {
        dispatch(Actions.companyActions.getAllAction(filterData));
      }
    };
    window.addEventListener('keydown', onKeyPress);
    return () => {
      window.removeEventListener('keydown', onKeyPress);
    };
  }, [filterData]);

  const headers = [
    { key: 'id', label: 'Id' },
    { key: 'name', label: t('company.companyName') },
    { key: 'email', label: t('company.companyEmail') },
    { key: 'taxNo', label: t('company.taxNo') },
    { key: 'image', label: t('company.logo') },
    { key: 'address', label: t('company.address') },
    { key: 'companyAttendeeCount', label: t('company.companyAttendeeCount') },
    { key: 'companyMamangerCount', label: t('company.companyMamangerCount') },
    { key: 'status', label: t('company.companyStatus') },
    { key: 'createdAt', label: t('common.created_at') },
    { key: 'createdBy', label: t('common.created_by') },
    { key: 'updatedAt', label: t('common.updated_at') },
    { key: 'updatedBy', label: t('common.updated_by') },
  ];
  const createAction = (id) => {
    navigate('/company-order-create', { state: { id: id } });
  };
  const editAction = (val) => {
    navigate('/company-update', { state: { id: val.id } });
  };
  const removeAction = (id) => {
    dispatch(Actions.companyActions.removeAction(id));
  };
  const editSupAction = (id) => {
    navigate('/company-order-update', { state: { id: id.id } });
  };
  const forwardAction = (id, supId) => {
    navigate(`/company-order-list`, { state: { id: id, supId: supId } });
  };
  const showDetailsAction = (id) => {
    window.open(`/company-reporting?id=${id}`, '_blank');
  };

  const onSearch = (e) => {
    e.preventDefault();
    dispatch(Actions.companyActions.getAllAction(filterData));
    setDateChanged(!dateChanged);
  };

  const onChangeText = (e) => {
    e.preventDefault();
    const { id, value, type } = e.target;
    if (value !== '') {
      resetMe(id, type === 'number' ? parseInt(value) : value);
    } else {
      setFilterData((prevState) => {
        const { [id]: _, ...rest } = prevState;
        return rest;
      });
    }
  };

  const onChangeDate = (e) => {
    e.preventDefault();
    const { id, value } = e.target;
    if (value !== '') {
      const [year, month, day] = value.split('-').map(Number);
      const normalDateTime = addDay(
        `00:00`,
        `${day}-${month}-${year}`,
        false,
        false,
        true
      );
      const today = new Date();
      const selectedDate = new Date(value);
      const utcTime = new Date(
        selectedDate.getTime() + selectedDate.getTimezoneOffset() * 60000
      );
      if (id === 'startDate') {
        setFilterData((prevState) => {
          const newFilterData = {
            ...prevState,
            [id]: normalDateTime,
            [`${id}View`]: value,
          };

          if (utcTime > today || utcTime > new Date(prevState.endDate)) {
            const { endDate, endDateView, ...rest } = newFilterData;
            return rest;
          }

          return newFilterData;
        });
      } else if (id === 'endDate') {
        setFilterData((prevState) => {
          const newFilterData = {
            ...prevState,
            [id]: normalDateTime,
            [`${id}View`]: value,
          };

          if (utcTime < new Date(prevState.startDate)) {
            const { startDate, startDateView, ...rest } = newFilterData;
            return rest;
          }

          return newFilterData;
        });
      }
      setDateChanged(true);
    } else {
      setFilterData((prevState) => {
        const { [id]: _, [`${id}View`]: __, ...rest } = prevState;
        return rest;
      });
      setDateChanged(true);
    }
  };

  function resetMe(key, value) {
    if (key !== undefined) {
      setFilterData((prevFilterData) => ({
        ...prevFilterData,
        [key]: value,
      }));
    }
    if (!dateChanged && key !== undefined) {
      setFilterData((prevFilterData) => ({
        ...prevFilterData,
        startDate: null,
        startDateView: null,
        [key]: value,
      }));
      setDateChanged(true);
    }
  }

  const handleDropdown = (propName, value) => {
    resetMe(propName, `${value}`);
  };

  const handleResetDropdown = (propName) => {
    setFilterData((prevState) => {
      const { [propName]: _, ...rest } = prevState;
      return rest;
    });
    setDateChanged(true);
  };

  useEffect(() => {
    if (dateChanged) {
      const hasNullOrUndefined = Object.values(filterData).some(
        (value) => value !== null || value !== undefined
      );
      setMountStatus(!hasNullOrUndefined);
    }
  }, [filterData, dateChanged]);

  useEffect(() => {
    return () => {
      dispatch(Actions.companyActions.cleanState());
    };
  }, []);

  const content = (
    <div className="container-fluid">
      <div className="d-sm-flex align-items-center justify-content-between mb-1 mb-md-2">
        <HeadTags hSize={1} text={t('company.company_list')} strong />
      </div>
      <div className="row">
        <div className="col-12">
          <Card
            body={
              <React.Fragment>
                <div className="d-flex flex-column flex-md-row align-items-center justify-content-between mb-4 form-div-border">
                  <HeadTags
                    hSize={6}
                    strong
                    text={t('cardHeaderFilter.filter')}
                  />
                  <div className="col-12 col-md-2 d-flex justify-content-center mb-1">
                    {checkClaims(
                      currentUserClaim,
                      Claims.backofficeClaims.company.create
                    ) && (
                      <Button
                        type={ButtonType.Successbg}
                        text={t('company.add_new_company')}
                        textColor={TextColors.Light}
                        buttonCol={12}
                        onClick={() => navigate('/company-create')}
                      />
                    )}
                  </div>
                </div>
                <div className="d-sm-flex align-items-center justify-content-end">
                  <SearchButton
                    stateName={'company'}
                    size={ButtonSize.Small}
                    bold
                    onClick={onSearch}
                    filterChanged={dateChanged}
                    mountingStatus={mountStatus}
                  />
                </div>
                <div className="row my-2">
                  <div className="col-lg-3">
                    <InputLabelOnBorder
                      id={'name'}
                      inputType={InputType.Text}
                      labelText={t('company.companyName')}
                      inputValue={filterData.name || ''}
                      onChange={onChangeText}
                    />
                  </div>
                  <div className="col-lg-3">
                    <InputLabelOnBorder
                      id={'startDate'}
                      inputType={InputType.Date}
                      labelText={t('common.start_date')}
                      inputValue={filterData.startDateView || ''}
                      onChange={onChangeDate}
                    />
                  </div>
                  <div className="col-lg-3">
                    <InputLabelOnBorder
                      id={'endDate'}
                      inputType={InputType.Date}
                      labelText={t('common.end_date')}
                      inputValue={filterData.endDateView || ''}
                      onChange={onChangeDate}
                    />
                  </div>
                  <div className="col-lg-3 mt-2">
                    <DropdownWithSearch
                      isSearchable
                      placeHolder={t('dropdownSelect.select_activity_status')}
                      options={UserStatus}
                      selectedProps={{
                        key: 'key',
                        value: 'value',
                      }}
                      onChange={(value) =>
                        handleDropdown('sType', parseInt(value))
                      }
                      onReset={() => handleResetDropdown('sType')}
                      isfilter
                    />
                  </div>
                </div>
              </React.Fragment>
            }
          />
          <Card
            body={
              <Table
                // searchAble
                data={companyList}
                headers={headers}
                page={Entities.order}
                forwardPage={Entities.company}
                striped
                bordered
                create={(val) => createAction(val)}
                createTxt="company.companyOrder"
                // editSup={(val) => editSupAction(val)}
                // editSupTxt="company.companyOrder"
                edit={(val) => editAction(val)}
                remove={(val) => removeAction(val)}
                forward={(val) => forwardAction(val.id, val.supId)}
                goDetails={(val) => showDetailsAction(val.id)}
                //supPage={Entities.order}
                currentUserClaim={currentUserClaim}
                idSearchOnly={true}
                exportName={Entities.company.exportName.company}
                filterActionsRule={true}
                filterRule={ActionFilterRules.company}
              />
            }
          />
        </div>
      </div>
      <Toast
        stateName={'company'}
        onClose={(val) => {
          if (val) {
            dispatch(Actions.companyActions.getAllAction(filterData));
          }
        }}
      />
    </div>
  );

  return checkClaims(
    currentUserClaim,
    Claims.backofficeClaims.company.getAll,
    content
  );
};
export default List;
