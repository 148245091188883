const EMAIL =
  /^[a-zA-Z0-9._%+-]+@[a-z-0-9.-]+\.(?:[a-z]{2}|com|org|net|edu|gov|mil|biz|info|mobi|name|aero|asia|jobs|museum|cafe|agency|pro|ai|shop)$/; // more extensions can be added
const PASSWORD = /^(?=.*?[A-Za-z0-9#!@$%^&*()+=])\S{1,20}$/;
const TEXT = /^(?=.*?[A-Za-z0-9#!@$%^&*()+=ıİşŞçÇüÜöÖğĞ])[\s\S]*$/;
const NAME = /^[A-Za-zıİşŞçÇüÜöÖğĞ\s]+$/;
const NUMBER = /^[1-9][0-9]*$/;
// const DATE = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;
// const TEL = /^\(?([0-9]{1})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
const TEL = /^(\d{3})(\d{3})(\d{2})(\d{2})$/;
const URL =
  /((([A-Za-z]{3,9}:(?:\/\/)?   )(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/;
const ImageExtensions = /(\.jpeg|\.png|\.jpg|\.webp|\.svg)$/i;
const CvExtensions = /(\.pdf|\.doc|\.docx)$/i;

const CSV = /\.csv$/i;

const FileExtensions =
  /(\.pdf|\.doc|\.docx|\.xls|\.xlsx|\.txt|\.rtf|\.ppt|\.pptx|\.csv)$/i;
const AllExtensions =
  /(\.pdf|\.doc|\.docx|\.xls|\.xlsx|\.txt|\.rtf|\.ppt|\.pptx|\.cvs|\.jpeg|\.png|\.jpg|\.webp)$/i;

export const REGEX = {
  EMAIL,
  PASSWORD,
  TEXT,
  NUMBER,
  // DATE,
  TEL,
  URL,
  ImageExtensions,
  CvExtensions,
  FileExtensions,
  AllExtensions,
  NAME,
  CSV,
};
