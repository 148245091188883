import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { InputType } from '../../../utils/ComponentEnums';
const Input = ({
  type,
  id,
  className,
  pattern,
  onChange,
  onFocus,
  placeholder,
  value,
  required = false,
  hidden,
  onBlur,
  icon,
  labelValue,
  labelFontType,
  error,
  onKeyPress,
  disabled,
  ref,
  style,
  checked,
  onClick,
  labelOnRight,
  textArea,
  requiredError,
  regexType,
  accept,
}) => {
  let checkIcon =
    icon !== undefined ? (
      <FontAwesomeIcon icon={icon} className="me-1" />
    ) : null;
  let checkRequiredField = required ? (
    <span className="text-danger fw-bold ms-1">*</span>
  ) : null;
  let errorField = ' border border-danger';
  switch (type) {
    case InputType.Checkbox:
      type = 'checkbox';
      className = `form-check-input border border-2 ${
        labelOnRight === true ? '' : 'ms-3 '
      } `;
      break;
    case InputType.Switch:
      type = 'checkbox';
      className = 'form-check-input';
      break;
    case InputType.Radio:
      type = 'radio';
      className = 'form-check-input';
      break;
    default:
      break;
  }
  const input = (
    <div>
      {labelValue !== undefined && labelOnRight === undefined && (
        <React.Fragment>
          {checkIcon}
          <label className={`${labelFontType} form-check-label`}>
            {labelValue}
          </label>
          {checkRequiredField}
        </React.Fragment>
      )}
      {textArea && (
        <React.Fragment>
          <textarea
            id={id}
            className={
              error !== undefined || requiredError !== undefined
                ? (className += errorField)
                : className
            }
            cols={textArea.col}
            rows={textArea.row}
            onChange={onChange}
            onFocus={onFocus}
            required={required}
            placeholder={placeholder}
            onBlur={onBlur}
            value={value}
            name={regexType}
            disabled={disabled}
          />
          <div className="text-center">{error || requiredError}</div>
        </React.Fragment>
      )}
      {textArea === undefined && (
        <React.Fragment>
          <input
            type={type}
            id={id}
            ref={ref}
            className={
              error !== undefined || requiredError !== undefined
                ? (className += errorField)
                : className
            }
            placeholder={placeholder}
            value={value}
            checked={checked}
            pattern={pattern}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            onClick={onClick}
            required={required}
            disabled={disabled}
            hidden={hidden}
            onKeyPress={onKeyPress}
            style={style}
            name={regexType}
            accept={accept}
          />
          {labelValue !== undefined && labelOnRight !== undefined && (
            <React.Fragment>
              {checkIcon}
              <label
                onClick={onClick}
                htmlFor={id}
                className={`${labelFontType} form-check-label ms-lg-2 ms-1`}
              >
                {labelValue}
              </label>
              {checkRequiredField}
            </React.Fragment>
          )}
          <div className="text-center">{error || requiredError}</div>
        </React.Fragment>
      )}
    </div>
  );
  return input;
};
export default Input;
