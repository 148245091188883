import React, { useState } from 'react';
import Papa from 'papaparse';
import { CSVLink } from 'react-csv';

const MergeCSV = () => {
  const [file1, setFile1] = useState(null);
  const [file2, setFile2] = useState(null);
  const [mergedData, setMergedData] = useState([]);
  const [keys, setKeys] = useState(['Name', 'Phone Number', 'Email Address']); // Default keys for uniqueness

  // Handle File Input
  const handleFileChange = (e, setFile) => {
    const file = e.target.files[0];
    if (file) setFile(file);
  };

  // Parse CSV File
  const parseCSV = (file) => {
    return new Promise((resolve, reject) => {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: (results) => resolve(results.data),
        error: (error) => reject(error),
      });
    });
  };

  // Merge CSV data and remove duplicates based on dynamic keys
  const mergeCSVData = (data1, data2, uniqueKeys) => {
    const allRows = [...data1, ...data2];
    const uniqueRows = allRows.reduce((acc, current) => {
      const isDuplicate = acc.some((item) =>
        uniqueKeys.every((key) => item[key] === current[key])
      );
      if (!isDuplicate) acc.push(current);
      return acc;
    }, []);
    setMergedData(uniqueRows);
  };

  const removeDuplicatesFromFirstList = (data1, data2, uniqueKeys) => {
    const filteredRows = data1.filter(
      (row1) =>
        !data2.some((row2) =>
          uniqueKeys.every((key) => row1[key] === row2[key])
        )
    );
    setMergedData(filteredRows); // Update the state with the filtered data
  };
  // Handle CSV Merge
  const handleMerge = async () => {
    if (file1 && file2) {
      const [data1, data2] = await Promise.all([
        parseCSV(file1),
        parseCSV(file2),
      ]);
      //mergeCSVData(data1, data2, keys);
      removeDuplicatesFromFirstList(data1, data2, keys);
    } else {
      alert('Please upload both CSV files.');
    }
  };

  // Handle dynamic key input change
  const handleKeysChange = (e, index) => {
    const newKeys = [...keys];
    newKeys[index] = e.target.value;
    setKeys(newKeys);
  };

  return (
    <div className="container-fluid">
      <h3 className="my-3">Merge Two CSV Files</h3>

      <div className="mb-3">
        <input
          type="file"
          accept=".csv"
          onChange={(e) => handleFileChange(e, setFile1)}
          className="form-control mb-2"
        />
        <input
          type="file"
          accept=".csv"
          onChange={(e) => handleFileChange(e, setFile2)}
          className="form-control"
        />
      </div>

      <div className="mb-3">
        <h5>Set Keys for Uniqueness Check</h5>
        {keys.map((key, index) => (
          <input
            key={index}
            type="text"
            value={key}
            onChange={(e) => handleKeysChange(e, index)}
            className="form-control mb-2"
            placeholder={`Key ${index + 1}`}
          />
        ))}
      </div>

      <button onClick={handleMerge} className="btn btn-primary mb-3">
        Merge Files
      </button>

      {mergedData.length > 0 && (
        <CSVLink
          data={mergedData}
          filename="merged_data.csv"
          className="btn btn-success"
        >
          Download Merged CSV
        </CSVLink>
      )}
    </div>
  );
};

export default MergeCSV;
