import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Actions from '../../../store/redux/actions';
import checkClaims from '../../../hooks/useCheckClaims';
import Claims from '../../../utils/Claims';
import Button from '../../../components/Common/Button/Button';
import Card from '../../../components/Common/Card/Card';
import Table from '../../../components/Common/Table/Table';
import HeadTags from '../../../components/Common/HeadTags/HeadTags';
import InputLabelOnBorder from '../../../components/Common/Input/InputLabelOnBorder';
import DropdownWithSearch from '../../../components/Common/Dropdown/DropdownWithSearch';
import {
  ButtonSize,
  ButtonType,
  InputType,
  TextColors,
} from '../../../utils/ComponentEnums';
import addDay from '../../../hooks/useAddDay';
import filterTimeSet from '../../../hooks/useFilterTimeSet';
import calculateUTC from '../../../hooks/useCalculateUTC';
import { Entities } from '../../../utils/Enum';
import SearchButton from '../../../components/Common/Button/SearchButton';
import Toast from '../../../components/Common/Popup/Toast';

const List = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const meetingSchemaList = useSelector((state) => state.meetingSchema.getAll);
  const currentUserClaim = useSelector((state) => state.auth.claim);
  const locationList = useSelector((state) => state.location.getAll);
  const sessionPackageList = useSelector(
    (state) => state.sessionPackage.getAll
  );
  const companyList = useSelector((state) => state.company.getAll);
  const staticDatas = useSelector((state) => state.staticData);
  const levelGroup = useSelector(
    (state) => state.preferenceOptions.preferenceLevelGroup
  );
  const ageInterval = useSelector((state) => state.ageInterval.getAll);
  const [filterData, setFilterData] = useState({});
  const [dateChanged, setDateChanged] = useState(false);
  const [mountStatus, setMountStatus] = useState(null);
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    const { strTime, endTime, strView, endView } = filterTimeSet(null, true);

    setFilterData({
      ...filterData,
      startDate: strTime,
      endDate: endTime,
      startDateView: strView,
      endDateView: endView,
    });
    setMountStatus(false);
  }, []);

  const getList = (func = false) => {
    if (
      checkClaims(
        currentUserClaim,
        Claims.backofficeClaims.meetingSchema.getAll
      ) &&
      Object.keys(filterData).length > 0 &&
      mountStatus !== null
    ) {
      setShowLoader(true);
      const action = Actions.meetingSchemaActions.getAllAction(filterData);
      if (dateChanged && func) {
        dispatch(action).then(() => setShowLoader(false));
        setDateChanged(!dateChanged);
      } else if (dateChanged === false) {
        dispatch(action).then(() => setShowLoader(false));
      }
    }
  };

  useEffect(() => {
    getList();
  }, [mountStatus]);

  useEffect(() => {
    const onKeyPress = (e) => {
      if (e.key === 'Enter') {
        getList(true);
      }
    };
    window.addEventListener('keydown', onKeyPress);
    return () => {
      window.removeEventListener('keydown', onKeyPress);
    };
  }, [filterData]);

  const onChangeText = (e) => {
    e.preventDefault();
    const { id, value, type } = e.target;
    if (value !== '') {
      resetMe(id, type === 'number' ? parseInt(value) : value);
    } else {
      setFilterData((prevState) => {
        const { [id]: _, ...rest } = prevState;
        setDateChanged(true);
        return rest;
      });
    }
  };

  const onChangeDate = (e) => {
    e.preventDefault();
    const { id, value } = e.target;
    if (value !== '') {
      const [year, month, day] = value.split('-').map(Number);
      const normalDateTime = addDay(
        `00:00`,
        `${day}-${month}-${year}`,
        false,
        false,
        true
      );
      const today = new Date();
      const selectedDate = new Date(value);
      const utcTime = new Date(
        selectedDate.getTime() + selectedDate.getTimezoneOffset() * 60000
      );
      if (id === 'startDate') {
        setFilterData((prevState) => {
          const newFilterData = {
            ...prevState,
            [id]: normalDateTime,
            [`${id}View`]: value,
          };

          if (utcTime > today || utcTime > new Date(prevState.endDate)) {
            const { endDate, endDateView, ...rest } = newFilterData;
            return rest;
          }

          return newFilterData;
        });
      } else if (id === 'endDate') {
        setFilterData((prevState) => {
          const newFilterData = {
            ...prevState,
            [id]: normalDateTime,
            [`${id}View`]: value,
          };

          if (utcTime < new Date(prevState.startDate)) {
            const { startDate, startDateView, ...rest } = newFilterData;
            return rest;
          }

          return newFilterData;
        });
      }
      setDateChanged(true);
    } else {
      setFilterData((prevState) => {
        const { [id]: _, [`${id}View`]: __, ...rest } = prevState;
        return rest;
      });
      setDateChanged(true);
    }
  };

  function resetMe(key, value) {
    if (key !== undefined) {
      setFilterData((prevFilterData) => ({
        ...prevFilterData,
        [key]: value,
      }));
    }
    if (!dateChanged && key !== undefined) {
      setFilterData((prevFilterData) => ({
        ...prevFilterData,
        startDate: null,
        startDateView: null,
        [key]: value,
      }));
      setDateChanged(true);
    }
  }

  const handleDropdown = (propName, value) => {
    resetMe(propName, `${value}`);
  };
  const onSearch = (e) => {
    e.preventDefault();
    getList(true);
  };

  const handleResetDropdown = (propName) => {
    setFilterData((prevState) => {
      const { [propName]: _, ...rest } = prevState;
      setDateChanged(true);
      return rest;
    });
  };

  const timeSlotsNormal = [];
  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += 30) {
      const val = `${hour.toString().padStart(2, '0')}:${minute
        .toString()
        .padStart(2, '0')}`;
      timeSlotsNormal.push({
        key: val,
      });
    }
  }
  const fixTime = (time) => {
    const today = new Date();
    const isoString = today.toISOString();
    const isoDateString = isoString.slice(0, 10);
    handleDropdown('sType3', `${calculateUTC(time, true)}:00`);
  };

  const editAction = (val) => {
    const url = new URL('/meeting-schema-update', window.location.origin);
    const data = {
      meetingSchemaId: val.id,
      interval: val.interval,
      dayOfWeekMeetingSchema: val.dayOfWeekMeetingSchema,
    };
    url.search = new URLSearchParams(data).toString();
    window.open(url.toString(), '_blank');
  };
  const removeAction = (id) => {
    dispatch(Actions.meetingSchemaActions.removeAction(id));
  };

  const selectedLanguageLevels = [];
  if (levelGroup && levelGroup?.adult && levelGroup?.kids) {
    Object.values(levelGroup).forEach((lang) => {
      if (staticDatas.LanguageLevels[lang]) {
        selectedLanguageLevels.push(...staticDatas.LanguageLevels[lang]);
      }
    });
  }

  const dayList = staticDatas.DayOfWeeks;

  const ageIntervalWithOptions = ageInterval?.map((item) => ({
    ...item,
    name: `${item.minAge} - ${item.maxAge}`,
  }));

  useEffect(() => {
    if (levelGroup === null) {
      dispatch(Actions.preferenceActions.getLevelGroupAction(4));
    }
  }, [levelGroup]);

  useEffect(() => {
    if (locationList.length === 0) {
      dispatch(Actions.locationActions.getAllAction());
    }
    if (sessionPackageList.length === 0) {
      dispatch(Actions.sessionPackageActions.getAllAction());
    }
    if (companyList.length === 0) {
      dispatch(Actions.companyActions.dropdownListAction());
    }
    if (ageInterval.length === 0) {
      dispatch(Actions.ageIntervalActions.getAllAction());
    }
    return () => {
      dispatch(Actions.meetingSchemaActions.cleanState());
    };
  }, []);

  const headers = [
    { key: 'id', label: t('common.meetingSchemaId') },
    { key: 'speakerName', label: t('meetingSchema.speaker') },
    { key: 'managerFullName', label: t('meetingSchema.manager') },
    { key: 'branch', label: t('meetingSchema.location') },
    {
      key: 'dayOfWeekMeetingSchema',
      label: t('meetingSchema.day_of_weeks'),
    },
    { key: 'interval', label: t('meetingSchema.interval') },
    { key: 'attendeeLimit', label: t('meetingSchema.attendee_limit') },
    { key: 'ageInterval', label: t('meetingSchema.age_interval') },
    { key: 'level', label: t('mySessionHistoryTable.level') },
    { key: 'meetingSchemaStartDate', label: t('common.start_date') },
    { key: 'endDate', label: t('common.end_date') },
    { key: 'companyName', label: t('company.companyName') },
    { key: 'createdAt', label: t('common.created_at') },
    { key: 'createdBy', label: t('common.created_by') },
    { key: 'updatedAt', label: t('common.updated_at') },
    // { key: 'updatedBy', label: t('common.updated_by') },
  ];

  const content = (
    <div className="container-fluid">
      <div className="d-flex align-items-center justify-content-center justify-content-md-start mb-1 mb-md-2">
        <HeadTags
          hSize={1}
          text={t('meetingSchema.meeting_schema_list')}
          strong
        />
      </div>
      <div className="col">
        <Card
          body={
            <React.Fragment>
              <div className="d-flex flex-column flex-md-row align-items-center justify-content-between mb-4 form-div-border">
                <HeadTags
                  hSize={6}
                  strong
                  text={t('cardHeaderFilter.filter')}
                />
                <div className="col-12 col-md-3 d-flex justify-content-center mb-1">
                  {checkClaims(
                    currentUserClaim,
                    Claims.backofficeClaims.meetingSchema.create
                  ) && (
                    <Button
                      type={ButtonType.Successbg}
                      text={t('meetingSchema.add_new_meeting_schema')}
                      textColor={TextColors.Light}
                      onClick={() => navigate('/meeting-schema-create')}
                      buttonCol={12}
                    />
                  )}
                </div>
              </div>
              <div className="d-sm-flex align-items-center justify-content-end">
                <SearchButton
                  stateName={'meetingSchema'}
                  size={ButtonSize.Small}
                  bold
                  onClick={onSearch}
                  filterChanged={dateChanged}
                  mountingStatus={mountStatus}
                />
              </div>
              <div className="row my-2">
                <div className="col-lg-3">
                  <InputLabelOnBorder
                    id={'name'}
                    inputType={InputType.Text}
                    labelText={t('meetingSchema.speaker')}
                    inputValue={filterData.name || ''}
                    onChange={onChangeText}
                  />
                </div>
                <div className="col-lg-3">
                  <InputLabelOnBorder
                    id={'startDate'}
                    inputType={InputType.Date}
                    labelText={t('common.start_date')}
                    inputValue={filterData.startDateView || ''}
                    onChange={onChangeDate}
                  />
                </div>
                <div className="col-lg-3">
                  <InputLabelOnBorder
                    id={'endDate'}
                    inputType={InputType.Date}
                    labelText={t('common.end_date')}
                    inputValue={filterData.endDateView || ''}
                    onChange={onChangeDate}
                  />
                </div>
                <div className="col-lg-3 mt-2">
                  <DropdownWithSearch
                    isSearchable
                    placeHolder={t('dropdownSelect.select_days')}
                    options={dayList}
                    selectedProps={{ key: 'key', value: 'value' }}
                    onChange={(value) => handleDropdown('sType', value)}
                    onReset={() => handleResetDropdown('sType')}
                    isfilter
                  />
                </div>
                <div className="col-lg-3 mt-lg-4 mt-2">
                  <DropdownWithSearch
                    isSearchable
                    placeHolder={t('dropdownSelect.select_level')}
                    options={selectedLanguageLevels}
                    selectedProps={{ key: 'key', value: 'key' }}
                    onChange={(value) => handleDropdown('sType2', value)}
                    onReset={() => handleResetDropdown('sType2')}
                    isfilter
                  />
                </div>
                <div className="col-lg-3 mt-lg-4 mt-2">
                  <DropdownWithSearch
                    isSearchable
                    placeHolder={t('dropdownSelect.start_time')}
                    options={timeSlotsNormal}
                    selectedProps={{ key: 'key', value: 'key' }}
                    onChange={(value) => {
                      fixTime(value);
                    }}
                    noTranslate={true}
                    onReset={() => handleResetDropdown('sType3')}
                    isfilter
                  />
                </div>
                <div className="col-lg-3 mt-lg-4 mt-2">
                  <DropdownWithSearch
                    isSearchable
                    placeHolder={t('dropdownSelect.select_location')}
                    options={locationList}
                    selectedProps={{ key: 'locationName', value: 'id' }}
                    onChange={(value) => handleDropdown('sType4', value)}
                    onReset={() => handleResetDropdown('sType4')}
                    isfilter
                  />
                </div>
                <div className="col-lg-3 mt-lg-4 mt-2">
                  <DropdownWithSearch
                    isSearchable
                    placeHolder={t('dropdownSelect.select_session_package')}
                    options={sessionPackageList}
                    selectedProps={{
                      key: 'person',
                      supKey: 'minute',
                      value: 'id',
                      display: 'displayName',
                    }}
                    onChange={(value) => handleDropdown('sType5', value)}
                    onReset={() => handleResetDropdown('sType5')}
                    isfilter
                  />
                </div>
                <div className="col-lg-3 mt-lg-4 mt-2">
                  <DropdownWithSearch
                    isSearchable
                    placeHolder={t('dropdownSelect.select_age_interval')}
                    options={ageIntervalWithOptions}
                    selectedProps={{
                      key: 'name',
                      value: 'id',
                    }}
                    onChange={(value) => handleDropdown('sType6', value)}
                    onReset={() => handleResetDropdown('sType6')}
                    isfilter
                  />
                </div>
                <div className="col-lg-3 mt-lg-4 mt-2">
                  <DropdownWithSearch
                    isSearchable
                    placeHolder={t('dropdownSelect.select_company')}
                    options={companyList}
                    selectedProps={{ key: 'companyName', value: 'companyId' }}
                    onChange={(value) => handleDropdown('sType7', value)}
                    onReset={() => handleResetDropdown('sType7')}
                    isfilter
                  />
                </div>
              </div>
            </React.Fragment>
          }
        />
        <Card
          body={
            <div>
              <Table
                // searchAble
                data={meetingSchemaList ? meetingSchemaList : []}
                showLoading={showLoader}
                headers={headers}
                striped
                bordered
                page={Entities.meetingSchema}
                edit={(val) => editAction(val)}
                remove={(val) => removeAction(val)}
                currentUserClaim={currentUserClaim}
                idSearchOnly={true}
                exportName={Entities.meetingSchema.exportName}
              />
            </div>
          }
        />
      </div>
      <Toast
        stateName={'meetingSchema'}
        onClose={(val) => {
          if (val) {
            dispatch(Actions.speakerActions.cleanState(true));
            dispatch(Actions.speakerActions.getAllAction(filterData));
          }
        }}
      />
    </div>
  );

  return checkClaims(
    currentUserClaim,
    Claims.backofficeClaims.meetingSchema.getAll,
    content
  );
};
export default List;
