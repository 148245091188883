import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment';
import 'moment-timezone';
import 'moment/locale/en-gb';
import 'moment/locale/tr';

import { useSelector, useDispatch } from 'react-redux';

import Button from '../Button/Button';
import Actions from '../../../store/redux/actions';
import { useTranslation } from 'react-i18next';
import calculateUTC from '../../../hooks/useCalculateUTC';
import getTimeZone from '../../../hooks/useGetTimeZone';
import {
  ButtonSize,
  ButtonType,
  TextColors,
} from '../../../utils/ComponentEnums';
import { useLocation } from 'react-router-dom';
import addDay from '../../../hooks/useAddDay';
import SubmitButton from '../Button/SubmitButton';
import Toast from '../Popup/Toast';

function Calendar() {
  const { t, i18n } = useTranslation();
  const [locale, setLocale] = useState(i18n.language);
  useEffect(() => {
    setLocale(i18n.language);
  }, [i18n.language]);
  useEffect(() => {
    moment.locale(locale);
  }, [locale]);

  const date = moment().locale(locale);
  const dispatch = useDispatch();
  const location = useLocation();
  const anotherDates = [];
  const reportDates = [];
  const speakerId = location?.state?.id;
  const [selectedWeek, setSelectedWeek] = useState(date);
  const [offsetDays, setOffsetDays] = useState(7);
  const [selectedDates, setSelectedDates] = useState({
    Intervals: [],
  });

  useEffect(() => {
    dispatch(Actions.speakerAvailabilityActions.getAllAction(speakerId));
  }, []);

  const [dragging, setDragging] = useState(false);
  const [startCell, setStartCell] = useState(null);
  const [currentTime, setCurrentTime] = useState(new Date());
  const [avabilityData, setAvabilityData] = useState({
    speakerId: speakerId,
    intervals: [],
  });

  const calendarRef = useRef(null);

  useEffect(() => {
    const isAvailable = selectedDates?.Intervals.map((intervals) => {
      const startResult = addDay(
        intervals.StartTime.split('T')[1],
        intervals.StartTime.split('T')[0],
        true
      );
      const endResult = addDay(
        intervals.EndTime.split('T')[1],
        intervals.EndTime.split('T')[0],
        true
      );

      const formattedResult = {
        StartTime: moment.utc(startResult).format('YYYY-MM-DDTHH:mm:ss'),
        EndTime: moment.utc(endResult).format('YYYY-MM-DDTHH:mm:ss'),
      };

      return formattedResult;
    });

    setAvabilityData({ ...avabilityData, intervals: isAvailable });
  }, [selectedDates?.Intervals]);

  const onCreate = async (e) => {
    e.preventDefault();
    await dispatch(
      Actions.speakerAvailabilityActions.createAction(avabilityData)
    );
  };
  const speakerAvailability = useSelector(
    (state) => state.speakerAvailability.getAll
  );

  useEffect(() => {
    const calendarEl = calendarRef.current;
    const timeSlotEl = calendarEl.querySelector(
      `[data-time="${currentTime.getHours()}:00"]`
    );

    if (timeSlotEl) {
      calendarEl.scrollTop = timeSlotEl.offsetTop - 75;
    }
  }, [currentTime]);

  for (let dayOffset = 0; dayOffset < offsetDays; dayOffset++) {
    const nextDate = moment(selectedWeek).add(dayOffset, 'days').toDate();

    reportDates.push(moment(nextDate));
    anotherDates.push(moment(nextDate));
    anotherDates.push(moment(...anotherDates, reportDates));
  }
  let timeSlots = [];
  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += 30) {
      timeSlots.push(
        `${hour.toString().padStart(2, '0')}:${minute
          .toString()
          .padStart(2, '0')}`
      );
    }
  }

  const handleMouseDown = (event, day, time) => {
    event.preventDefault();
    if (isMeetingCell(day, time)) {
      return;
    }
    setDragging(true);
    setStartCell({ day, time, end: time });
  };

  const handleMouseUp = () => {
    if (dragging && startCell) {
      setDragging(false);
      setStartCell(null);
    }
  };

  const handleMouseEnter = (day, time) => {
    if (dragging && startCell) {
      setStartCell((prevStartCell) => {
        if (isMeetingCell(day, time)) {
          return prevStartCell;
        }

        const startDay = moment(prevStartCell.day, 'YYYY-MM-DD');
        const endDay = moment(day, 'YYYY-MM-DD');
        const startTime = moment(prevStartCell.time, 'HH:mm');
        const endTime = moment(time, 'HH:mm');

        if (
          startDay.isSame(endDay) &&
          startDay.isValid() &&
          startTime.isValid() &&
          endTime.isValid()
        ) {
          const newSelection = [...selectedDates.Intervals];

          let intervalStartTime = moment(
            `${startDay.format('YYYY-MM-DD')}T${startTime.format('HH:mm:ss')}`
          );
          const intervalEndTime = moment(
            `${startDay.format('YYYY-MM-DD')}T${endTime.format('HH:mm:ss')}`
          );

          while (intervalStartTime.isBefore(intervalEndTime)) {
            const nextIntervalStartTime = intervalStartTime
              .clone()
              .add(30, 'minutes');

            if (nextIntervalStartTime.isAfter(intervalEndTime)) {
              break;
            }

            const selectedInterval = {
              StartTime: intervalStartTime.format('YYYY-MM-DDTHH:mm:ss'),
              EndTime: nextIntervalStartTime.format('YYYY-MM-DDTHH:mm:ss'),
            };

            const overlapsWithMeeting = isMeetingCell(
              day,
              intervalStartTime.format('HH:mm')
            );
            if (!overlapsWithMeeting) {
              const existingIntervalIndex = newSelection.findIndex(
                (item) =>
                  moment(item.StartTime).isSame(
                    moment(selectedInterval.StartTime)
                  ) &&
                  moment(item.EndTime).isSame(moment(selectedInterval.EndTime))
              );
              if (existingIntervalIndex === -1) {
                newSelection.push(selectedInterval);
              }
            }

            intervalStartTime = nextIntervalStartTime;
          }

          setSelectedDates({ ...selectedDates, Intervals: newSelection });
        }

        return {
          ...prevStartCell,
          end: endTime.format('HH:mm'),
        };
      });
    }
  };

  const handleCellClick = (day, time) => {
    if (isMeetingCell(day, time)) {
      return;
    }

    const clickedDay = moment(day, 'YYYY-MM-DD');
    const clickedTime = time;
    const startTime = moment(
      `${clickedDay.format('YYYY-MM-DD')} ${clickedTime}`,
      'YYYY-MM-DD HH:mm'
    );
    const endTime = startTime.clone().add(30, 'minutes');

    const selectedInterval = {
      StartTime: startTime.format('YYYY-MM-DDTHH:mm:ss'),
      EndTime: endTime.format('YYYY-MM-DDTHH:mm:ss'),
    };

    const existingIndex = selectedDates.Intervals.findIndex(
      (interval) =>
        interval.StartTime === selectedInterval.StartTime &&
        interval.EndTime === selectedInterval.EndTime
    );

    if (existingIndex !== -1) {
      const updatedSelection = [...selectedDates.Intervals];
      updatedSelection.splice(existingIndex, 1);
      setSelectedDates({
        speakerId: speakerId !== undefined ? speakerId : undefined,
        Intervals: updatedSelection,
      });
    } else {
      setSelectedDates((prevSelectedDates) => ({
        speakerId: speakerId !== undefined ? speakerId : undefined,
        Intervals: [...prevSelectedDates.Intervals, selectedInterval],
      }));
    }
  };

  const isMeetingCell = (day, time) => {
    return speakerAvailability?.meetings?.some((interval) => {
      const viewString = addDay(
        interval.interval,
        interval.date,
        false,
        false,
        false,
        false,
        false,
        false,
        true
      );

      return (
        time === calculateUTC(interval.interval.split('-')[0]) &&
        getDayOfWeek(day) === getDayOfWeek(viewString.resultDate)
      );
    });
  };

  const getHighlightClass = (day, time) => {
    const selectedDay = selectedDates?.Intervals.find((item) => {
      const startTime = moment(item.StartTime);
      const endTime = moment(item.EndTime);
      const dateTime = moment(
        `${day.format('YYYY-MM-DD')}T${time}`,
        'YYYY-MM-DDTHH:mm:ss'
      );

      return dateTime.isSameOrAfter(startTime) && dateTime.isBefore(endTime);
    });

    return selectedDay ? 'highlighted' : '';
  };

  const renderTableHeaders = () => {
    return (
      <tr
        className="text-muted  position-sticky bg-light top-0 "
        style={{ zIndex: '1' }}
      >
        <th
          style={{ width: '50px' }}
          className="p-0 m-0 text-center align-middle "
        >
          {`UTC ${getTimeZone()}`}
        </th>
        {reportDates.map((day) => (
          <th
            key={day.format('dddd, MMMM Do YYYY')}
            className="text-center p-0 m-0"
          >
            {day.format('ddd')}
            <br />
            {day.format('D')}
          </th>
        ))}
      </tr>
    );
  };

  function getDayOfWeek(dateString) {
    const daysOfWeek = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];
    const date = new Date(dateString);
    const dayOfWeek = date.getDay();
    return daysOfWeek[dayOfWeek];
  }
  const renderTableRows = () => {
    return timeSlots.map((time, i) => {
      return (
        <tr key={`${time}_${i + 2}`} className="p-0 m-0 ">
          <td
            key={`${time}_${i + 1}`}
            data-time={time}
            className="p-0 m-0 text-center  align-middle text-muted"
          >
            {time}
          </td>
          {reportDates.map((day, dayIndex) => {
            const highlightClass = getHighlightClass(day, time);
            const isAvailable = speakerAvailability?.avabilities?.some(
              (interval) => {
                const viewString = addDay(
                  interval.interval,
                  interval.date,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  true
                );

                return (
                  time === calculateUTC(interval.interval.split('-')[0]) &&
                  getDayOfWeek(day.format('YYYY-MM-DD')) ===
                    getDayOfWeek(viewString.resultDate)
                );
              }
            );
            const isMeeting = speakerAvailability?.meetings?.some(
              (interval) => {
                const viewString = addDay(
                  interval.interval,
                  interval.date,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  true
                );

                return (
                  time === calculateUTC(interval.interval.split('-')[0]) &&
                  getDayOfWeek(day.format('YYYY-MM-DD')) ===
                    getDayOfWeek(viewString.resultDate)
                );
              }
            );

            return (
              <td
                key={`${day.format('YYYY-MM-DD')}-${time}-${dayIndex}`}
                className={`p-0 m-0 ${highlightClass} ${
                  isAvailable && highlightClass
                    ? 'bg-danger'
                    : isMeeting
                    ? 'bg-info'
                    : isAvailable
                    ? 'bg-success'
                    : ''
                }`}
                contentEditable={false}
                style={{
                  height: '50px',
                  fontSize: '12px',
                }}
                onMouseDown={(e) =>
                  handleMouseDown(e, day.format('YYYY-MM-DD'), time)
                }
                onMouseUp={handleMouseUp}
                onMouseEnter={(e) =>
                  handleMouseEnter(day.format('YYYY-MM-DD'), time)
                }
                onClick={() => handleCellClick(day.format('YYYY-MM-DD'), time)}
              >
                <div className="d-flex text-white"></div>
              </td>
            );
          })}
        </tr>
      );
    });
  };

  const [mobileView, setMobileView] = useState(window.innerWidth < 577);

  useEffect(() => {
    const handleResize = () => {
      setMobileView(window.innerWidth < 577);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="container-fluid mt-1">
      <div className="d-flex align-items-center justify-content-between mb-1">
        {!mobileView && (
          <div className="d-flex justify-content-start">
            <div className="ms-2">
              <SubmitButton
                stateName={'speakerAvailability'}
                text={'button.create'}
                onClick={onCreate}
                objectLengthRule={
                  avabilityData?.intervals?.length > 0 ? false : true
                }
              />
            </div>
            <div className="ms-2">
              <Button
                type={ButtonType.Successbg}
                text={t('button.refresh')}
                textColor={TextColors.Light}
                onClick={() => {
                  dispatch(
                    Actions.speakerAvailabilityActions.getAllAction(speakerId)
                  );
                }}
              />
            </div>
          </div>
        )}
        {!mobileView && (
          <div className="d-flex flex-column align-items-end  p-0">
            <div className="d-flex justify-content-around">
              <div className="me-3">
                <span className="me-auto fw bold fs-6">
                  {t('speakerAvailability.availability_hour')}
                </span>
                <div>
                  <span className=" fw-bold">
                    {speakerAvailability?.totalSelectedHours}
                  </span>
                </div>
              </div>
              <div className="me-3">
                <span className="me-auto fw bold fs-6">
                  {t('speakerAvailability.meeting_hour')}
                </span>
                <div>
                  <span className=" fw-bold">
                    {speakerAvailability?.totalActiveMeetingHours}
                  </span>
                </div>
              </div>
              <div className="ms-2">
                <span className="me-auto fw bold fs-6">
                  {t('speakerAvailability.on_create_press')}
                </span>
                <div
                  className="d-flex justify-content-end mt-2"
                  style={{ fontSize: '0.9rem' }}
                >
                  <div className="ms-2">
                    <span className="badge bg-info fw-bold">
                      {t('button.meeting')}
                    </span>
                  </div>
                  <div className="ms-2">
                    <span className="badge bg-success fw-bold">
                      {t('button.active')}
                    </span>
                  </div>
                  <div className="ms-2">
                    <span className="badge bg-secondary  fw-bold">
                      {t('button.selected_cells')}
                    </span>
                  </div>
                  <div className="ms-2">
                    <span className="badge bg-danger  fw-bold">
                      {t('button.delete')}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {mobileView && (
        <div className="d-flex flex-column">
          <div
            className="col-lg-6 d-flex justify-content-between"
            style={{ fontSize: '1.2em' }}
          >
            <span className="fw-bold text-success">
              {t('speakerAvailability.availability_hour')}
            </span>
            <span className="fw-bold text-info">
              {t('speakerAvailability.meeting_hour')}
            </span>
          </div>
          <div
            className="col-lg-6 d-flex justify-content-between"
            style={{ fontSize: '1em' }}
          >
            <span className="">{speakerAvailability?.totalSelectedHours}</span>
            <span className="">
              {speakerAvailability?.totalActiveMeetingHours}
            </span>
          </div>
          <div
            className="col-lg-4 mt-1 d-flex justify-content-center"
            style={{ fontSize: '0.95em' }}
          >
            <span className="fw bold fs-6">
              {t('speakerAvailability.on_create_press')}
            </span>
          </div>
          <div
            className="d-flex justify-content-around mt-1 mb-3"
            style={{ fontSize: '0.85rem' }}
          >
            <span className="badge bg-info fw-bold">{t('button.meeting')}</span>
            <span className="badge bg-success fw-bold">
              {t('button.create')}
            </span>
            <span className="badge bg-secondary  fw-bold">
              {t('button.selected_cells')}
            </span>
            <span className="badge bg-danger  fw-bold">
              {t('button.delete')}
            </span>
          </div>
        </div>
      )}
      {mobileView && (
        <div className="d-flex justify-content-between mb-2">
          <div className="col-6 me-1">
            <Button
              type={ButtonType.Warning}
              size={ButtonSize.Small}
              text={t('button.refresh')}
              textColor={TextColors.Light}
              onClick={() => {
                dispatch(
                  Actions.speakerAvailabilityActions.getAllAction(speakerId)
                );
              }}
            />
          </div>
          <div className="col-6">
            <SubmitButton
              size={ButtonSize.Small}
              text={'button.create'}
              stateName={'speakerAvailability'}
              onClick={onCreate}
              objectLengthRule={
                avabilityData?.intervals?.length > 0 ? false : true
              }
            />
          </div>
        </div>
      )}
      <div
        className="col-12 table-responsive calendar-scroll"
        ref={calendarRef}
        style={{ width: '100%' }}
      >
        <table
          className="table table-sm  table-bordered"
          style={{ tableLayout: 'fixed', width: '100%' }}
        >
          <thead>{renderTableHeaders()}</thead>
          <tbody>{renderTableRows()}</tbody>
        </table>
      </div>
      <Toast
        id={'weeklyCalenderMeetingDetails'}
        stateName={'speakerAvailability'}
        onClose={(val) => {
          if (val) {
            setSelectedDates({
              Intervals: [],
            });
            setAvabilityData({
              speakerId: speakerId,
              intervals: [],
            });
            dispatch(
              Actions.speakerAvailabilityActions.getAllAction(speakerId)
            );
            dispatch(Actions.speakerAvailabilityActions.cleanState(true));
          }
        }}
      />
    </div>
  );
}

export default Calendar;
