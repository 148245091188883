import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Actions from '../../../store/redux/actions';
import Button from '../../../components/Common/Button/Button';
import Card from '../../../components/Common/Card/Card';
import HeadTags from '../../../components/Common/HeadTags/HeadTags';
import InputLabelOnBorder from '../../../components/Common/Input/InputLabelOnBorder';
import DropdownWithSearch from '../../../components/Common/Dropdown/DropdownWithSearch';
import {
  ButtonType,
  TextColors,
  InputType,
  ButtonIcon,
} from '../../../utils/ComponentEnums';
import Toast from '../../../components/Common/Popup/Toast';
import SubmitButton from '../../../components/Common/Button/SubmitButton';
import { TimesPerWeek } from '../../../utils/Enum';

const GiftOrder = ({ attendee, navigateMe }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const goBack = () => navigate(-1);
  const locationList = useSelector((state) => state.location.getAll);
  const staticDatas = useSelector((state) => state.staticData);
  const serviceType = staticDatas.UserArea;
  const sessionPackageList = useSelector(
    (state) => state.sessionPackage.getAll
  );
  const [createData, setCreateData] = useState({
    packageOptions: {
      Location: 1,
      MonthDuration: 1,
      TimesPerWeek: 1,
      SessionPackage: 1,
      Type: 1,
    },
    trialStatus: 3,
    attendeeId: attendee?.id,
    languageLevel: attendee?.languageLevel,
    learningPurposes: attendee?.learningPurposes,
  });
  const [resetState, setResetState] = useState(null);

  const onChangeText = (e) => {
    e.preventDefault();
    const { id, value, type } = e.target;
    if (value !== '') {
      if (type === 'number' && value > 0) {
        setCreateData({
          ...createData,
          [id]: type === 'number' ? parseInt(value) : value,
        });
      }
    } else {
      setCreateData((prevState) => {
        const { [id]: _, ...rest } = prevState;
        return rest;
      });
    }
  };

  const onSave = async (e) => {
    e.preventDefault();
    await dispatch(Actions.orderActions.selectSessionAction(createData, true));
  };

  useEffect(() => {
    dispatch(Actions.locationActions.getAllAction());
    dispatch(Actions.sessionPackageActions.getAllAction());
    dispatch(Actions.ageIntervalActions.getAllAction());
    return () => {
      dispatch(Actions.orderActions.cleanState());
    };
  }, []);

  useEffect(() => {
    if (resetState) {
      setResetState(null);
    }
  }, [resetState]);

  return (
    <div className="container-fluid">
      <div className="d-flex justify-content-center mt-5">
        <div className="col-11">
          <Card
            body={
              <React.Fragment>
                <div className="d-sm-flex align-items-center justify-content-center mb-4 form-div-border">
                  <HeadTags hSize={5} strong text={t('giftOrder.form')} />
                </div>
                <div className="row">
                  <div className="col-12 col-md-6">
                    <DropdownWithSearch
                      placeHolder={t('dropdownSelect.select_user_area')}
                      options={serviceType}
                      selectedProps={{
                        key: 'key',
                        value: 'value',
                      }}
                      onChange={(value) => {
                        if (
                          parseInt(value) !== createData?.userArea &&
                          createData?.userArea !== undefined
                        ) {
                          const data = {
                            packageOptions: {
                              Location: 1,
                              MonthDuration: 1,
                              TimesPerWeek: 1,
                              SessionPackage: 1,
                              Type: 1,
                            },
                            trialStatus: 3,
                            attendeeId: attendee?.id,
                            languageLevel: attendee?.languageLevel,
                            learningPurposes: attendee?.learningPurposes,
                            userArea: parseInt(value),
                            userAreaString: serviceType
                              .find((val) => val.value === value)
                              ?.path.split('/')[1],
                          };
                          setCreateData(data);
                          setResetState(true);
                        } else {
                          setCreateData({
                            ...createData,
                            userArea: parseInt(value),
                            userAreaString: serviceType
                              .find((val) => val.value === value)
                              ?.path.split('/')[1],
                          });
                        }
                      }}
                    />
                  </div>
                  <div className="col-12 col-md-6 mt-2 mt-md-0">
                    <DropdownWithSearch
                      resetState={resetState}
                      placeHolder={t('dropdownSelect.select_location')}
                      options={locationList.filter(
                        (area) =>
                          area.locationArea === createData?.userArea ||
                          (area.locationArea === 0 &&
                            createData?.userArea === 2)
                      )}
                      selectedProps={{ key: 'locationName', value: 'id' }}
                      onChange={(value) =>
                        setCreateData({
                          ...createData,
                          packageOptions: {
                            ...createData.packageOptions,
                            Location: parseInt(value),
                          },
                        })
                      }
                      selectRule={t('dropdownWithSearch.select_rule_area')}
                    />
                  </div>
                  <div className="col-12 col-md-6 mt-md-3 mt-2">
                    <DropdownWithSearch
                      resetState={resetState}
                      placeHolder={t('dropdownSelect.select_session_package')}
                      options={sessionPackageList.filter((item) =>
                        item.displayName
                          .toLowerCase()
                          .includes(createData?.userAreaString)
                      )}
                      selectedProps={{
                        key: 'person',
                        supKey: 'minute',
                        value: 'id',
                        display: 'displayName',
                      }}
                      onChange={(value) =>
                        setCreateData({
                          ...createData,
                          packageOptions: {
                            ...createData.packageOptions,
                            SessionPackage: parseInt(value),
                          },
                          productType:
                            parseInt(value) === 1 ||
                            parseInt(value) === 2 ||
                            parseInt(value) === 5
                              ? 'Private'
                              : 'Group',
                        })
                      }
                      selectRule={t('dropdownWithSearch.select_rule_area')}
                    />
                  </div>
                  <div className="col-12 col-md-6 mt-md-3 mt-2">
                    <DropdownWithSearch
                      resetState={resetState}
                      placeHolder={t('giftOrder.timesPerWeek')}
                      options={TimesPerWeek}
                      selectedProps={{ key: 'perWeek', value: 'perWeek' }}
                      onChange={(value) =>
                        setCreateData({
                          ...createData,
                          packageOptions: {
                            ...createData.packageOptions,
                            TimesPerWeek: parseInt(value),
                          },
                        })
                      }
                    />
                  </div>
                  <div className="col-12 col-md-6 mt-md-3 mt-2">
                    <InputLabelOnBorder
                      id={'dueDate'}
                      inputType={InputType.Number}
                      labelText={t('giftOrder.dueDate')}
                      inputValue={createData.dueDate || ''}
                      onChange={onChangeText}
                    />
                  </div>
                  <div className="col-12 col-md-6 mt-md-3 mt-2">
                    <InputLabelOnBorder
                      id={'giftSessionCount'}
                      inputType={InputType.Number}
                      labelText={t('giftOrder.sessionCount')}
                      inputValue={createData.giftSessionCount || ''}
                      onChange={onChangeText}
                    />
                  </div>
                  <div className="d-flex justify-content-center justify-content-md-end mt-3">
                    <div className="col-12 col-md-2">
                      <SubmitButton
                        stateName={'order'}
                        onClick={onSave}
                        objectLengthRule={
                          Object.keys(createData).length >= 10 ? false : true
                        }
                      />
                    </div>
                  </div>
                </div>
              </React.Fragment>
            }
          />
        </div>
      </div>
      <Toast
        stateName={'order'}
        onClose={(val) => {
          if (val) {
            setCreateData({});
            navigateMe(2);
          }
        }}
      />
    </div>
  );
};
export default GiftOrder;
