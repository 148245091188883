import React, { useEffect } from 'react';
import {
  Routes,
  Route,
  Outlet,
  Navigate,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import i18next from 'i18next';
import './assets/App.css';
import UI from './pages/UI';
import BackOffice from './pages/BackOffice';
import Landing from './pages/Landing';
import Sidebar from './components/Common/Sidebar/Sidebar';
import Topbar from './components/Common/Topbar/Topbar';
import Footer from './components/Common/Footer';
import Navbar from './components/Common/Navbar';
import Banner from './components/Common/Banner/Banner';
import Actions from './store/redux/actions';
import { setAreaDetails } from './services/apiServices/authServices';
import findMyPath from './hooks/useFindMyPath';
import getCookieItem from './hooks/useGetCookieItem';
import refreshPage from './hooks/useReload';
import utmDataBuilder from './hooks/useUtmDataBuilder';
import utmDataSender from './hooks/useUtmDataSender';
import { formActions, storageKeys, storageValues, UserAreasSring } from './utils/Enum';

export const App = () => {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.lang.language);
  const auth = useSelector((state) => state.auth.auth);
  const getLanguage = localStorage.getItem(storageKeys.languge);

  useEffect(() => {
    dispatch(Actions.langActions.pickLanguage(getLanguage));
    dispatch(Actions.langActions.setFlag(getLanguage));
    i18next.changeLanguage(lang?.split(',')[0]);
  }, [dispatch, getLanguage, lang]);

  function UISideTopFoot() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const userInfo = useSelector((state) => state.user.getById);
    const areaEnum = useSelector((state) => state.onboard.enum);
    const auth = useSelector((state) => state.auth.auth);
    const currentUserClaim = useSelector((state) => state.auth.claim);
    const lengthCurrentUserClaim = currentUserClaim?.length === 0;

    useEffect(() => {
      const storageKey = JSON.parse(
        sessionStorage.getItem(storageKeys.utmDataInsert)
      );
      if (
        userInfo.id !== undefined &&
        storageKey?.value &&
        storageKey?.form === formActions.register.key
      ) {
        utmDataSender(userInfo, formActions.register);
      }
    }, [userInfo]);

    useEffect(() => {
      const utmId = queryParams.get('utm_id');
      if (
        utmId !== null &&
        utmId !== '' &&
        utmId !== undefined &&
        sessionStorage.getItem(storageKeys.utmData) === null
      ) {
        utmDataBuilder(utmId);
      }
      const hardReset = localStorage.getItem(storageKeys.hardReset);
      if (hardReset) {
        localStorage.removeItem(storageKeys.hardReset);
        refreshPage();
      }
      window.scrollTo(0, 0);
    }, []);

    return auth === '' ? (
      <Navigate to="/account/login" replace />
    ) : lengthCurrentUserClaim ? (
      <div>
        <Banner userArea={areaEnum} />
        <div id="wrapper">
          <Sidebar />
          <div id="content-wrapper">
            <Topbar userArea={areaEnum} />
            <Outlet />
          </div>
        </div>
      </div>
    ) : (
      <Navigate to="/unauthorized" replace />
    );
  }
  function Dashboard() {
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const userInfo = useSelector((state) => state.user.getById);
    const areaEnum = useSelector((state) => state.onboard.enum);
    const auth = useSelector((state) => state.auth.auth);
    const currentUserClaim = useSelector((state) => state.auth.claim);
    const lengthCurrentUserClaim = currentUserClaim?.length === 0;
    const propsToPass = {
      currentUserClaim: currentUserClaim,
    };
    const currentPathname = window.location.pathname;

    useEffect(() => {
      const storageKey = JSON.parse(
        sessionStorage.getItem(storageKeys.utmDataInsert)
      );
      if (
        userInfo.id !== undefined &&
        storageKey?.value &&
        storageKey?.form === formActions.register.key
      ) {
        utmDataSender(userInfo, formActions.register);
      }
    }, [userInfo]);

    useEffect(() => {
      const utmId = queryParams.get('utm_id');
      if (
        utmId !== null &&
        utmId !== '' &&
        utmId !== undefined &&
        sessionStorage.getItem(storageKeys.utmData) === null
      ) {
        utmDataBuilder(utmId);
      }
      if (auth !== '' && currentPathname === '/') {
        navigate(findMyPath('dashboard'));
      }
      const hardReset = localStorage.getItem(storageKeys.hardReset);
      if (hardReset) {
        localStorage.removeItem(storageKeys.hardReset);
        refreshPage();
      }
      window.scrollTo(0, 0);
    }, []);

    return auth === '' ? (
      <Navigate to="/account/login" replace />
    ) : (
      <div>
        {lengthCurrentUserClaim ? <Banner userArea={areaEnum} /> : null}
        <div id="wrapper">
          <Sidebar />
          <div id="content-wrapper" className="d-flex flex-column">
            <Topbar userArea={areaEnum} />
            <Outlet context={{ props: propsToPass }} />
          </div>
        </div>
      </div>
    );
  }
  function BOSideTop() {
    const areaEnum = useSelector((state) => state.onboard.enum);
    const auth = useSelector((state) => state.auth.auth);
    const currentUserClaim = useSelector((state) => state.auth.claim);
    const lengthCurrentUserClaim = currentUserClaim?.length === 0;

    useEffect(() => {
      const hardReset = localStorage.getItem(storageKeys.hardReset);
      if (hardReset) {
        localStorage.removeItem(storageKeys.hardReset);
        refreshPage();
      }
      window.scrollTo(0, 0);
    }, []);

    return auth === '' ? (
      <Navigate to="/account/login" replace />
    ) : !lengthCurrentUserClaim ? (
      <div id="wrapper">
        <Sidebar />
        <div id="content-wrapper">
          <Topbar userArea={areaEnum} />
          <div id="content">
            <Outlet />
          </div>
        </div>
      </div>
    ) : (
      <Navigate to="/unauthorized" replace />
    );
  }
  const StickyWrapper = () => {
    const areaEnum = useSelector((state) => state.onboard.enum);
    return (
      <div
        className="sticky-wrapper"
        style={{ position: 'sticky', top: 0, zIndex: 1000 }}
      >
        <Banner userArea={areaEnum} />
        <Navbar />
      </div>
    );
  };
  function LandingNavFoot() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const userInfo = useSelector((state) => state.user.getById);
    const auth = useSelector((state) => state.auth.auth);
    const userPackage = useSelector((state) => state.userPackage);
    const checkHasItems = userPackage.isPackageComplete;
    const currentUserClaim = useSelector((state) => state.auth.claim);
    const lengthCurrentUserClaim = currentUserClaim?.length === 0;
    const isFirstRegisterLanding = () =>
      getCookieItem(storageKeys.firstRegisterLanding);
    const fromRegisterLanding = isFirstRegisterLanding();
    let path;
    const userArea = sessionStorage.getItem(storageKeys.userArea);
    const tiktok =
      sessionStorage.getItem(storageKeys.tiktok) === storageValues.tiktok;

    if (checkHasItems) {
      const getValues = setAreaDetails(userPackage.packageData.userArea);
      path = `${getValues.path}/speakers`;
    }
    else if (tiktok && lengthCurrentUserClaim) {
      path = `${userArea?.toLowerCase()}/tiktok`;
    }
    else {
      if (userArea && userArea !== '' && userArea !== UserAreasSring.None) {
        path = `/${userArea?.toLowerCase()}/dashboard`;
      } else {
        path = '/dashboard';
      }
    }

    useEffect(() => {
      const storageKey = JSON.parse(
        sessionStorage.getItem(storageKeys.utmDataInsert)
      );
      if (
        userInfo.id !== undefined &&
        storageKey?.value &&
        storageKey?.form === formActions.register.key
      ) {
        utmDataSender(userInfo, formActions.register);
      }
    }, [userInfo]);

    useEffect(() => {
      const utmId = queryParams.get('utm_id');
      if (
        utmId !== null &&
        utmId !== '' &&
        utmId !== undefined &&
        sessionStorage.getItem(storageKeys.utmData) === null
      ) {
        utmDataBuilder(utmId);
      }
      const hardReset = localStorage.getItem(storageKeys.hardReset);
      if (hardReset) {
        localStorage.removeItem(storageKeys.hardReset);
        refreshPage();
      }
      window.scrollTo(0, 0);
    }, []);

    return auth === '' || fromRegisterLanding ? (
      <React.Fragment>
        <StickyWrapper />
        <Outlet />
        <Footer />
      </React.Fragment>
    ) : (
      <Navigate
        to={path}
        replace
        state={{ createData: userPackage.packageData }}
      />
    );
  }
  function CommonPages() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const userInfo = useSelector((state) => state.user.getById);
    const areaEnum = useSelector((state) => state.onboard.enum);
    const auth = useSelector((state) => state.auth.auth);
    const currentUserClaim = useSelector((state) => state.auth.claim);
    const lengthCurrentUserClaim = currentUserClaim?.length === 0;

    useEffect(() => {
      const storageKey = JSON.parse(
        sessionStorage.getItem(storageKeys.utmDataInsert)
      );
      if (
        userInfo.id !== undefined &&
        storageKey?.value &&
        storageKey?.form === formActions.register.key
      ) {
        utmDataSender(userInfo, formActions.register);
      }
    }, [userInfo]);

    useEffect(() => {
      const utmId = queryParams.get('utm_id');
      if (
        utmId !== null &&
        utmId !== '' &&
        utmId !== undefined &&
        sessionStorage.getItem(storageKeys.utmData) === null
      ) {
        utmDataBuilder(utmId);
      }
      const hardReset = localStorage.getItem(storageKeys.hardReset);
      if (hardReset) {
        localStorage.removeItem(storageKeys.hardReset);
        refreshPage();
      }
      window.scrollTo(0, 0);
    }, []);

    return auth === '' ? (
      <React.Fragment>
        <StickyWrapper />
        <Outlet />
        <Footer />
      </React.Fragment>
    ) : lengthCurrentUserClaim ? (
      <React.Fragment>
        <Banner userArea={areaEnum} />
        <div id="wrapper">
          <Sidebar />
          <div id="content-wrapper">
            <Topbar userArea={areaEnum} />
            <Outlet />
          </div>
        </div>
      </React.Fragment>
    ) : (
      <Navigate to="/unauthorized" replace />
    );
  }

  useEffect(() => {
    const hardReset = localStorage.getItem(storageKeys.hardReset);
    if (hardReset) {
      localStorage.removeItem(storageKeys.hardReset);
      refreshPage();
    }
  }, []);

  const dashboardPath = [
    '/dashboard',
    '/online/dashboard',
    '/kids/dashboard',
    '/cafe/dashboard',
    '/company/dashboard',
  ];
  const orderBasketPath = [
    '/order-basket',
    '/online/order-basket',
    '/kids/order-basket',
    '/cafe/order-basket',
  ];
  const orderPaymentDetailPath = [
    '/order/paymentdetail',
    '/online/order/paymentdetail',
    '/kids/order/paymentdetail',
    '/cafe/order/paymentdetail',
  ];
  const schedulePath = [
    '/schedule',
    '/online/schedule',
    '/kids/schedule',
    '/cafe/schedule',
    '/company/schedule',
  ];
  const sessionsPath = [
    '/sessions',
    '/online/sessions',
    '/kids/sessions',
    '/cafe/sessions',
    '/company/sessions',
  ];
  const ordersPath = [
    '/orders',
    '/online/orders',
    '/kids/orders',
    '/cafe/orders',
    '/company/orders',
  ];
  const orderPackagePath = [
    '/order/package',
    '/online/order/package',
    '/kids/order/package',
    '/cafe/order/package',
  ];
  const orderTriallessonPath = [
    '/order/triallesson',
    '/online/order/triallesson',
    '/kids/order/triallesson',
    '/cafe/order/triallesson',
  ];
  const groupPath = [
    '/order/triallesson/group',
    '/online/order/triallesson/group',
    '/cafe/order/triallesson/group',
    '/kids/order/triallesson/group',
    '/order/package/group',
    '/online/order/package/group',
    '/cafe/order/package/group',
    '/kids/order/package/group',
  ];
  const privatePath = [
    '/order/triallesson/one-to-one',
    '/online/order/triallesson/one-to-one',
    '/kids/order/triallesson/one-to-one',
    '/order/package/one-to-one',
    '/online/order/package/one-to-one',
    '/kids/order/package/one-to-one',
  ];
  const speakersPath = [
    '/online/speakers',
    '/kids/speakers',
    '/cafe/speakers',
    '/company/speakers',
  ];
  const speakerDetailsPath = [
    '/speakers/speaker-detail/:id',
    '/online/speakers/speaker-detail/:id',
    '/kids/speakers/speaker-detail/:id',
    '/cafe/speakers/speaker-detail/:id',
    '/company/speakers/speaker-detail/:id',
  ];
  const tiktokPaths = [
    '/tiktok',
    '/online/tiktok',
    '/kids/tiktok',
    '/cafe/tiktok',
    '/company/tiktok',
  ];
  return (
    <Routes>
      <Route element={<LandingNavFoot />}>
        <Route exact path="/" element={<Landing.Main />} />
        <Route path="/main" element={<Landing.Main />} />
        <Route path="/form-success" element={<Landing.Main />} />
        {auth === '' && (
          <Route path="/account/verify" element={<Landing.VerifyEmail />} />
        )}
        <Route path="/account/register" element={<Landing.Register />} />
        <Route path="/account/login" element={<Landing.Login />} />
        <Route path="/career/create" element={<Landing.BecomeASpeaker />} />
        <Route path="/become-a-partner" element={<Landing.BecomeAPartner />} />
        <Route path="/faq" element={<Landing.Faq />} />
        <Route path="/online" element={<Landing.Online />} />
        <Route path="/kids" element={<Landing.Kids />} />
        <Route path="/cafe" element={<Landing.Cafe />} />
        <Route path="/company" element={<Landing.Company />} />
        <Route path="/cafe/branch/kadikoy" element={<Landing.Kadikoy />} />
        <Route path="/cafe/branch/antalya" element={<Landing.Antalya />} />
        <Route path="/cafe/branch/besiktas" element={<Landing.Besiktas />} />
        <Route path="/cafe/branch/camlica" element={<Landing.Camlica />} />
        {/* <Route path="/cafe/branch/maslak" element={<Landing.Maslak />} /> */}
        <Route
          path="/cafe/branch/mecidiyekoy"
          element={<Landing.Mecidiyekoy />}
        />
        <Route path="/campaigns" element={<Landing.Campaigns />} />
        <Route
          path="/account/forgotpassword"
          element={<Landing.ForgotPassword />}
        />
        <Route
          path="/campaign/detail/:name"
          element={<Landing.CampaignDetail />}
        />
        <Route
          path="/company-price-request"
          element={<Landing.CompanyPriceRequest />}
        />
        <Route path="/onboarding" element={<Landing.OnBoarding />} />
        <Route path="/lead-form" element={<Landing.LeadForm />} />
        <Route path="/new-main" element={<Landing.NewMain />} />
        <Route path="/new-kadikoy" element={<Landing.NewKadikoy />} />
      </Route>
      <Route element={<Dashboard />}>
        {dashboardPath.map((path, index) => (
          <Route key={index} path={path} element={<UI.Dashboard />} />
        ))}
        <Route
          path="/profile/change-password"
          element={<UI.ChangePassword />}
        />
        <Route
          path="/profile/personal-information"
          element={<UI.PersonalInformation />}
        />
      </Route>
      <Route element={<UISideTopFoot />}>
        {orderBasketPath.map((path, index) => (
          <Route key={index} path={path} element={<UI.OrderBasket />} />
        ))}
        {orderPaymentDetailPath.map((path, index) => (
          <Route key={index} path={path} element={<UI.Buy />} />
        ))}
        {schedulePath.map((path, index) => (
          <Route key={index} path={path} element={<UI.Schedule />} />
        ))}
        {sessionsPath.map((path, index) => (
          <Route key={index} path={path} element={<UI.MySessions />} />
        ))}
        {ordersPath.map((path, index) => (
          <Route key={index} path={path} element={<UI.MyOrders />} />
        ))}
        {auth !== '' && (
          <Route path="/account/verify" element={<Landing.VerifyEmail />} />
        )}
        <Route path="/3d-result" element={<UI.Result3D />} />
        <Route path="/payment/callback" element={<UI.Callback />} />
        <Route path="/packages" element={<UI.Packages />} />
        <Route path="/congratulations" element={<UI.Congratulations />} />
        <Route path="/gift-code" element={<UI.GiftCode />} />
        <Route path="/profile/settings" element={<UI.ProfileSettings />} />
        <Route path="/my-sessions-history" element={<UI.MySessionsHistory />} />
        <Route path="/all-notifications" element={<UI.AllNotifications />} />
        <Route path="/support" element={<UI.Support />} />
      </Route>
      <Route element={<BOSideTop />}>
        <Route
          path="/order-history/:attendeeId?"
          element={<BackOffice.AttendeeOrderHistory />}
        />
        <Route
          path="/meetings/:attendeeId?/:orderItemId?"
          element={<BackOffice.MeetingList />}
        />
        <Route path="/operations" element={<BackOffice.OperationManagment />} />
        <Route
          path="/meeting-schedule"
          element={<BackOffice.ScheduleMeetings />}
        />
        <Route
          path="/monthly-schedule"
          element={<BackOffice.ScheduleManager />}
        />
        <Route path="/manage-claims" element={<BackOffice.ManageClaims />} />
        <Route path="/career-list" element={<BackOffice.CareerList />} />
        <Route path="/speaker-create" element={<BackOffice.SpeakerCreate />} />
        <Route path="/speaker-update" element={<BackOffice.SpeakerUpdate />} />
        <Route
          path="/speaker-effort-report"
          element={<BackOffice.SpeakerEffortReport />}
        />
        <Route
          path="/speaker-effort-details"
          element={<BackOffice.SpeakerEffortDetails />}
        />
        <Route path="/speaker-list" element={<BackOffice.SpeakerList />} />
        <Route path="/availability-calendar" element={<BackOffice.AvailabilityCalendar />} />
        <Route
          path="/question-create"
          element={<BackOffice.QuestionCreate />}
        />
        <Route path="/question-list" element={<BackOffice.QuestionList />} />
        <Route path="/details" element={<BackOffice.Details />} />
        <Route
          path="/votes-by-speakers"
          element={<BackOffice.VotesBySpeakers />}
        />
        <Route
          path="/attendee-create"
          element={<BackOffice.AttendeeCreate />}
        />
        <Route
          path="/attendee-update"
          element={<BackOffice.AttendeeUpdate />}
        />
        <Route path="/attendee-list" element={<BackOffice.AttendeeList />} />
        <Route path="/manager-list" element={<BackOffice.ManagerList />} />
        <Route path="/manager-create" element={<BackOffice.ManagerCreate />} />
        <Route path="/manager-update" element={<BackOffice.ManagerUpdate />} />
        <Route path="/user-list" element={<BackOffice.UserList />} />
        <Route path="/user-create" element={<BackOffice.UserCreate />} />
        <Route path="/user-update" element={<BackOffice.UserUpdate />} />
        <Route
          path="/meeting-schema-list"
          element={<BackOffice.MeetingSchemaList />}
        />
        <Route
          path="/meeting-schema-create"
          element={<BackOffice.MeetingSchemaCreate />}
        />
        <Route
          path="/meeting-schema-update"
          element={<BackOffice.MeetingSchemaUpdate />}
        />
        <Route path="/created-today-trials" element={<BackOffice.CreatedTodayTrials />} />
        <Route
          path="/upcoming-trials"
          element={<BackOffice.UpcomingTrials />}
        />
        <Route path="/order-list" element={<BackOffice.OrderList />} />
        <Route path="/order-item-list" element={<BackOffice.OrderItemList />} />
        <Route
          path="/order-item-update"
          element={<BackOffice.OrderItemUpdate />}
        />
        <Route path="/order-create" element={<BackOffice.OrderCreate />} />
        <Route path="/order-update" element={<BackOffice.OrderUpdate />} />
        <Route path="/order-payments" element={<BackOffice.Payments />} />
        <Route
          path="/remaining-order-calculation"
          element={<BackOffice.RemainingOrderCalculation />}
        />
        <Route path="/eft-payments" element={<BackOffice.EftPayments />} />
        <Route
          path="/attendee-freeze-create"
          element={<BackOffice.AttendeeFreezeCreate />}
        />
        <Route
          path="/attendee-freeze-list"
          element={<BackOffice.AttendeeFreezeList />}
        />
        <Route
          path="/branch-holiday-create"
          element={<BackOffice.BranchHolidayCreate />}
        />
        <Route
          path="/branch-holiday-update"
          element={<BackOffice.BranchHolidayUpdate />}
        />
        <Route
          path="/branch-holiday-list"
          element={<BackOffice.BranchHolidayList />}
        />
        <Route path="/create-package" element={<BackOffice.CreatePackage />} />
        <Route path="/package-list" element={<BackOffice.PackageList />} />
        <Route path="/call-them" element={<BackOffice.CallThem />} />
        <Route path="/test-redate" element={<BackOffice.TestRedate />} />
        <Route path="/test-price" element={<BackOffice.TestPrice />} />
        <Route path="/roles" element={<BackOffice.Roles />} />
        <Route
          path="/age-interval-list"
          element={<BackOffice.AgeIntervalList />}
        />
        <Route
          path="/age-interval-create"
          element={<BackOffice.AgeIntervalCreate />}
        />
        <Route
          path="/age-interval-update"
          element={<BackOffice.AgeIntervalUpdate />}
        />
        <Route path="/location-list" element={<BackOffice.LocationList />} />
        <Route
          path="/location-create"
          element={<BackOffice.LocationCreate />}
        />
        <Route
          path="/location-update"
          element={<BackOffice.LocationUpdate />}
        />
        <Route
          path="/session-category-list"
          element={<BackOffice.SessionCategoryList />}
        />
        <Route
          path="/session-category-create"
          element={<BackOffice.SessionCategoryCreate />}
        />
        <Route
          path="/session-category-update"
          element={<BackOffice.SessionCategoryUpdate />}
        />
        <Route
          path="/session-package-list"
          element={<BackOffice.SessionPackageList />}
        />
        <Route
          path="/session-package-create"
          element={<BackOffice.SessionPackageCreate />}
        />
        <Route
          path="/session-package-update"
          element={<BackOffice.SessionPackageUpdate />}
        />
        <Route
          path="/times-per-month-list"
          element={<BackOffice.TimesPerMonthList />}
        />
        <Route
          path="/times-per-month-create"
          element={<BackOffice.TimesPerMonthCreate />}
        />
        <Route
          path="/times-per-month-update"
          element={<BackOffice.TimesPerMonthUpdate />}
        />
        <Route
          path="/times-per-week-list"
          element={<BackOffice.TimesPerWeekList />}
        />
        <Route
          path="/times-per-week-create"
          element={<BackOffice.TimesPerWeekCreate />}
        />
        <Route
          path="/times-per-week-update"
          element={<BackOffice.TimesPerWeekUpdate />}
        />
        <Route path="/zone-list" element={<BackOffice.ZoneList />} />
        <Route path="/zone-create" element={<BackOffice.ZoneCreate />} />
        <Route path="/zone-update" element={<BackOffice.ZoneUpdate />} />
        <Route
          path="/banner-create"
          element={<BackOffice.BannerCreate />}
        />
        <Route
          path="/banner-update"
          element={<BackOffice.BannerUpdate />}
        />
        <Route path="/banner-list" element={<BackOffice.BannerList />} />
        <Route path="/backoofice-chatroom" element={<UI.MySessions />} />
        <Route path="/segment-editor" element={<BackOffice.SegmentEditor />} />
        <Route path="/feedback-list" element={<BackOffice.FeedbackList />} />
        <Route path="/lead-list" element={<BackOffice.LeadList />} />
        <Route path="/lead-update" element={<BackOffice.LeadUpdate />} />
        <Route
          path="/coupon-code-create"
          element={<BackOffice.CouponCreate />}
        />
        <Route
          path="/coupon-code-update"
          element={<BackOffice.CouponUpdate />}
        />
        <Route path="/coupon-code-list" element={<BackOffice.CouponList />} />
        <Route path="/company-list" element={<BackOffice.CompanyList />} />
        <Route path="/company-create" element={<BackOffice.CompanyCreate />} />
        <Route path="/company-update" element={<BackOffice.CompanyUpdate />} />
        <Route
          path="/company-order-list"
          element={<BackOffice.CompanyOrderList />}
        />
        <Route
          path="/company-order-create"
          element={<BackOffice.CompanyOrderCreate />}
        />
        <Route
          path="/company-order-update"
          element={<BackOffice.CompanyOrderUpdate />}
        />
        <Route path="/campaign-list" element={<BackOffice.CampaignList />} />
        <Route path="/campaign-create" element={<BackOffice.CampaignCreate />} />
        <Route path="/campaign-update" element={<BackOffice.CampaignUpdate />} />
        <Route path="/reporting-list" element={<BackOffice.RepotingList />} />
        <Route path="/company-reporting" element={<BackOffice.CompanyReporting />} />
        <Route path={"/merge-cvs"} element={<BackOffice.MergeCSV />} />
      </Route>
      <Route element={<CommonPages />}>
        {orderPackagePath.map((path, index) => (
          <Route key={index} path={path} element={<UI.Subscriptions />} />
        ))}
        {orderTriallessonPath.map((path, index) => (
          <Route key={index} path={path} element={<UI.Subscriptions />} />
        ))}
        {groupPath.map((path, index) => (
          <Route key={index} path={path} element={<UI.GroupPackage />} />
        ))}
        {privatePath.map((path, index) => (
          <Route key={index} path={path} element={<UI.PrivatePackage />} />
        ))}
        {speakersPath.map((path, index) => (
          <Route key={index} path={path} element={<UI.Mentors />} />
        ))}
        {speakerDetailsPath.map((path, index) => (
          <Route key={index} path={path} element={<UI.MentorDetails />} />
        ))}
        <Route
          path="/mentor-detail-calendar/:id"
          element={<UI.MentorDetailCalendar />}
        />
        <Route path="/calendar" element={<UI.Calendar />} />
        <Route path="/gizlilik-politikasi" element={<UI.Privacy />} />
        <Route path="/satis-sozlesmesi" element={<UI.PaymentPolicy />} />
        <Route path="/uyelik-sozlesmesi" element={<UI.MembershipPolicy />} />
        <Route path="/cerez-politikasi" element={<UI.CookiePolicy />} />
        <Route path="/kullanim-kosullari" element={<UI.TermsOFUse />} />
        {tiktokPaths.map((path, index) => (
          <Route key={index} path={path} element={<UI.Tiktok />} />
        ))}
      </Route>
      {/* for test */}
      <Route path="/unauthorized" element={<Landing.Unauthorized />} />
      <Route path="/*" element={<Landing.Error404 />} />
    </Routes>
  );
};

export default App;
