import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Common/Button/Button';
import {
  ButtonIcon,
  ButtonType,
  RegexType,
  TextColors,
} from '../../../utils/ComponentEnums';
import Card from '../../../components/Common/Card/Card';
import { InputType } from '../../../utils/ComponentEnums';
import { useDispatch, useSelector } from 'react-redux';
import Actions from '../../../store/redux/actions';
import HeadTags from '../../../components/Common/HeadTags/HeadTags';
import InputLabelOnBorder from '../../../components/Common/Input/InputLabelOnBorder';
import { formatPhone } from '../../../hooks/useFormatPhone';
import CustomFileInput from '../../../components/Common/FileInput/FileUpload';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import DropdownWithSearch from '../../../components/Common/Dropdown/DropdownWithSearch';
import Input from '../../../components/Common/Input/Input';
import { getNames } from 'i18n-iso-countries';
import { AllLanguages, countryListAllIsoData } from '../../../utils/Enum';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Claims from '../../../utils/Claims';
import checkClaims from '../../../hooks/useCheckClaims';
import regexValidator from '../../../hooks/useRegexValidator';
import validationMessage from '../../../hooks/useValidationMessage';
import Toast from '../../../components/Common/Popup/Toast';
import SubmitButton from '../../../components/Common/Button/SubmitButton';

const Create = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const goBack = () => {
    dispatch(Actions.speakerActions.cleanState(true));
    dispatch(Actions.careerActions.cleanState(true));
    navigate(-1);
  };
  const careerId = location?.state?.id;
  const lang = useSelector((state) => state.lang.language);
  const speaker = useSelector((state) => state.speaker.speaker);
  const locationList = useSelector((state) => state.location.getAll);
  const currentUserClaim = useSelector((state) => state.auth.claim);
  const career = useSelector((state) => state.career.getById);
  const redirect = '/speaker-list';
  const staticDatas = useSelector((state) => state.staticData);
  const [createData, setCreateData] = useState(speaker);
  const [imgSrc, setImgSrc] = useState('');
  const levelGroup = useSelector(
    (state) => state.preferenceOptions.preferenceLevelGroup
  );
  const [CKOn, setCKOn] = useState(false);
  const [error, setError] = useState({});

  const isManager = checkClaims(
    currentUserClaim,
    Claims.backofficeClaims.speaker.getAll
  );

  const onChangeText = (e, special = undefined) => {
    e.preventDefault();
    const { id, value, type, name } = e.target;
    if (special === undefined) {
      if (value !== '') {
        setCreateData({ ...createData, [id]: value });
      } else {
        setCreateData((prevState) => {
          const { [id]: _, ...rest } = prevState;
          return rest;
        });
      }
      const validationResult = regexValidator(type, value, name);
      if (!validationResult && value !== '') {
        setError({
          ...error,
          [id]: (
            <span className="text-danger">
              {t('validationMessages.' + validationMessage(type))}
            </span>
          ),
        });
      } else {
        setError({ ...error, [id]: undefined });
      }
    } else {
      setCreateData({
        ...createData,
        [special]: { ...createData[special], [id]: value },
      });
    }
  };

  const onBlur = (e) => {
    e.preventDefault();
    dispatch(Actions.speakerActions.holdValue({ ...createData }));
  };

  const onCreate = async (e) => {
    e.preventDefault();
    const apiObject = {
      ...createData,
      detailInfo: JSON.stringify(createData.detailInfo),
    };
    await dispatch(Actions.speakerActions.createAction(apiObject));
  };

  const selectedLanguageLevels = [];
  if (levelGroup && levelGroup?.adult && levelGroup?.kids) {
    Object.values(levelGroup).forEach((lang) => {
      if (staticDatas.LanguageLevels[lang]) {
        selectedLanguageLevels.push(...staticDatas.LanguageLevels[lang]);
      }
    });
  }

  const [countryList, setCountryList] = useState([]);
  const countryNames = countryListAllIsoData.map((item) => {
    const name = getNames(lang?.split(',')[0].split('-')[0])[item.code];
    const isoName = item.name;
    const phone = item.phone;

    return { name: name, phone: phone, isoName: isoName };
  });

  useEffect(() => {
    if (levelGroup === null) {
      dispatch(Actions.preferenceActions.getLevelGroupAction(4));
    }
  }, [levelGroup]);

  useEffect(() => {
    if (careerId) {
      dispatch(Actions.careerActions.getByIdAction(careerId));
    }
    dispatch(Actions.locationActions.getAllAction());
    setCountryList(countryNames);
  }, []);

  useEffect(() => {
    if (Object.keys(career).length > 0) {
      career.detailInfo = JSON.parse(career.detailInfo);
      setCreateData(career);
      dispatch(Actions.speakerActions.holdValue(career));
      setImgSrc(career.image);
    }
  }, [career]);

  const handleSelectedData = (value, key) => {
    const dataArray = createData?.[key]?.split(',');

    if (dataArray?.length === undefined) {
      const updatedData = { ...createData, [key]: value };
      setCreateData(updatedData);
      dispatch(Actions.speakerActions.holdValue(updatedData));
    }

    if (dataArray?.includes(value)) {
      const index = dataArray.indexOf(value);
      if (index !== -1) {
        const newDataArray = dataArray.slice();
        newDataArray.splice(index, 1);
        const updatedData = {
          ...createData,
          [key]: newDataArray.join(','),
        };
        setCreateData(updatedData);
        if (newDataArray?.length === 0) {
          const newData = { ...createData };
          delete newData[key];
          setCreateData(newData);
          dispatch(Actions.speakerActions.holdValue(newData));
        } else {
          dispatch(Actions.speakerActions.holdValue(updatedData));
        }
      }
    }

    if (dataArray?.length !== undefined && !createData[key]?.includes(value)) {
      const updatedData = {
        ...createData,
        [key]: createData[key] + ',' + value,
      };
      setCreateData(updatedData);
      dispatch(Actions.speakerActions.holdValue(updatedData));
    }
  };

  const handleDropdown = (propName, value) => {
    setCreateData({ ...createData, [propName]: value });
    dispatch(
      Actions.speakerActions.holdValue({
        ...createData,
        [propName]: value,
      })
    );
  };

  const onFocus = (e) => {
    e.preventDefault();
    if (error?.phone !== undefined) {
      const cleanValue = formatPhone(undefined, createData?.phone);
      setCreateData({
        ...createData,
        phone: cleanValue,
      });
    }
  };

  useEffect(() => {
    if (createData?.phone?.length > 15 || error?.phone !== undefined) {
      const cleanValue = formatPhone(undefined, createData?.phone);
      setCreateData({
        ...createData,
        phone: cleanValue,
      });
    }
  }, [createData?.phone, error?.phone]);

  useEffect(() => {
    return () => {
      dispatch(Actions.speakerActions.cleanState(true));
      dispatch(Actions.careerActions.cleanState(true));
    };
  }, []);

  const content =
    careerId === undefined && !isManager ? (
      <Navigate to="/unauthorized" replace />
    ) : (
      <div className="container-fluid">
        <div className="d-flex justify-content-center mt-0 mt-md-5">
          <div className="col-12">
            <Card
              body={
                <React.Fragment>
                  <div className="d-sm-flex align-items-center justify-content-start ms-sm-2 mt-sm-3">
                    <div className="col-12 col-md-1">
                      <Button
                        type={ButtonType.Successbg}
                        icon={ButtonIcon.ArrowLeft}
                        iconColor={TextColors.Light}
                        bold
                        onClick={goBack}
                        buttonCol={12}
                      />
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-center mb-4 mt-2 mt-md-0 form-div-border">
                    <HeadTags
                      hSize={5}
                      strong
                      text={t('speaker.add_new_speaker_form')}
                    />
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <InputLabelOnBorder
                        id={'firstName'}
                        inputType={InputType.Text}
                        labelText={t('common.name')}
                        inputValue={createData.firstName || ''}
                        onChange={onChangeText}
                        onBlur={onBlur}
                        error={error?.firstName}
                        regexType={RegexType.Name}
                        required
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <InputLabelOnBorder
                        id={'lastName'}
                        inputType={InputType.Text}
                        labelText={t('common.lastName')}
                        inputValue={createData.lastName || ''}
                        onChange={onChangeText}
                        onBlur={onBlur}
                        error={error?.lastName}
                        regexType={RegexType.Name}
                        required
                      />
                    </div>
                    <div className="col-12 col-md-6 mt-md-2 mt-1">
                      <InputLabelOnBorder
                        id={'email'}
                        inputType={InputType.Email}
                        labelText={t(
                          'becomeAMentor.professional_email_address'
                        )}
                        inputValue={createData.email || ''}
                        onChange={onChangeText}
                        onBlur={onBlur}
                        error={error?.email}
                        required
                      />
                    </div>
                    <div className="col-12 col-md-6 mt-md-2 mt-1">
                      <InputLabelOnBorder
                        id={'dateOfBirth'}
                        inputType={InputType.Date}
                        labelText={t('becomeAMentor.birth_date')}
                        inputValue={
                          moment(createData.dateOfBirth).format('yyyy-MM-DD') ||
                          ''
                        }
                        onChange={onChangeText}
                        onBlur={onBlur}
                      />
                    </div>
                    <div className="col-12 col-md-6 mt-md-2 mt-1">
                      <InputLabelOnBorder
                        labelText={t('becomeAMentor.country_code')}
                        inputType={InputType.Text}
                        select={{
                          selectStyle: 'selectStyle',
                          option: countryList.map((item) => ({
                            key: `${item.name} (${item.phone})`,
                            value: `${item.phone} ${item.isoName}`,
                            name: 'countryCode',
                          })),

                          selectedValue: createData.countryCode,
                        }}
                        handleSelectChange={(val) => {
                          setCreateData({
                            ...createData,
                            [val.name]: val.value,
                          });
                          dispatch(
                            Actions.speakerActions.holdValue({
                              ...createData,
                              [val.name]: val.value,
                            })
                          );
                        }}
                        required
                      />
                    </div>
                    <div className="col-12 col-md-6 mt-md-2 mt-1">
                      <InputLabelOnBorder
                        id={'phone'}
                        inputType={InputType.Tel}
                        labelText={t('common.phone')}
                        inputValue={formatPhone(createData.phone) || ''}
                        onChange={onChangeText}
                        onBlur={onBlur}
                        onFocus={onFocus}
                        placeholder={'(5xx) xxx xx xx'}
                        error={error?.phone}
                        required
                      />
                    </div>
                    <div className="col-12 col-md-6 mt-md-2 mt-1">
                      <div className="col-12 d-flex justify-content-center">
                        {imgSrc ? (
                          <img
                            className={'img-fluid rounded-2 me-3'}
                            src={imgSrc}
                            alt={imgSrc}
                            width="20"
                          />
                        ) : undefined}
                      </div>
                      <CustomFileInput
                        id={'image'}
                        key={'image'}
                        hidden={true}
                        buttonText={t('becomeAMentor.upload_picture')}
                        textColor={TextColors.Light}
                        removeState={
                          createData.image !== undefined ? false : true
                        }
                        buttonColor={ButtonType.Successbg}
                        fileType={'Image'}
                        dataFrom={'Career'}
                        func={(val) => {
                          setCreateData(
                            Object.assign({}, createData, {
                              image: val.base64String,
                              imgExtension: val.extension,
                              url: val.fileName,
                            })
                          );
                          setImgSrc(val.imgSrc);
                          dispatch(
                            Actions.careerActions.holdValue(
                              Object.assign({}, createData, {
                                image: val.base64String,
                                imgExtension: val.extension,
                              })
                            )
                          );
                        }}
                      />
                    </div>
                    <div className="col-12 col-md-6 mt-md-2 mt-1">
                      <InputLabelOnBorder
                        id={'specialUrl'}
                        inputType={InputType.Text}
                        labelText={'Youtube'}
                        inputValue={createData.specialUrl}
                        onChange={onChangeText}
                        onBlur={onBlur}
                        required
                      />
                    </div>
                    <div className="col-12 col-md-6 mt-md-2 mt-1">
                      <InputLabelOnBorder
                        id={'displayOrder'}
                        inputType={InputType.Text}
                        labelText={'Display Order'}
                        inputValue={createData.displayOrder || ''}
                        onChange={onChangeText}
                        onBlur={onBlur}
                      />
                    </div>
                    <div className="col-12 col-md-6 mt-md-2 mt-1">
                      <InputLabelOnBorder
                        labelText={t('becomeAMentor.country')}
                        inputType={InputType.Text}
                        select={{
                          selectStyle: 'selectStyle',
                          option: countryList.map((item) => ({
                            key: item.name,
                            value: item.name,
                            name: 'country',
                          })),
                          selectedValue: createData.country,
                        }}
                        handleSelectChange={(val) => {
                          setCreateData({
                            ...createData,
                            [val.name]: val.value,
                          });
                          dispatch(
                            Actions.speakerActions.holdValue({
                              ...createData,
                              [val.name]: val.value,
                            })
                          );
                        }}
                        required
                      />
                    </div>
                    <div className="col-12 col-md-6 mt-md-2 mt-1">
                      <InputLabelOnBorder
                        id={'urlName'}
                        inputType={InputType.Text}
                        labelText={'Url Name'}
                        inputValue={createData.urlName || ''}
                        onChange={onChangeText}
                        onBlur={onBlur}
                        required
                      />
                    </div>
                    <div className="col-12 col-md-6 mt-md-2 mt-1">
                      <InputLabelOnBorder
                        id={'metaTitle'}
                        inputType={InputType.Text}
                        labelText={'Meta Title'}
                        inputValue={createData.metaTitle || ''}
                        onChange={onChangeText}
                        onBlur={onBlur}
                      />
                    </div>
                    <div className="col-12 col-md-6 mt-md-2 mt-1">
                      <InputLabelOnBorder
                        labelText={'Language'}
                        inputType={InputType.Text}
                        select={{
                          selectStyle: 'selectStyle',
                          option: AllLanguages,
                          selectedValue: createData.language || '',
                        }}
                        handleSelectChange={(val) => {
                          setCreateData({
                            ...createData,
                            [val.name]: val.key,
                          });
                          dispatch(
                            Actions.speakerActions.holdValue({
                              ...createData,
                              [val.name]: val.key,
                            })
                          );
                        }}
                        required
                      />
                    </div>
                    <div className="col-12 col-md-6 mt-md-2 mt-1">
                      <InputLabelOnBorder
                        labelText={'Currency Unit'}
                        id={'currentUnit'}
                        inputType={InputType.Text}
                        select={{
                          selectStyle: 'selectStyle',
                          option: staticDatas.CurrencyUnit,
                          selectedValue: createData.currencyUnit,
                        }}
                        handleSelectChange={(val) => {
                          setCreateData({
                            ...createData,
                            currencyUnit: parseInt(val.value),
                          });
                          dispatch(
                            Actions.speakerActions.holdValue({
                              ...createData,
                              currencyUnit: parseInt(val.value),
                            })
                          );
                        }}
                        required
                      />
                    </div>
                    <div className="col-12 col-md-6 mt-md-2 mt-1">
                      <div className="row">
                        <div className="col">
                          <InputLabelOnBorder
                            id={'native'}
                            labelText={t('becomeAMentor.accent')}
                            inputType={InputType.Text}
                            select={{
                              selectStyle: 'selectStyle',
                              option: staticDatas.Native,
                              selectedValue: createData.native,
                            }}
                            handleSelectChange={(val) => {
                              setCreateData({
                                ...createData,
                                [val.name]: val.value,
                              });
                              dispatch(
                                Actions.speakerActions.holdValue({
                                  ...createData,
                                  [val.name]: val.value,
                                })
                              );
                            }}
                            required
                          />
                        </div>
                        <div className="col">
                          <Input
                            key={'isFullTimer'}
                            id={'isFullTimer'}
                            value={createData.isFullTimer}
                            labelValue={t('becomeAMentor.is_full_timer')}
                            checked={createData.isFullTimer || false}
                            type={InputType.Checkbox}
                            onClick={(e) => {
                              setCreateData({
                                ...createData,
                                [e.target.id]: !createData.isFullTimer,
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 mt-md-3 mt-1">
                      <DropdownWithSearch
                        isSearchable
                        isMulti
                        onBlur={onBlur}
                        placeHolder={t('dropdownSelect.select_learningPurpose')}
                        options={staticDatas.LearningPurpose}
                        selectedProps={{
                          key: 'key',
                          value: 'value',
                        }}
                        selectedValues={createData.learningPurposes}
                        onChange={(value) => {
                          handleSelectedData(value, 'learningPurposes');
                        }}
                        required
                      />
                    </div>
                    <div className="col-12 col-md-6 mt-md-3 mt-1">
                      <DropdownWithSearch
                        isSearchable
                        isMulti
                        onBlur={onBlur}
                        placeHolder={t('dropdownSelect.select_level')}
                        options={selectedLanguageLevels}
                        selectedProps={{
                          key: 'key',
                          value: 'key',
                        }}
                        selectedValues={createData.languageLevels}
                        onChange={(value) => {
                          handleSelectedData(value, 'languageLevels');
                        }}
                        required
                      />
                    </div>
                    <div className="col-12 col-md-6 mt-md-3 mt-1">
                      <DropdownWithSearch
                        isSearchable
                        isMulti
                        onBlur={onBlur}
                        placeHolder={t('dropdownSelect.select_user_area')}
                        options={staticDatas.UserArea}
                        selectedProps={{ key: 'key', value: 'value' }}
                        onChange={(value) =>
                          handleSelectedData(value, 'displayAreas')
                        }
                        selectedValues={createData.displayAreas}
                        required
                      />
                    </div>
                    <div className="col-12 col-md-6 mt-md-3 mt-1">
                      <DropdownWithSearch
                        isSearchable
                        isMulti
                        onBlur={onBlur}
                        placeHolder={t('dropdownSelect.select_typeOfService')}
                        options={staticDatas.TypeOfService}
                        selectedProps={{ key: 'key', value: 'value' }}
                        onChange={(value) =>
                          handleSelectedData(value, 'typeOfService')
                        }
                        required
                      />
                    </div>
                    <div className="col-12 col-md-6 mt-md-3 mt-1">
                      <DropdownWithSearch
                        isMulti
                        isSearchable
                        onBlur={onBlur}
                        placeHolder={t('dropdownSelect.select_location')}
                        options={locationList}
                        selectedProps={{ key: 'locationName', value: 'id' }}
                        onChange={(value) =>
                          handleSelectedData(String(value), 'locations')
                        }
                        required
                      />
                    </div>
                    <div className="col-12 col-md-6 mt-md-2 mt-1">
                      <InputLabelOnBorder
                        id={'headTagData'}
                        inputType={InputType.Text}
                        labelText={'Head Tag Data'}
                        inputValue={createData.headTagData || ''}
                        onChange={onChangeText}
                        onBlur={onBlur}
                      />
                    </div>
                    <div className="col-0 col-md-6 mt-3"></div>
                    <div className="col-12 col-md-6 mt-md-2 mt-1">
                      <InputLabelOnBorder
                        id={'Education'}
                        inputType={InputType.Text}
                        labelText={t('becomeAMentor.education')}
                        inputValue={createData?.detailInfo?.Education || ''}
                        onChange={(e) => onChangeText(e, 'detailInfo')}
                        onBlur={onBlur}
                        textArea={{ row: 3, col: 20 }}
                      />
                    </div>
                    <div className="col-12 col-md-6 mt-md-2 mt-1">
                      <InputLabelOnBorder
                        id={'Profession'}
                        inputType={InputType.Text}
                        labelText={t('becomeAMentor.profession')}
                        inputValue={createData.detailInfo?.Profession || ''}
                        onChange={(e) => onChangeText(e, 'detailInfo')}
                        onBlur={onBlur}
                        textArea={{ row: 3, col: 20 }}
                      />
                    </div>
                    <div className="col-12 col-md-6 mt-md-2 mt-1">
                      <InputLabelOnBorder
                        id={'Certificates'}
                        inputType={InputType.Text}
                        labelText={t('becomeAMentor.certificates')}
                        inputValue={createData?.detailInfo?.Certificates || ''}
                        onChange={(e) => onChangeText(e, 'detailInfo')}
                        onBlur={onBlur}
                        textArea={{ row: 3, col: 20 }}
                      />
                    </div>
                    <div className="col-12 col-md-6 mt-md-2 mt-1">
                      <InputLabelOnBorder
                        id={'metaDescription'}
                        inputType={InputType.Text}
                        labelText={'Meta Description'}
                        inputValue={createData.metaDescription || ''}
                        onChange={onChangeText}
                        onBlur={onBlur}
                        textArea={{ row: 3, col: 20 }}
                      />
                    </div>
                    <div className="col-12 mt-4">
                      <label
                        className="ms-2 position-absolute"
                        style={{
                          marginTop: '-1.25rem',
                          fontSize: '.9rem',
                        }}
                      >
                        <span className="h6 small bg-white text-muted px-1">
                          {t('About Speaker')}
                        </span>
                        <span className="text-danger fw-bold ms-1">*</span>
                      </label>
                      {}{' '}
                      <CKEditor
                        editor={ClassicEditor}
                        data={createData?.htmlContent || ''}
                        onFocus={() => setCKOn(true)}
                        onChange={(event, editor) => {
                          if (CKOn) {
                            const data = editor.getData();
                            setCreateData({
                              ...createData,
                              htmlContent: data,
                            });
                          }
                        }}
                      />
                    </div>
                    <div className="d-flex justify-content-center justify-content-md-end mt-3">
                      <div className="col-12 col-md-2">
                        <SubmitButton
                          stateName={'speaker'}
                          onClick={onCreate}
                          objectLengthRule={
                            Object.keys(createData).length > 5 &&
                            Object.values(error).every((e) => e === undefined)
                              ? false
                              : true
                          }
                        />
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              }
            />
          </div>
        </div>
        <Toast
          stateName={'speaker'}
          onClose={(val) => {
            if (val) {
              setImgSrc('');
              setCreateData({});
              dispatch(Actions.speakerActions.cleanState());
              navigate(redirect);
            }
          }}
        />
      </div>
    );

  return checkClaims(
    currentUserClaim,
    Claims.backofficeClaims.speaker.create,
    content
  );
};
export default Create;
