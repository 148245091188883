import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Common/Button/Button';
import { ButtonType, TextColors } from '../../../utils/ComponentEnums';
import HeadTags from '../../../components/Common/HeadTags/HeadTags';
import Card from '../../../components/Common/Card/Card';
import BasicTable from '../../../components/Common/Table/BasicTable';
import { useLocation, useNavigate } from 'react-router-dom';
import Popup from '../../../components/Common/Popup/Popup';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useLoader from '../../../hooks/useLoader';
import { Loader } from '../../../components/Common/Loader/Loader';
import Actions from '../../../store/redux/actions';
import MobileTable from '../../../components/Common/Table/MobileTable';
import findMyPath from '../../../hooks/useFindMyPath';
import { checkLogged } from '../../../hooks/useCheckLogged';
import { cardArray, cardPropertyNames } from '../../../utils/ExternalVariables';
import Toast from '../../../components/Common/Popup/Toast';

const MyOrders = ({
  attendeeId = undefined,
  setDate = undefined,
  attendeeName = undefined,
  getMeIndex = {},
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const userInfo = useSelector((state) => state.user.getById);
  const orderData = useSelector((state) => state.order.activeOrders);
  const [popupIsOpen, setPopupIsOpen] = useState(false);
  const [popupPosition, setPopupPosition] = useState({ x: 400, y: 50 });
  const [buttonText, setButtonText] = useState({});
  const orderSuccess = location?.state?.reload;
  const [popupArray, setPopupArray] = useState({});
  const [area, setArea] = useState(null);

  useEffect(() => {
    return () => {
      dispatch(Actions.orderActions.cleanState());
      if (orderSuccess) {
        dispatch(Actions.userActions.getByIdAction(checkLogged()));
      }
    };
  }, []);

  const closePopup = () => {
    setPopupArray([]);
    setPopupIsOpen(false);
  };

  const openPopup = (val) => {
    setArea(val?.userArea);
    const filteredArray = getFilteredArray(val?.userArea);
    setPopupArray({
      title: t('button.bank_accounts'),
      Contents: filteredArray,
    });
  };

  const getFilteredArray = (userArea) => {
    return cardArray
      ?.filter((data) => {
        if (userArea === 0 || userArea === 2) {
          return data.id === 3;
        } else {
          return data.id !== 3;
        }
      })
      ?.map((data) => ({
        ContentId: data.id,
        ContentTitle: data.title,
        CardImage: data.png,
        ContentText: {
          text1: '',
          text2: data.description,
          text3: data.owner,
        },
        Images: [],
        ImageCount: [],
      }));
  };

  useEffect(() => {
    if (Object.keys(popupArray).length > 0) {
      setPopupIsOpen(true);
    }
  }, [popupArray]);

  let headers = [
    { key: 'orderNumber', label: t('common.orderItemId') },
    {
      key: 'orderItemDescription',
      label: t('order.order_item_description'),
    },
    { key: 'orderStatus', label: t('myOrdersTable.order_status') },
    { key: 'paymentStatus', label: t('myOrdersTable.payment_status') },
    {
      key: 'netAmount',
      label: t('myOrdersTable.net_amount'),
    },
    {
      key:
        attendeeId !== undefined && !setDate
          ? 'waitingMeetingCount'
          : 'undatedPackageCount',
      label: t(
        attendeeId !== undefined && !setDate
          ? 'myOrdersTable.waiting_package_count'
          : 'myOrdersTable.undated_package_count'
      ),
    },
  ].filter(Boolean);

  if (userInfo.userArea === 3) {
    headers = headers.filter((header) => header.key !== 'netAmount');
  }

  if (attendeeId !== undefined) {
    headers.unshift({ key: 'orderId', label: t('common.orderId') });
  }

  // waitingMeetingCount unset
  let totalUndatedPackageCount = 0;

  orderData?.forEach((orderData) => {
    if (orderData.paymentStatus === t('PaymentStatus.1')) {
      totalUndatedPackageCount += orderData.undatedPackageCount;
    }
  });
  const selectedValues = orderData?.map((item) => ({
    orderNumber: item?.orderNumber,
    productType: item?.setDateItems?.productType,
    timesPerWeek:
      item?.setDateItems?.scheduleUserSelection?.packageOptions?.timesPerWeek,
    sessionContent: item?.sessionContent,
    location:
      item?.setDateItems?.scheduleUserSelection?.packageOptions?.location,
    languageLevel: item?.setDateItems?.languageLevel,
    sessionCount:
      item?.monthDuration *
      4 *
      item?.setDateItems?.scheduleUserSelection?.packageOptions?.timesPerWeek,
    userArea: item?.userArea,
    packageId:
      item?.setDateItems?.scheduleUserSelection?.packageOptions?.sessionPackage,
    orderId: item?.setDateItems?.orderId,
    trialStatus: item?.setDateItems?.scheduleUserSelection?.trialStatus,
    companyId: item?.setDateItems?.scheduleUserSelection?.companyId,
    ageId: item?.setDateItems?.scheduleUserSelection?.ageId,
  }));
  function getOrders() {
    if (attendeeId === undefined) {
      dispatch(Actions.orderActions.getBasketItemsAction());
    }
    dispatch(
      Actions.orderActions.getOrdersAction(
        attendeeId !== undefined ? attendeeId : ''
      )
    );
  }
  useEffect(() => {
    getOrders();
  }, []);
  const onClickFuncSetDate = (val) => {
    if (attendeeId !== undefined) {
      if (setDate) {
        getMeIndex({
          index: 5,
          state: {
            fromNavigateWhere: 'setDate',
            selectLaterItems: {
              orderItemId: selectedValues?.[val]?.orderNumber,
              productType: selectedValues?.[val]?.productType,
              timesPerWeek: selectedValues?.[val]?.timesPerWeek,
              location: selectedValues?.[val]?.location,
              languageLevel: selectedValues?.[val]?.languageLevel,
              sessionContent: selectedValues?.[val]?.sessionContent,
              sessionCount: selectedValues?.[val]?.sessionCount,
              userArea: selectedValues?.[val]?.userArea,
              packageId: selectedValues?.[val]?.packageId,
              orderId: selectedValues?.[val]?.orderId,
              trialStatus: selectedValues?.[val]?.trialStatus,
              companyId: selectedValues?.[val]?.companyId,
              ageId: selectedValues?.[val]?.ageId,
            },
          },
        });
      } else {
        dispatch(
          Actions.orderActions.unSetDateAction(
            selectedValues?.[val]?.orderNumber
          )
        );
      }
    } else {
      navigate(findMyPath('speakers'), {
        state: {
          fromNavigateWhere: 'setDate',
          selectLaterItems: {
            orderItemId: selectedValues?.[val]?.orderNumber,
            productType: selectedValues?.[val]?.productType,
            timesPerWeek: selectedValues?.[val]?.timesPerWeek,
            location: selectedValues?.[val]?.location,
            languageLevel: selectedValues?.[val]?.languageLevel,
            sessionContent: selectedValues?.[val]?.sessionContent,
            sessionCount: selectedValues?.[val]?.sessionCount,
            userArea: selectedValues?.[val]?.userArea,
            packageId: selectedValues?.[val]?.packageId,
            orderId: selectedValues?.[val]?.orderId,
            trialStatus: selectedValues?.[val]?.trialStatus,
            companyId: selectedValues?.[val]?.companyId,
            ageId: selectedValues?.[val]?.ageId,
          },
        },
      });
    }
  };
  const handleButtonClick = (text, i) => {
    navigator.clipboard.writeText(text);
    setButtonText((prevButtonTexts) => ({
      ...prevButtonTexts,
      [i]: t('buy.eft_copy'),
    }));
    setTimeout(() => {
      setButtonText({});
    }, 2000);
  };

  const customToastStyle = {
    top: '0',
    left: '0',
    bottom: '0',
    right: '0',
    width: '100%',
    zIndex: 10000,
    background: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };
  return useLoader() ? (
    <Loader />
  ) : (
    <div className="container">
      <div className="row">
        <div className="col">
          <Card
            body={
              <React.Fragment>
                <div className="d-sm-flex ms-3">
                  <div className="col-sm-6 mb-4">
                    <div className="d-flex align-items-center justify-content-between">
                      <HeadTags
                        hSize={3}
                        strong
                        text={
                          attendeeName !== undefined
                            ? t('myOrdersCard.header_selected_user', {
                                name: attendeeName,
                              })
                            : t('myOrdersCard.header', {
                                area: t(`UserArea.${userInfo.userArea}`, {
                                  lng: 'en',
                                }),
                              })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="d-sm-flex ms-sm-3 mb-sm-1 justify-content-end">
                  {totalUndatedPackageCount !== 0 &&
                    attendeeId === undefined && (
                      <span className="bg-danger rounded-2 p-1 text-light">
                        {t('myOrders.undated_session_count', {
                          count: totalUndatedPackageCount,
                        })}
                      </span>
                    )}
                </div>
                <div className="d-sm-flex">
                  <div className="col-sm-12 d-none d-sm-block">
                    <BasicTable
                      data={orderData}
                      headers={headers}
                      matchValues={{
                        actionButtonProps: {
                          key:
                            attendeeId !== undefined && !setDate
                              ? 'waitingMeetingCount'
                              : 'undatedPackageCount',
                          value: 0,
                        },
                        infoButtonProps: {
                          key: 'paymentStatus',
                          value: t('PaymentStatus.0'),
                        },
                      }}
                      actionButtonProps={{
                        text: t(
                          attendeeId !== undefined && !setDate
                            ? 'myOrders.unset_date'
                            : 'earnFreeSessionTable.button_set_date'
                        ),
                        type:
                          attendeeId !== undefined && !setDate
                            ? ButtonType.Danger
                            : ButtonType.Successbg,
                        textColor: TextColors.Light,
                      }}
                      infoButtonProps={{
                        text: t('myOrders.payment_info'),
                        type: ButtonType.Info,
                        textColor: TextColors.Light,
                      }}
                      onClickFuncSetDate={(val) => onClickFuncSetDate(val)}
                      onClickFuncPopup={(val) => openPopup(val)}
                    />
                  </div>
                  <Popup
                    title={t('button.bank_accounts')}
                    isOpen={popupIsOpen}
                    onClose={closePopup}
                    cardArray={popupArray}
                    position={popupPosition}
                    content={cardArray
                      ?.filter((data) => {
                        if (area === 0 || area === 2) {
                          return data.id === 3;
                        } else {
                          return data.id !== 3;
                        }
                      })
                      ?.map((card, i) => (
                        <div className="row" key={card[cardPropertyNames[0]]}>
                          <div className="col-12">
                            <Card
                              cardTitle={{
                                title: (
                                  <div className="col-sm-3 col-md-6 col-lg-12 d-flex align-items-center">
                                    <strong className="ms-3">
                                      {card[cardPropertyNames[1]]}
                                    </strong>
                                    <img
                                      className="ms-3"
                                      src={card[cardPropertyNames[4]]}
                                      alt={card[cardPropertyNames[4]]}
                                      width={100}
                                    />
                                  </div>
                                ),
                              }}
                              shadow={true}
                              cardRounded={true}
                              body={
                                <React.Fragment>
                                  <div
                                    value={card[cardPropertyNames[2]]}
                                    className="row mx-2 mt-2 text-muted fst-italic"
                                  >
                                    <p className="col-8">
                                      {card[cardPropertyNames[2]]}
                                    </p>
                                    <div className="col-4 d-flex justify-content-end">
                                      <Button
                                        textColor={TextColors.Light}
                                        text={buttonText[i] || t('buy.copy')}
                                        disabled={
                                          buttonText[i] !== undefined
                                            ? true
                                            : false
                                        }
                                        bold
                                        type={
                                          buttonText[i] !== undefined
                                            ? ButtonType.Secondary
                                            : ButtonType.Info
                                        }
                                        buttonCol={12}
                                        onClick={() => {
                                          handleButtonClick(
                                            card[cardPropertyNames[2]],
                                            i
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>
                                  {cardPropertyNames[3] ? (
                                    <div className="row ms-3 text-muted fst-italic">
                                      {card[cardPropertyNames[3]]}
                                    </div>
                                  ) : undefined}
                                </React.Fragment>
                              }
                            />
                          </div>
                        </div>
                      ))}
                  />
                  <div className="d-block d-sm-none">
                    <MobileTable
                      searchAble
                      data={orderData}
                      headers={headers}
                      tableType={'basic'}
                      buttonPopup={openPopup}
                      matchValues={{
                        actionButtonProps: {
                          key:
                            attendeeId !== undefined && !setDate
                              ? 'waitingMeetingCount'
                              : 'undatedPackageCount',
                          value: 0,
                        },
                        infoButtonProps: {
                          key: 'paymentStatus',
                          value: t('PaymentStatus.0'),
                        },
                      }}
                      actionButtonProps={{
                        text: t(
                          attendeeId !== undefined && !setDate
                            ? 'myOrders.unset_date'
                            : 'earnFreeSessionTable.button_set_date'
                        ),
                        type:
                          attendeeId !== undefined && !setDate
                            ? ButtonType.Danger
                            : ButtonType.Successbg,
                        textColor: TextColors.Light,
                      }}
                      infoButtonProps={{
                        text: t('myOrders.payment_info'),
                        type: ButtonType.Info,
                        textColor: TextColors.Light,
                      }}
                      onClickFuncSetDate={(val) => onClickFuncSetDate(val)}
                      onClickFuncPopup={openPopup}
                    />
                  </div>
                </div>
              </React.Fragment>
            }
          />
        </div>
      </div>
      <Toast
        key={'unSetDate'}
        id={'unSetDate'}
        stateName={'order'}
        onClose={(val) => {
          if (val) {
            dispatch(Actions.orderActions.getOrdersAction(attendeeId));
            dispatch(Actions.resetStoreAction.resetStore());
          }
        }}
      />
    </div>
  );
};
export default MyOrders;
