import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Common/Button/Button';
import {
  ButtonIcon,
  ButtonType,
  RegexType,
  TextColors,
} from '../../../utils/ComponentEnums';
import Card from '../../../components/Common/Card/Card';
import Input from '../../../components/Common/Input/Input';
import { InputType } from '../../../utils/ComponentEnums';
import { useDispatch, useSelector } from 'react-redux';
import Actions from '../../../store/redux/actions';
import HeadTags from '../../../components/Common/HeadTags/HeadTags';
import InputLabelOnBorder from '../../../components/Common/Input/InputLabelOnBorder';
import CustomFileInput from '../../../components/Common/FileInput/FileUpload';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleUser } from '@fortawesome/free-solid-svg-icons';
import { countryListAllIsoData } from '../../../utils/Enum';
import { getNames } from 'i18n-iso-countries';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { formatPhone } from '../../../hooks/useFormatPhone';
import moment from 'moment';
import DropdownWithSearch from '../../../components/Common/Dropdown/DropdownWithSearch';
import { ToastContainer, toast } from 'react-toastify';
import checkClaims from '../../../hooks/useCheckClaims';
import Claims from '../../../utils/Claims';
import regexValidator from '../../../hooks/useRegexValidator';
import validationMessage from '../../../hooks/useValidationMessage';

const Update = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const speakerId = location?.state?.id;

  const lang = useSelector((state) => state.lang.language);
  const currentUserClaim = useSelector((state) => state.auth.claim);
  const speakerData = useSelector((state) => state.speaker.getById);
  const speakerIsSuccess = useSelector((state) => state.speaker.isSuccess);
  const speakerValidationMessage = useSelector(
    (state) => state.speaker.validationMessage
  );
  const levelGroup = useSelector(
    (state) => state.preferenceOptions.preferenceLevelGroup
  );
  const locationList = useSelector((state) => state.location.getAll);
  const staticDatas = useSelector((state) => state.staticData);
  const isManager = checkClaims(
    currentUserClaim,
    Claims.backofficeClaims.speaker.create
  );
  const [updateData, setUpdateData] = useState({});
  const checkData = Object.keys(updateData).length === 0;
  const [dataChanged, setDataChanged] = useState(true);
  const [imgSrc, setImgSrc] = useState('');

  const redirect = '/speaker-list';
  const [handleError, setHandleError] = useState('');
  const [error, setError] = useState({});

  const goBack = () => {
    navigate(-1);
    dispatch(Actions.speakerActions.cleanState());
  };

  useEffect(() => {
    if (handleError !== '' && !speakerIsSuccess) {
      toast.warning(handleError, {
        onClose: () => {
          setHandleError('');
        },
      });
    } else if (handleError !== '' && speakerIsSuccess) {
      setUpdateData(speakerData);
      dispatch(Actions.resetStoreAction.resetStore());
      toast.success(handleError, {
        onClose: () => {
          setImgSrc('');
          setUpdateData({});
          setHandleError('');
          dispatch(Actions.speakerActions.cleanState());
          if (isManager) {
            navigate(redirect);
          } else if (!isManager) {
            FetchById();
          }
        },
      });
    }
  }, [handleError]);

  useEffect(() => {
    if (speakerIsSuccess && speakerValidationMessage !== '') {
      setHandleError(
        <span
          className="d-flex position-relative mx-auto justify-content-center fs-6 my-2"
          style={{ maxWidth: '550px' }}
        >
          <strong>{speakerValidationMessage}</strong>
        </span>
      );
    } else if (!speakerIsSuccess && speakerValidationMessage !== '') {
      setHandleError(
        <span
          className="d-flex position-relative mx-auto justify-content-center fs-6 my-2"
          style={{ maxWidth: '550px' }}
        >
          <strong>{speakerValidationMessage}</strong>
        </span>
      );
    }
  }, [speakerIsSuccess, speakerValidationMessage]);

  const customToastStyle = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  };

  useEffect(() => {
    if (
      Object.keys(speakerData).length > 0 &&
      Object.keys(updateData).length === 0
    ) {
      speakerData.detailInfo = JSON.parse(speakerData.detailInfo);
      setUpdateData({ ...speakerData });
    }
  }, [speakerData]);

  const onChangeText = (e, special = undefined) => {
    e.preventDefault();
    const { id, value, type, name } = e.target;
    if (special === undefined) {
      if (value !== '') {
        setUpdateData({ ...updateData, [id]: value });
      } else {
        setUpdateData((prevState) => {
          const { [id]: _, ...rest } = prevState;
          return rest;
        });
      }
      const validationResult = regexValidator(type, value, name);
      if (!validationResult && value !== '') {
        setError({
          ...error,
          [id]: (
            <span className="text-danger">
              {t('validationMessages.' + validationMessage(type))}
            </span>
          ),
        });
      } else {
        setError({ ...error, [id]: undefined });
      }
    } else {
      setUpdateData({
        ...updateData,
        [special]: { ...updateData[special], [id]: value },
      });
    }
    setDataChanged(false);
  };

  const onFocus = () => {
    if (Object.keys(updateData).length === 0) {
      setUpdateData({ ...speakerData });
    }
    if (error?.phoneNumber !== undefined) {
      const cleanValue = formatPhone(undefined, updateData?.phoneNumber);
      setUpdateData({
        ...updateData,
        phoneNumber: cleanValue,
      });
    }
  };
  useEffect(() => {
    if (
      updateData?.phoneNumber?.length > 15 ||
      error?.phoneNumber !== undefined
    ) {
      const cleanValue = formatPhone(undefined, updateData?.phoneNumber);
      setUpdateData({
        ...updateData,
        phoneNumber: cleanValue,
      });
    }
  }, [updateData?.phoneNumber, error?.phoneNumber]);

  const onBlur = (e) => {
    e.preventDefault();
    dispatch(Actions.speakerActions.holdValue({ ...updateData }));
  };

  const onSave = async (e) => {
    e.preventDefault();
    const apiObject = {
      ...updateData,
      detailInfo: JSON.stringify(updateData.detailInfo),
    };
    await dispatch(Actions.speakerActions.updateAction(apiObject));
  };
  async function FetchById() {
    await dispatch(
      Actions.speakerActions.getByIdAction(isManager ? speakerId : '')
    );
  }

  useEffect(() => {
    FetchById();
    return () => {
      dispatch(Actions.speakerActions.cleanState());
    };
  }, []);

  const handleSelectedData = (value, key) => {
    const dataArray = updateData?.[key]?.split(',');

    if (dataArray?.length === undefined) {
      const updatedData = { ...updateData, [key]: value };
      setUpdateData(updatedData);
      dispatch(Actions.speakerActions.holdValue(updatedData));
    }

    if (dataArray?.includes(value)) {
      const index = dataArray.indexOf(value);
      if (index !== -1) {
        const newDataArray = dataArray.slice();
        newDataArray.splice(index, 1);
        const updatedData = {
          ...updateData,
          [key]: newDataArray.join(','),
        };
        setUpdateData(updatedData);
        if (newDataArray?.length === 0) {
          const newData = { ...updateData };
          delete newData[key];
          setUpdateData(newData);
          dispatch(Actions.speakerActions.holdValue(newData));
        } else {
          dispatch(Actions.speakerActions.holdValue(updatedData));
        }
      }
    }

    if (dataArray?.length !== undefined && !updateData[key]?.includes(value)) {
      const updatedData = {
        ...updateData,
        [key]: updateData[key] + ',' + value,
      };
      setUpdateData(updatedData);
      dispatch(Actions.speakerActions.holdValue(updatedData));
    }
    if (value) {
      setDataChanged(false);
    }
  };

  const selectedLanguageLevels = [];
  if (levelGroup && levelGroup?.adult && levelGroup?.kids) {
    Object.values(levelGroup).forEach((lang) => {
      if (staticDatas.LanguageLevels[lang]) {
        selectedLanguageLevels.push(...staticDatas.LanguageLevels[lang]);
      }
    });
  }
  const [countryList, setCountryList] = useState([]);
  const countryNames = countryListAllIsoData.map((item) => {
    const name = getNames(lang?.split(',')[0].split('-')[0])[item.code];
    const isoName = item.name;
    const phone = item.phone;

    return { name: name, phone: phone, isoName: isoName };
  });

  useEffect(() => {
    if (levelGroup === null) {
      dispatch(Actions.preferenceActions.getLevelGroupAction(4));
    }
  }, [levelGroup]);

  useEffect(() => {
    setCountryList(countryNames);
    dispatch(Actions.locationActions.getAllAction());
  }, []);

  const isUpdateAble =
    Object.values(error).every((e) => e !== undefined) &&
    Object.keys(error).length > 0;

  const content = (
    <div className="container-fluid">
      <div className="d-flex justify-content-center mt-5">
        <div className="col-12">
          <Card
            body={
              <>
                <div className="d-sm-flex align-items-center justify-content-start ms-sm-2">
                  <div className="col-2 mt-sm-3">
                    <Button
                      type={ButtonType.Successbg}
                      icon={ButtonIcon.ArrowLeft}
                      iconColor={TextColors.Light}
                      bold
                      onClick={goBack}
                      buttonCol={3}
                    />
                  </div>
                </div>
                <div className="d-sm-flex align-items-center justify-content-center mb-4 form-div-border">
                  <HeadTags hSize={5} strong text={t('speaker.update_form')} />
                </div>
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-12 mt-4">
                    <div className="col-12 d-flex justify-content-center">
                      {speakerData.speakerImage || imgSrc ? (
                        <img
                          className={'img-fluid rounded me-1'}
                          src={imgSrc ? imgSrc : speakerData.speakerImage}
                          alt={speakerData.speakerImage}
                          height={200}
                          style={{ maxHeight: '200px' }}
                        />
                      ) : (
                        <FontAwesomeIcon icon={faCircleUser} size="10x" />
                      )}
                    </div>
                    <CustomFileInput
                      id={'speakerImage'}
                      hidden={true}
                      buttonText={t('personalInformation.upload_picture')}
                      textColor={TextColors.Light}
                      buttonColor={ButtonType.Successbg}
                      fileType={'Image'}
                      dataFrom={'Profile'}
                      removeState={imgSrc === '' ? true : false}
                      func={(val) => {
                        if (!val) {
                          setUpdateData({});
                          setImgSrc('');
                          setDataChanged(false);
                        } else {
                          setUpdateData(
                            Object.assign({}, speakerData, {
                              speakerImage: val.base64String,
                              extension: val.extension,
                            })
                          );
                          setImgSrc(val.imgSrc);
                          setDataChanged(false);
                        }
                      }}
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 col-12 mt-4">
                    <div className="row">
                      <div className="col">
                        <InputLabelOnBorder
                          id={'firstName'}
                          inputType={InputType.Text}
                          labelText={t('common.name')}
                          inputValue={
                            checkData
                              ? speakerData.firstName
                              : updateData.firstName
                          }
                          onChange={onChangeText}
                          onBlur={onBlur}
                          error={error?.firstName}
                          regexType={RegexType.Name}
                        />
                      </div>
                      <div className="col">
                        <InputLabelOnBorder
                          id={'lastName'}
                          inputType={InputType.Text}
                          labelText={t('common.lastName')}
                          inputValue={
                            checkData
                              ? speakerData.lastName
                              : updateData.lastName
                          }
                          onChange={onChangeText}
                          onBlur={onBlur}
                          error={error?.lastName}
                          regexType={RegexType.Name}
                        />
                      </div>
                    </div>

                    <div className="col-12 pt-4">
                      <InputLabelOnBorder
                        id={'email'}
                        inputType={InputType.Email}
                        labelText={t('common.email')}
                        inputValue={
                          checkData ? speakerData.email : updateData.email
                        }
                        onChange={onChangeText}
                        onBlur={onBlur}
                        error={error?.email}
                      />
                    </div>
                    <div className="row">
                      {' '}
                      <div className="col mt-4">
                        <InputLabelOnBorder
                          labelText={t('becomeAMentor.country_code')}
                          inputType={InputType.Text}
                          select={{
                            selectStyle: 'selectStyle',
                            option: countryList.map((item) => ({
                              key: `${item.name} (${item.phone})`,
                              value: `${item.phone} ${item.isoName}`,
                              name: 'countryCode',
                            })),

                            selectedValue: checkData
                              ? speakerData.countryCode
                              : updateData.countryCode,
                          }}
                          handleSelectChange={(val) => {
                            setUpdateData({
                              ...updateData,
                              [val.name]: val.value,
                            });
                            dispatch(
                              Actions.speakerActions.holdValue({
                                ...updateData,
                                [val.name]: val.value,
                              })
                            );
                            setDataChanged(false);
                          }}
                        />
                      </div>
                      <div className="col mt-4">
                        <InputLabelOnBorder
                          id={'phoneNumber'}
                          inputType={InputType.Tel}
                          labelText={t('common.phone')}
                          inputValue={
                            checkData
                              ? formatPhone(speakerData.phoneNumber)
                              : formatPhone(updateData.phoneNumber)
                          }
                          onChange={onChangeText}
                          placeholder={'(5xx) xxx xx xx'}
                          onBlur={onBlur}
                          onFocus={onFocus}
                          error={error?.phoneNumber}
                        />
                      </div>
                    </div>
                    <div className="col pt-4">
                      <InputLabelOnBorder
                        id={'dateOfBirth'}
                        inputType={InputType.Date}
                        labelText={t('personalInformation.birth_date')}
                        inputValue={
                          moment(updateData.dateOfBirth).format('yyyy-MM-DD') ||
                          ''
                        }
                        onChange={onChangeText}
                        onBlur={onBlur}
                      />
                    </div>
                  </div>

                  {isManager ? (
                    <div className="col-lg-6 col-md-6 col-12 mt-4">
                      <InputLabelOnBorder
                        id={'specialUrl'}
                        inputType={InputType.Text}
                        labelText={'Youtube'}
                        inputValue={
                          checkData
                            ? speakerData.specialUrl
                            : updateData.specialUrl
                        }
                        onChange={onChangeText}
                        onBlur={onBlur}
                      />
                    </div>
                  ) : null}

                  {isManager && (
                    <div className="col-lg-6 col-md-6 col-12 mt-4">
                      <InputLabelOnBorder
                        id={'displayOrder'}
                        inputType={InputType.Text}
                        labelText={'Display Order'}
                        inputValue={
                          checkData
                            ? speakerData.displayOrder
                            : updateData.displayOrder
                        }
                        onChange={onChangeText}
                        onBlur={onBlur}
                      />
                    </div>
                  )}

                  <div className="col-lg-6 col-md-6 col-12 mt-4">
                    <InputLabelOnBorder
                      labelText={t('becomeAMentor.country')}
                      inputType={InputType.Text}
                      select={{
                        selectStyle: 'selectStyle',
                        option: countryList.map((item) => ({
                          key: item.name,
                          value: item.name,
                          name: 'country',
                        })),
                        selectedValue: updateData.country,
                      }}
                      handleSelectChange={(val) => {
                        setUpdateData({
                          ...updateData,
                          [val.name]: val.value,
                        });
                        dispatch(
                          Actions.managerActions.holdValue({
                            ...updateData,
                            [val.name]: val.value,
                          })
                        );
                        setDataChanged(false);
                      }}
                    />{' '}
                  </div>
                  <div className="col-lg-6 col-md-6 col-12 mt-4">
                    <InputLabelOnBorder
                      id={'urlName'}
                      inputType={InputType.Text}
                      labelText={'Url Name'}
                      inputValue={
                        checkData ? speakerData.urlName : updateData.urlName
                      }
                      onChange={onChangeText}
                      onBlur={onBlur}
                    />
                  </div>
                  {isManager ? (
                    <div className="col-lg-6 col-md-6 col-12 mt-4">
                      <InputLabelOnBorder
                        id={'metaTitle'}
                        inputType={InputType.Text}
                        labelText={'Meta Title'}
                        inputValue={
                          checkData
                            ? speakerData.metaTitle
                            : updateData.metaTitle
                        }
                        onChange={onChangeText}
                        onBlur={onBlur}
                      />
                    </div>
                  ) : null}

                  {isManager ? (
                    <div className="col-lg-6 col-md-6 col-12 mt-4">
                      <InputLabelOnBorder
                        labelText={'Currency Unit'}
                        inputType={InputType.Text}
                        select={{
                          selectStyle: 'selectStyle',
                          option: staticDatas.CurrencyUnit,
                          selectedValue: checkData
                            ? speakerData?.currencyUnit
                            : updateData?.currencyUnit,
                        }}
                        handleSelectChange={(val) => {
                          setUpdateData({
                            ...updateData,
                            currencyUnit: val.value,
                          });
                          setDataChanged(false);
                        }}
                      />
                    </div>
                  ) : null}

                  <div className="col-lg-6 col-md-6 col-12 mt-4">
                    <div className="row">
                      <div className="col">
                        <InputLabelOnBorder
                          labelText={t('becomeAMentor.accent')}
                          inputType={InputType.Text}
                          select={{
                            selectStyle: 'selectStyle',
                            option: staticDatas.Native,
                            selectedValue: checkData
                              ? speakerData.native
                              : updateData.native,
                          }}
                          handleSelectChange={(val) => {
                            setUpdateData({
                              ...updateData,
                              [val.name]: val.value,
                            });
                            dispatch(
                              Actions.speakerActions.holdValue({
                                ...updateData,
                                [val.name]: val.value,
                              })
                            );
                            setDataChanged(false);
                          }}
                        />
                      </div>
                      {isManager && (
                        <div className={`col`}>
                          <Input
                            key={'isFullTimer'}
                            id={'isFullTimer'}
                            value={updateData.isFullTimer}
                            labelValue={'Is Full Timer?'}
                            checked={
                              checkData
                                ? speakerData.isFullTimer
                                : updateData.isFullTimer
                            }
                            type={InputType.Checkbox}
                            onClick={(e) => {
                              setUpdateData({
                                ...updateData,
                                [e.target.id]: !updateData.isFullTimer,
                              });
                              setDataChanged(false);
                            }}
                          />
                        </div>
                      )}
                      {isManager && (
                        <div className="col">
                          <Input
                            key={'isActive'}
                            id={'isActive'}
                            value={updateData.isActive}
                            labelValue={t('speaker.is_active')}
                            checked={
                              checkData
                                ? speakerData.isActive
                                : updateData.isActive
                            }
                            type={InputType.Checkbox}
                            onClick={(e) => {
                              setUpdateData({
                                ...updateData,
                                [e.target.id]: !updateData.isActive,
                              });
                              setDataChanged(false);
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-6 mt-4">
                    <InputLabelOnBorder
                      id={'Education'}
                      inputType={InputType.Text}
                      labelText={t('becomeAMentor.education')}
                      inputValue={updateData?.detailInfo?.Education || ''}
                      onChange={(e) => onChangeText(e, 'detailInfo')}
                      onBlur={onBlur}
                      textArea={{ row: 3, col: 20 }}
                    />
                  </div>
                  <div className="col-6 mt-4">
                    <InputLabelOnBorder
                      id={'Profession'}
                      inputType={InputType.Text}
                      labelText={t('becomeAMentor.profession')}
                      inputValue={updateData.detailInfo?.Profession || ''}
                      onChange={(e) => onChangeText(e, 'detailInfo')}
                      onBlur={onBlur}
                      textArea={{ row: 3, col: 20 }}
                    />
                  </div>
                  <div className="col-6 mt-4">
                    <InputLabelOnBorder
                      id={'Certificates'}
                      inputType={InputType.Text}
                      labelText={t('becomeAMentor.certificates')}
                      inputValue={updateData?.detailInfo?.Certificates || ''}
                      onChange={(e) => onChangeText(e, 'detailInfo')}
                      onBlur={onBlur}
                      textArea={{ row: 3, col: 20 }}
                    />
                  </div>
                  <div className="col-6 mt-4">
                    <DropdownWithSearch
                      isSearchable
                      isMulti
                      onBlur={onBlur}
                      placeHolder={t('dropdownSelect.select_learningPurpose')}
                      options={staticDatas.LearningPurpose}
                      selectedProps={{
                        key: 'key',
                        value: 'value',
                      }}
                      selectedValues={
                        checkData
                          ? speakerData.learningPurposes
                          : updateData.learningPurposes
                      }
                      onChange={(value) => {
                        handleSelectedData(value, 'learningPurposes');
                        setDataChanged(false);
                      }}
                    />
                  </div>

                  {isManager && (
                    <div className="col-6 mt-4">
                      <DropdownWithSearch
                        isSearchable
                        isMulti
                        onBlur={onBlur}
                        placeHolder={t('dropdownSelect.select_user_area')}
                        options={staticDatas.UserArea}
                        selectedValues={
                          checkData
                            ? speakerData.displayAreas
                            : updateData.displayAreas
                        }
                        selectedProps={{
                          key: 'key',
                          value: 'value',
                        }}
                        onChange={(value) =>
                          handleSelectedData(value, 'displayAreas')
                        }
                      />
                    </div>
                  )}
                  {isManager && (
                    <div className="col-6 mt-4">
                      <DropdownWithSearch
                        isMulti
                        isSearchable
                        onBlur={onBlur}
                        placeHolder={t('dropdownSelect.select_location')}
                        options={locationList.map((item) => ({
                          key: item.locationName,
                          value: String(item.id),
                          name: 'locations',
                        }))}
                        selectedValues={
                          checkData
                            ? speakerData.locations
                            : updateData.locations
                        }
                        selectedProps={{ key: 'key', value: 'value' }}
                        onChange={(value) => {
                          handleSelectedData(String(value), 'locations');
                        }}
                      />
                    </div>
                  )}
                  {isManager && (
                    <div className="col-6 mt-4">
                      <DropdownWithSearch
                        isSearchable
                        isMulti
                        onBlur={onBlur}
                        placeHolder={t('dropdownSelect.select_level')}
                        options={selectedLanguageLevels}
                        selectedProps={{
                          key: 'key',
                          value: 'key',
                        }}
                        selectedValues={
                          checkData
                            ? speakerData.languageLevels
                            : updateData.languageLevels
                        }
                        onChange={(value) => {
                          handleSelectedData(value, 'languageLevels');
                          setDataChanged(false);
                        }}
                      />
                    </div>
                  )}

                  {isManager ? (
                    <div className="col-12 mt-4">
                      <InputLabelOnBorder
                        id={'metaDescription'}
                        inputType={InputType.Text}
                        labelText={'İntroductions'}
                        inputValue={
                          checkData
                            ? speakerData.metaDescription
                            : updateData.metaDescription
                        }
                        onChange={onChangeText}
                        onBlur={onBlur}
                        textArea={{ row: 3, col: 20 }}
                      />
                    </div>
                  ) : null}
                  <div className="col-12 mt-4">
                    <label
                      className="ms-2 position-absolute"
                      style={{
                        marginTop: '-1.25rem',
                        fontSize: '.9rem',
                      }}
                    >
                      <span className="h6 small bg-white text-muted px-1">
                        {t('About Speaker')}
                      </span>
                    </label>
                    <CKEditor
                      editor={ClassicEditor}
                      data={updateData?.htmlContent || ''}
                      onChange={(event, editor) => {
                        const data = editor?.getData();
                        const dataToUpdate = (prevData, data) => {
                          const newData = { ...prevData, htmlContent: data };
                          return newData;
                        };
                        setDataChanged(
                          dataToUpdate.htmlContent !== updateData.htmlContent
                            ? false
                            : true
                        );
                        setUpdateData((prevData) =>
                          dataToUpdate(prevData, data)
                        );
                      }}
                    />
                  </div>
                  <div className="col-3 d-flex ms-auto justify-content-end align-items-center text-nowrap mt-3">
                    <Button
                      type={
                        dataChanged || isUpdateAble
                          ? ButtonType.Secondary
                          : ButtonType.Successbg
                      }
                      text={t('button.submit')}
                      textColor={TextColors.Light}
                      onClick={onSave}
                      buttonCol={12}
                      disabled={dataChanged || isUpdateAble ? true : false}
                    />
                  </div>
                </div>
              </>
            }
          />
        </div>
      </div>
      {handleError !== '' && (
        <ToastContainer
          style={customToastStyle}
          autoClose={speakerIsSuccess ? 1000 : false}
          closeOnClick
          theme="light"
        />
      )}
    </div>
  );

  return checkClaims(
    currentUserClaim,
    Claims.backofficeClaims.speaker.update,
    content
  );
};
export default Update;
