import SpeakerCreate from './Speaker/Create';
import SpeakerEffortReport from './Speaker/SpeakerEffortReport';
import SpeakerEffortDetails from './Speaker/SpeakerEffortDetails';
import SpeakerList from './Speaker/List';
import AvailabilityCalendar from './Speaker/SpeakerAvability/AvailabilityCalendar';
import QuestionCreate from './Speaker/SpeakerDetails/QuestionCreate';
import QuestionList from './Speaker/SpeakerDetails/QuestionList';
import Details from './Speaker/SpeakerDetails/Details';
import VotesBySpeakers from './Speaker/SpeakerDetails/VotesBySpeakers';
import AttendeeCreate from './Attendee/Create';
import AttendeeList from './Attendee/List';
import AttendeeUpdate from './Attendee/Update';
import ManagerList from './Manager/List';
import ManagerCreate from './Manager/Create';
import ManagerUpdate from './Manager/Update';
import UserList from './User/List';
import UserCreate from './User/Create';
import Roles from './User/Roles';
import MeetingSchemaList from './MeetingSchema/List';
import MeetingSchemaCreate from './MeetingSchema/Create';
import MeetingSchemaUpdate from './MeetingSchema/Update';
import OrderList from './Order/List';
import OrderCreate from './Order/Create';
import OrderUpdate from './Order/Update';
import EftPayments from './Order/EftControl';
import Payments from './Order/Payments';
import AttendeeFreezeCreate from './Attendee/AttendeeFreeze/AttendeeFreezeCreate';
import AttendeeFreezeList from './Attendee/AttendeeFreeze/AttendeeFreezeList';
import BranchHolidayCreate from './BranchHoliday/Create';
import BranchHolidayList from './BranchHoliday/List';
import BranchHolidayUpdate from './BranchHoliday/Update';
import CreatePackage from './Packages/CreatePackage';
import PackageList from './Packages/PackageList';
import CallThem from './CallThem';
import SpeakerUpdate from './Speaker/Update';
import UserUpdate from './User/Update';
import AgeIntervalList from './PreferencePages/AgeInterval/List';
import AgeIntervalCreate from './PreferencePages/AgeInterval/Create';
import AgeIntervalUpdate from './PreferencePages/AgeInterval/Update';
import LocationList from './PreferencePages/Location/List';
import LocationCreate from './PreferencePages/Location/Create';
import LocationUpdate from './PreferencePages/Location/Update';
import SessionCategoryList from './PreferencePages/SessionCategory/List';
import SessionCategoryCreate from './PreferencePages/SessionCategory/Create';
import SessionCategoryUpdate from './PreferencePages/SessionCategory/Update';
import SessionPackageList from './PreferencePages/SessionPackage/List';
import SessionPackageCreate from './PreferencePages/SessionPackage/Create';
import SessionPackageUpdate from './PreferencePages/SessionPackage/Update';
import TimesPerMonthList from './PreferencePages/TimesPerMonth/List';
import TimesPerMonthCreate from './PreferencePages/TimesPerMonth/Create';
import TimesPerMonthUpdate from './PreferencePages/TimesPerMonth/Update';
import TimesPerWeekList from './PreferencePages/TimesPerWeek/List';
import TimesPerWeekCreate from './PreferencePages/TimesPerWeek/Create';
import TimesPerWeekUpdate from './PreferencePages/TimesPerWeek/Update';
import ZoneList from './PreferencePages/Zone/List';
import ZoneCreate from './PreferencePages/Zone/Create';
import ZoneUpdate from './PreferencePages/Zone/Update';
import TestRedate from './TestRedate';
import TestPrice from './TestPrice';
import CareerList from './Career/List';
import SegmentEditor from './Notifications/SegmentEditor';
import ManageClaims from './OperationClaim/ManageClaims';
import ScheduleManager from './Operation/ScheduleManager';
import OperationManagment from './Operation/OperationManagment';
import ScheduleMeetings from './Operation/SchedulePlan';
import BannerCreate from './Banner/Create';
import BannerList from './Banner/List';
import BannerUpdate from './Banner/Update';
import MeetingList from './Meetings/List';
import FeedbackList from './Feedback/List';
import OrderItemList from './Order/OrderItem/List';
import OrderItemUpdate from './Order/OrderItem/Update';
import CreatedTodayTrials from './Order/FreeTrial/CreatedToday';
import UpcomingTrials from './Order/FreeTrial/Upcoming';
import CouponCreate from './CouponCodes/Create';
import CouponList from './CouponCodes/List';
import CouponUpdate from './CouponCodes/Update';
import LeadList from './Lead/List';
import LeadUpdate from './Lead/Update';
import RenewOrderItem from './Order/OrderItem/RenewOrderItem';
import RemainingOrderCalculation from './Order/RemainingOrderCalculation';
import CompanyList from './Company/List';
import CompanyCreate from './Company/Create';
import CompanyUpdate from './Company/Update';
import CompanyOrderList from './Company/CompanyOrder/List';
import CompanyOrderCreate from './Company/CompanyOrder/Create';
import CompanyOrderUpdate from './Company/CompanyOrder/Update';
import AttendeeOrderHistory from './Attendee/AttendeeOrderDetails';
import CampaignList from './Campaign/List';
import CampaignCreate from './Campaign/Create';
import CampaignUpdate from './Campaign/Update';
import RepotingList from './Reporting/List';
import CompanyReporting from './Company/Reproting';
import MergeCSV from './MergeCsv';

const BackOfficePages = {
  SpeakerCreate,
  SpeakerEffortReport,
  SpeakerEffortDetails,
  SpeakerList,
  QuestionCreate,
  QuestionList,
  Details,
  VotesBySpeakers,
  AttendeeCreate,
  AttendeeList,
  ManagerList,
  ManagerCreate,
  UserList,
  UserCreate,
  Roles,
  MeetingSchemaList,
  MeetingSchemaCreate,
  MeetingSchemaUpdate,
  OrderList,
  OrderCreate,
  OrderUpdate,
  EftPayments,
  AttendeeFreezeCreate,
  AttendeeFreezeList,
  BranchHolidayCreate,
  BranchHolidayList,
  BranchHolidayUpdate,
  CreatePackage,
  PackageList,
  CallThem,
  SpeakerUpdate,
  AttendeeUpdate,
  ManagerUpdate,
  UserUpdate,
  AgeIntervalList,
  AgeIntervalCreate,
  AgeIntervalUpdate,
  LocationList,
  LocationCreate,
  LocationUpdate,
  SessionCategoryList,
  SessionCategoryCreate,
  SessionCategoryUpdate,
  SessionPackageList,
  SessionPackageCreate,
  SessionPackageUpdate,
  TimesPerMonthList,
  TimesPerMonthCreate,
  TimesPerMonthUpdate,
  TimesPerWeekList,
  TimesPerWeekCreate,
  TimesPerWeekUpdate,
  ZoneList,
  ZoneCreate,
  ZoneUpdate,
  TestRedate,
  TestPrice,
  CareerList,
  SegmentEditor,
  ManageClaims,
  ScheduleManager,
  OperationManagment,
  BannerCreate,
  BannerList,
  BannerUpdate,
  MeetingList,
  Payments,
  FeedbackList,
  ScheduleMeetings,
  OrderItemList,
  OrderItemUpdate,
  CreatedTodayTrials,
  UpcomingTrials,
  CouponCreate,
  CouponList,
  CouponUpdate,
  LeadList,
  LeadUpdate,
  RenewOrderItem,
  RemainingOrderCalculation,
  CompanyList,
  CompanyCreate,
  CompanyUpdate,
  CompanyOrderList,
  CompanyOrderCreate,
  CompanyOrderUpdate,
  AttendeeOrderHistory,
  AvailabilityCalendar,
  CampaignList,
  CampaignCreate,
  CampaignUpdate,
  RepotingList,
  CompanyReporting,
  MergeCSV
};

export default BackOfficePages;
