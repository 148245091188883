import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Papa from 'papaparse';
import Actions from '../../../store/redux/actions';
import Button from '../../../components/Common/Button/Button';
import HeadTags from '../../../components/Common/HeadTags/HeadTags';
import InputLabelOnBorder from '../../../components/Common/Input/InputLabelOnBorder';
import Toast from '../../../components/Common/Popup/Toast';
import SubmitButton from '../../../components/Common/Button/SubmitButton';
import Card from '../../../components/Common/Card/Card';
import DropdownWithSearch from '../../../components/Common/Dropdown/DropdownWithSearch';
import {
  ButtonIcon,
  ButtonType,
  TextColors,
  InputType,
} from '../../../utils/ComponentEnums';
import { MonthOptions, SessionCapacity, UserArea } from '../../../utils/Enum';
import Claims from '../../../utils/Claims';
import addDay from '../../../hooks/useAddDay';
import checkClaims from '../../../hooks/useCheckClaims';
import Input from '../../../components/Common/Input/Input';

const Create = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentUserClaim = useSelector((state) => state.auth.claim);
  const locationList = useSelector((state) => state.location.getAll);
  const redirect = '/coupon-code-list';
  const [createData, setCreateData] = useState({
    details: [
      {
        month: 1,
        capacity: [],
        userArea: [],
        location: [],
        discount: 0,
        isPercent: true,
      },
      {
        month: 3,
        capacity: [],
        userArea: [],
        location: [],
        discount: 0,
        isPercent: true,
      },
      {
        month: 6,
        capacity: [],
        userArea: [],
        location: [],
        discount: 0,
        isPercent: true,
      },
      {
        month: 12,
        capacity: [],
        userArea: [],
        location: [],
        discount: 0,
        isPercent: true,
      },
    ],
    emails: null,
    hasSub: false,
    isPercent: true,
  });
  const fileInputRef = useRef(null);

  useEffect(() => {
    dispatch(Actions.locationActions.getAllAction());
  }, []);

  const goBack = () => {
    navigate(-1);
    dispatch(Actions.couponCodesActions.cleanState(true));
  };

  const onChangeText = (e) => {
    e.preventDefault();
    const { id, value } = e.target;
    setCreateData({ ...createData, [id]: value === '' ? undefined : value });
  };
  const onChangeDetails = (index, e) => {
    const { id, value } = e.target;
    const updatedDetails = [...createData.details];
    updatedDetails[index][id] = value === '' ? 0 : parseInt(value);
    setCreateData({ ...createData, details: updatedDetails });
  };

  const onCreate = async (e) => {
    e.preventDefault();
    const filteredDetails = createData.details.filter(
      (detail) => detail.discount !== 0
    );
    const filteredData = {
      ...createData,
      details: filteredDetails,
    };
    await dispatch(Actions.couponCodesActions.createAction(filteredData));
  };

  const onChangeDate = (e) => {
    e.preventDefault();
    const { id, value } = e.target;
    if (value !== '') {
      const [year, month, day] = value.split('-').map(Number);
      const normalDateTime = addDay(
        `00:00`,
        `${day}-${month}-${year}`,
        false,
        false,
        true
      );
      setCreateData({
        ...createData,
        [id]: normalDateTime,
        [`${id}View`]: value,
      });
    } else {
      setCreateData((prevState) => {
        const { [id]: _, [`${id}View`]: __, ...rest } = prevState;
        return rest;
      });
    }
  };

  const handleDropdown = (monthIndex, field, value) => {
    const currentDetails = [...createData.details];
    const currentDetail = currentDetails[monthIndex];

    let updatedArray = [...(currentDetail[field] || [])];
    const valueIndex = updatedArray.indexOf(value);

    if (valueIndex !== -1) {
      updatedArray.splice(valueIndex, 1);
    } else {
      updatedArray.push(value);
    }
    currentDetails[monthIndex] = {
      ...currentDetail,
      [field]: updatedArray,
    };
    setCreateData({
      ...createData,
      details: currentDetails,
    });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      Papa.parse(file, {
        complete: (results) => {
          const emails = extractEmails(results.data);
          const concatenatedEmails = concatenateEmails(emails);
          setCreateData({
            ...createData,
            emails: concatenatedEmails,
            fileName: file.name,
          });
        },
        header: true,
        skipEmptyLines: true,
      });
    }
  };

  const extractEmails = (data) => {
    return data.flatMap((row) => row['Email Address'] || []);
  };

  const concatenateEmails = (emails) => {
    return emails
      .map((email) => email.trim())
      .filter((email) => email.length > 0)
      .join(',');
  };

  const handleFileInsert = (e) => {
    e.preventDefault();
    fileInputRef.current.click();
  };

  const content = (
    <div className="container-fluid">
      <div className="d-flex justify-content-center mt-0 mt-md-5">
        <div className="col-12">
          <Card
            body={
              <React.Fragment>
                <div className="d-sm-flex align-items-center justify-content-start ms-sm-2 mt-sm-3">
                  <div className="col-12 col-md-1">
                    <Button
                      type={ButtonType.Successbg}
                      icon={ButtonIcon.ArrowLeft}
                      iconColor={TextColors.Light}
                      bold
                      onClick={goBack}
                      buttonCol={12}
                    />
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-center mb-4 mt-2 mt-md-0 form-div-border">
                  <HeadTags
                    hSize={5}
                    strong
                    text={t('couponCodes.add_new_form')}
                  />
                </div>
                <div className="row">
                  <div className="col-12 col-md-6">
                    <InputLabelOnBorder
                      id={'name'}
                      inputType={InputType.Text}
                      labelText={t('common.name')}
                      inputValue={createData.name || ''}
                      onChange={onChangeText}
                      required
                    />
                  </div>
                  <div className="col-12 col-md-6 mt-md-0 mt-1">
                    <InputLabelOnBorder
                      id={'codes'}
                      inputType={InputType.Text}
                      labelText={t('couponCodes.codes')}
                      inputValue={createData.codes}
                      onChange={onChangeText}
                      required
                    />
                  </div>
                  <div className="col-12 col-md-6 mt-md-4 mt-1">
                    <InputLabelOnBorder
                      id={'startTime'}
                      inputType={InputType.Date}
                      labelText={t('common.start_date')}
                      inputValue={createData.startTimeView || ''}
                      onChange={onChangeDate}
                      required
                    />
                  </div>
                  <div className="col-12 col-md-6 mt-md-4 mt-1">
                    <InputLabelOnBorder
                      id={'endTime'}
                      inputType={InputType.Date}
                      labelText={t('common.end_date')}
                      inputValue={createData.endTimeView || ''}
                      onChange={onChangeDate}
                      required
                    />
                  </div>
                  {/* <div className="col-12 col-md-6 mt-md-4 mt-1">
                    <Input
                      key={'hasSub'}
                      id={'hasSub'}
                      value={createData.hasSub}
                      labelValue={t('couponCodes.hasSub')}
                      labelFontType={LabelFontSize.fs5}
                      checked={createData.hasSub || false}
                      type={InputType.Checkbox}
                      onClick={(e) => {
                        setCreateData({
                          ...createData,
                          [e.target.id]: !createData.hasSub,
                        });
                      }}
                    />
                  </div>
                  {createData.hasSub && (
                    <div className="col-12 col-md-6 mt-md-4 mt-1">
                      <InputLabelOnBorder
                        id={'limit'}
                        inputType={InputType.Number}
                        labelText={t('couponCodes.limit')}
                        inputValue={createData.limit || ''}
                        onChange={onChangeText}
                      />
                    </div>
                  )} */}

                  <div className="col-12 col-md-6 mt-md-4 mt-1">
                    <div className="d-flex justify-content-center mt-2">
                      <input
                        id={'csv'}
                        ref={fileInputRef}
                        type={InputType.File}
                        className={'d-none'}
                        hidden={true}
                        onChange={handleFileChange}
                        accept=".csv"
                      />
                      <span
                        role={'button'}
                        onClick={handleFileInsert}
                        className="text-muted"
                        style={{ fontSize: '.7rem' }}
                      >
                        {createData.fileName === undefined
                          ? t('fileUpload.upload_document')
                          : createData.fileName}
                      </span>
                    </div>
                    <div className="d-flex justify-content-center mt-2">
                      <button
                        className={`btn btn-sm ${ButtonType.Successbg}`}
                        onClick={handleFileInsert}
                      >
                        <span
                          style={{ fontSize: '.98rem' }}
                          className={TextColors.Light}
                        >
                          {t('couponCodes.attendeeList')}
                        </span>
                      </button>
                    </div>
                  </div>
                  {createData.details.map((detail, index) => (
                    <div
                      key={`${detail.month}-${index}`}
                      className="d-flex flex-column flex-sm-row align-items-center mt-md-4"
                    >
                      <div className="col-12 col-md-1 me-md-1 mt-md-0 mt-1">
                        <InputLabelOnBorder
                          id={`details[${index}].month`}
                          labelText={t('couponCodes.month')}
                          inputValue={
                            MonthOptions.filter(
                              (val) => val?.key === detail?.month
                            )?.[0]?.key
                          }
                          disabled={true}
                        />
                      </div>
                      <div className="col-12 col-md-2 me-md-1 mt-md-1 mt-2">
                        <DropdownWithSearch
                          id={`details[${index}].capacity`}
                          options={SessionCapacity}
                          selectedProps={{
                            key: 'capacity',
                            value: 'capacity',
                          }}
                          placeHolder={t('dropdownSelect.select_capacity')}
                          onChange={(value) =>
                            handleDropdown(index, 'capacity', value)
                          }
                          isfilter={false}
                          isMulti={true}
                        />
                      </div>
                      <div className="col-12 col-md-3 me-md-1 mt-md-1 mt-2">
                        <DropdownWithSearch
                          isSearchable
                          placeHolder={t('dropdownSelect.select_user_area')}
                          options={UserArea}
                          selectedProps={{ key: 'key', value: 'value' }}
                          onChange={(value) =>
                            handleDropdown(index, 'userArea', value)
                          }
                          isfilter={false}
                          isMulti={true}
                        />
                      </div>
                      <div className="col-12 col-md-5 me-md-1 mt-md-1 mt-2">
                        <DropdownWithSearch
                          isSearchable
                          placeHolder={t('dropdownSelect.select_location')}
                          options={locationList}
                          selectedProps={{ key: 'locationName', value: 'id' }}
                          onChange={(value) =>
                            handleDropdown(index, 'location', value)
                          }
                          isfilter={false}
                          isMulti={true}
                        />
                      </div>
                      <div className="col-12 col-md-1">
                        <InputLabelOnBorder
                          key={`details[${index}].discount`}
                          id={'discount'}
                          inputType={InputType.Number}
                          labelText={t('couponCodes.discount')}
                          inputValue={detail.discount}
                          minNumber={1}
                          maxNumber={100}
                          onChange={(e) => onChangeDetails(index, e)}
                        />
                      </div>
                    </div>
                  ))}
                  <div className="d-flex justify-content-center justify-content-md-end mt-3">
                    <div className="col-12 col-md-2">
                      <SubmitButton
                        stateName={'couponCode'}
                        onClick={onCreate}
                        objectLengthRule={
                          Object.keys(createData).length > 7 &&
                          createData.details.some(
                            (val) =>
                              val.discount !== 0 &&
                              val.capacity.length > 0 &&
                              val.location.length > 0 &&
                              val.userArea.length > 0
                          )
                            ? false
                            : true
                        }
                      />
                    </div>
                  </div>
                </div>
              </React.Fragment>
            }
          />
        </div>
      </div>
      <Toast
        stateName={'couponCode'}
        onClose={() => {
          setCreateData({});
          dispatch(Actions.couponCodesActions.cleanState());
          navigate(redirect);
        }}
      />
    </div>
  );
  return checkClaims(
    currentUserClaim,
    Claims.backofficeClaims.couponCodes.create,
    content
  );
};
export default Create;
