import {
    selectedDateBeforeCreatedAndCompletedOrdersAttendeeDetails
} from '../../../services/apiServices/reportingServices';
import ActionTypes from './actionTypes';
import config from '../../../config.json';
import { reponseStatus, responseErrorStatus } from '../../../utils/Enum';

const selectedDateBeforeCreatedAndCompletedOrdersAttendeeDetailsAction = (filterData) => {
    return async (dispatch) => {
        dispatch(cleanState(true, true));
        dispatch(getAllReducer([]))
        let maxRetries = config.RequestRule.retryCount;
        let isFirstRequest = true;
        while (maxRetries > 0) {
            if (!isFirstRequest) {
                await new Promise(resolve => setTimeout(resolve, 2000));
            }
            try {
                const res = await selectedDateBeforeCreatedAndCompletedOrdersAttendeeDetails(filterData);
                let response = res?.data?.data;
                await dispatch(getAllReducer(response));
                await dispatch(handleError(""));
                isFirstRequest = false;
                break;
            } catch (error) {
                isFirstRequest = false;
                maxRetries--;
                if (maxRetries === 0) {
                    await dispatch(handleValidationReducer(responseErrorStatus.RetryTimeOut));
                    await dispatch(handleError(false));
                    break;
                }
            }
        }
    };
};

const getAllReducer = (data) => {
    return { type: ActionTypes.reporting.GetAll, payload: data };
};

const handleError = (data) => {
    return {
        type: ActionTypes.reporting.HandleError,
        payload: data,
    };
};
const handleValidationReducer = (data) => {
    return {
        type: ActionTypes.reporting.HandleValidation,
        payload: data,
    };
};

const cleanState = (validation = false, ongoing) => {
    return async (dispatch) => {
        if (validation) {
            dispatch(handleValidationReducer(''));
            dispatch(handleError(ongoing ? reponseStatus.ongoing : reponseStatus.default));
        } else {
            await dispatch(handleValidationReducer(''));
            await dispatch(handleError(''));
            await dispatch(getAllReducer([]));
        }
    };
};

const reportingActions = {
    selectedDateBeforeCreatedAndCompletedOrdersAttendeeDetailsAction,
    cleanState,
};
export default reportingActions;
