import React, { useEffect, useState } from 'react';
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Actions from '../../../store/redux/actions';
import ProgressLine from '../../../components/Common/ProgressLine/ProgressLine';
import Card from '../../../components/Common/Card/Card';
import SubscriptionDropdown from '../../../components/Common/Dropdown/SubscriptionDropdown';
import Button from '../../../components/Common/Button/Button';
import HeadTags from '../../../components/Common/HeadTags/HeadTags';
import Tooltip from '../../../components/Common/ToolTip/Tooltip';
import Modal from '../../../components/Common/Modal/Modal';
import SubmitButton from '../../../components/Common/Button/SubmitButton';
import InputLabelOnBorder from '../../../components/Common/Input/InputLabelOnBorder';
import Toast from '../../../components/Common/Popup/Toast';
import ToastWithParams from '../../../components/Common/Popup/ToastWithParams';
import { Loader } from '../../../components/Common/Loader/Loader';
import {
  ButtonIcon,
  ButtonType,
  TextColors,
  InputType,
} from '../../../utils/ComponentEnums';
import { TimesPerWeek, storageKeys } from '../../../utils/Enum';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import useLoader from '../../../hooks/useLoader';
import findMyPath from '../../../hooks/useFindMyPath';
import useGTM from '../../../hooks/useGTM';

const PrivatePackage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const queryParams = new URLSearchParams(location.search);
  const orderItems = useSelector((state) => state.order.basketItems);
  const auth = useSelector((state) => state.auth.auth);
  const calculationResult = useSelector(
    (state) => state.price.calculatedPrices
  );
  const staticDatas = useSelector((state) => state.staticData);
  const learningPurpose = staticDatas.LearningPurpose;
  const userInfo = useSelector((state) => state.user.getById);
  const currency = useSelector(
    (state) => state.preferenceOptions.preferenceCurrencyUnit
  );
  const levelGroup = useSelector(
    (state) => state.preferenceOptions.preferenceLevelGroup
  );
  const sessionPackage = useSelector((state) => state.sessionPackage.getAll);
  const ageInterval = useSelector((state) => state.ageInterval.getAll);
  const selfUpdate = useSelector((state) => state.user.selfUpdate);
  const holdFilter = useSelector((state) => state.holdFilter.filter);
  const isChild = userInfo?.isChild ?? null;
  const myState = location.state?.createData;
  const isGift = myState?.isGift;
  const orderRouting = findMyPath('order/paymentdetail');
  const speakerRouting = findMyPath('speakers');
  const checkOrderItemDetails =
    orderItems?.unpayedOrderItemList?.[0].scheduleUserSelection?.attendeeId !==
    undefined;
  const affiliation = queryParams?.get('utm_source');
  const { sendEventToDataLayer, generateItemId } = useGTM();
  const isLoggedIn = auth !== '';
  const isOnline = myState?.userArea === 0;
  const isCafe = myState?.userArea === 1;
  const isKids = myState?.userArea === 2;

  const [createData, setCreateData] = useState({
    userArea: myState?.userArea,
    trialStatus: myState?.trialStatus,
    type: 1,
    location: [1],
    monthDuration: 1,
    timesPerWeek: 1,
    couponCode: null,
    currency: currency.code,
    ageIntervalView: 'onlineGroup.age_interval',
    languageLevels: !isLoggedIn
      ? holdFilter?.LanguageLevels || null
      : userInfo.languageLevel,
    learningPurpose: holdFilter?.LearningPurpose || null,
    productType: myState?.productType,
    ageInterval: holdFilter?.AgeId || null,
  });
  const [error, setError] = useState(null);
  const [showLoader, setShowLoader] = useState(null);
  const [modalState, setModalState] = useState({
    loginModal: { isOpen: false },
    basketModal: { isOpen: false },
  });
  const [openModal, setOpenModal] = useState(false);
  const [notifyMessage, setNotifyMessage] = useState('');
  const [promoCode, setPromoCode] = useState({ couponCode: '' });

  const goBack = () => navigate(-1);

  function triggerFinalViewItem(event) {
    const learningPurposes = holdFilter?.LearningPurpose?.map((item) =>
      t(`LearningPurpose.${item.key}`)
    );
    let selectedMonthOption =
      holdFilter?.TrialStatus === 2
        ? calculationResult?.totalCostItems[holdFilter.MonthDuration - 1]
        : 0;
    sendEventToDataLayer({
      event: event,
      ecommerce: {
        currency: currency.name === 'TL' ? 'TRY' : currency.name,
        value: selectedMonthOption?.netAmount,
        attendee:
          holdFilter.UserArea === 0
            ? 'Online'
            : holdFilter.UserArea === 2
            ? 'Kids'
            : holdFilter.UserArea === 1
            ? 'Cafe'
            : 'Company',
        language_level: t(holdFilter.LanguageLevels ?? '', { lng: 'en' }),
        items: [
          {
            item_id: generateItemId(
              1,
              holdFilter.UserArea,
              holdFilter.PackageId,
              holdFilter.TimesPerWeek,
              holdFilter?.TrialStatus === 2
                ? selectedMonthOption.rowIndex || 1
                : 0,
              1
            ),
            item_name:
              holdFilter?.TrialStatus === 2
                ? `${selectedMonthOption?.month || 1} ${t('onlineGroup.month', {
                    lng: 'en',
                  })}`
                : holdFilter?.TrialStatus === 0
                ? `Free Trial`
                : `Paid Trial`,
            session_type: holdFilter.Person > 1 ? 'Group' : 'OneToOne',
            days: holdFilter.TimesPerWeek,
            duration: holdFilter.Minute,
            age_interval:
              holdFilter.UserArea === 2
                ? holdFilter.AgeIntervalView
                : '18 - 100',
            learning_purposes: learningPurposes ?? '',
            affiliation: affiliation ?? '',
            coupon: holdFilter.CouponCode ?? '',
            index: holdFilter.MonthDuration,
            item_brand: 'Meet2Talk',
            price:
              holdFilter?.TrialStatus === 2
                ? selectedMonthOption?.netAmount
                : 0,
            quantity: 1,
          },
        ],
      },
    });
  }

  const onSelectSubsLenght = (e) => {
    e.preventDefault();
    const selectedTabIndex = e.currentTarget.tabIndex;
    setCreateData({
      ...createData,
      monthDuration: selectedTabIndex + 1,
    });
  };

  const handleContinueParent = async (isGift = false) => {
    const myData = {
      attendeeId: null,
      trialStatus: 2,
      userArea: createData.userArea,
      ageId: createData.ageInterval,
      packageOptions: {
        type: 1,
        location: createData?.location?.[0],
        monthDuration: createData?.monthDuration,
        timesPerWeek: createData.timesPerWeek,
        sessionPackage: createData?.sessionPackage,
        currency: createData?.currency,
        couponCode: createData?.couponCode,
        isGift: isGift,
      },
    };
    const selectSessionData = {
      attendeeId: userInfo?.attendeeId,
      languageLevel: createData?.languageLevels,
      productType: createData?.productType === 1 ? 'Private' : 'Group',
      trialStatus: createData?.trialStatus,
      userArea: createData?.userArea,
      learningPurposes: formatLearningPurposes(createData?.learningPurpose),
      ageId: createData.ageInterval,
      packageOptions: {
        type: 1,
        location: createData?.location?.[0],
        monthDuration: createData?.monthDuration,
        timesPerWeek: createData.timesPerWeek,
        sessionPackage: createData?.sessionPackage,
        currency: createData?.currency,
        couponCode: createData?.couponCode,
        isGift: isGift,
      },
    };
    if (isLoggedIn) {
      await dispatch(
        Actions.orderActions.selectSessionAction(
          isGift ? myData : selectSessionData
        )
      );
      await navigate(orderRouting);
    } else if (!isLoggedIn) {
      dispatch(
        Actions.userPackageActions.createAction({
          ...selectSessionData,
          userArea: createData?.userArea,
        })
      );
      setModalState((prevState) => ({
        ...prevState,
        loginModal: {
          ...prevState.loginModal,
          isOpen: true,
        },
      }));
    }
  };

  const handleModal = (confirm) => {
    if (confirm) {
      setOpenModal(false);
      dispatch(Actions.orderActions.selectSessionAction({})).then(() => {
        if (isGift) {
          handleContinueParent(true);
        } else if (!isGift) {
          if (createData.trialStatus === 0) {
            navigate(speakerRouting, {
              state: { createData, fromNavigateWhere: 'freeTrial' },
            });
          } else if (createData.trialStatus === 1) {
            navigate(speakerRouting, {
              state: {
                createData,
                fromNavigateWhere: 'Subscription-One',
              },
            });
          } else {
            handleContinueParent(false);
          }
        }
      });
    }
    setModalState({
      loginModal: { isOpen: false },
      basketModal: { isOpen: false },
    });
  };

  const onSelectMentor = (e) => {
    if (isGift) {
      if (isCafe) {
        if (createData.location === null) {
          return setError(
            <span className="text-danger">
              {t('onlineGroup.branch_errorr')}
            </span>
          );
        } else {
          if (
            openModal &&
            createData.location !== null &&
            checkOrderItemDetails
          ) {
            return setModalState((prevState) => ({
              ...prevState,
              basketModal: {
                ...prevState.basketModal,
                isOpen: true,
              },
            }));
          } else {
            return handleContinueParent(true);
          }
        }
      } else {
        if (openModal && checkOrderItemDetails) {
          return setModalState((prevState) => ({
            ...prevState,
            basketModal: {
              ...prevState.basketModal,
              isOpen: true,
            },
          }));
        } else {
          return handleContinueParent(true);
        }
      }
    }

    if (isChild !== null) {
      if (isChild && !isKids) {
        return setNotifyMessage(t('onlineGroup.kids_adult_warning'));
      }
      if (!isChild && isKids) {
        return setNotifyMessage(t('onlineGroup.kids_adult_warning'));
      }
    }

    if (createData.languageLevels === null) {
      return setError(
        <span className="text-danger">{t('onlineGroup.language_error')}</span>
      );
    } else if (
      createData.ageIntervalView === 'onlineGroup.age_interval' &&
      isKids
    ) {
      return setError(
        <span className="text-danger">
          {t('onlineGroup.age_interval_errorr')}
        </span>
      );
    } else if (openModal && checkOrderItemDetails) {
      return setModalState((prevState) => ({
        ...prevState,
        basketModal: {
          ...prevState.basketModal,
          isOpen: true,
        },
      }));
    } else if (createData.trialStatus === 0) {
      navigate(speakerRouting, {
        state: { createData, fromNavigateWhere: 'freeTrial' },
      });
    } else if (createData.trialStatus === 1) {
      navigate(speakerRouting, {
        state: {
          createData,
          fromNavigateWhere: 'Subscription-One',
        },
      });
    } else {
      handleContinueParent(false);
    }
  };

  const onChangeText = (e) => {
    e.preventDefault();
    const { value } = e.target;
    setPromoCode({ ...promoCode, couponCode: value });
  };
  const onClickPromo = async (e) => {
    e.preventDefault();
    const selectSessionData = {
      languageLevel: createData?.languageLevels,
      learningPurposes: formatLearningPurposes(createData?.learningPurpose),
      productType: createData?.productType === 1 ? 'Private' : 'Group',
      trialStatus: createData?.trialStatus,
      userArea: createData?.userArea,
      ageId: createData.ageInterval,
      packageOptions: {
        type: 1,
        location: createData?.location?.[0],
        monthDuration: createData?.monthDuration,
        timesPerWeek: createData.timesPerWeek,
        sessionPackage: createData?.sessionPackage,
        currency: createData?.currency,
        couponCode: createData?.couponCode,
        isGift: isGift,
      },
    };
    setCreateData({ ...createData, couponCode: promoCode?.couponCode });
    dispatch(
      Actions.userPackageActions.createAction({
        ...selectSessionData,
        userArea: createData?.userArea,
      })
    );
    calculationFetch(promoCode?.couponCode);
  };

  const onSelectPersonalize = (target, name) => {
    const { id, value, key } = target;
    let ageIntervalView = name !== undefined ? key : undefined;
    setCreateData((prevCreateData) => {
      const updatedCreateData = {
        ...prevCreateData,
        [id || name]: parseInt(value),
        ...(ageIntervalView !== undefined && {
          ageIntervalView: ageIntervalView,
        }),
      };

      searchParams.set(id || name, value);
      navigate(location.pathname + '?' + searchParams.toString(), {
        replace: true,
        state: {
          ...location.state,
          ...updatedCreateData,
        },
      });
      return updatedCreateData;
    });
  };
  function handleMatchingId(min, callBack = false) {
    const minute = parseInt(min);
    const matchingPackage = sessionPackage.find((pkg) => {
      const isOnlinePackage = pkg.displayName.includes('Online');
      const isKidsPackage = pkg.displayName.includes('Kids');
      const isUserAreaOnline = isOnline;
      const isUserAreaKids = isKids;

      if (isUserAreaOnline && isOnlinePackage) {
        return pkg.minute === minute;
      } else if (isUserAreaKids && isKidsPackage) {
        return pkg.minute === minute;
      }
      return false;
    });
    if (matchingPackage) {
      const sessionPackage = matchingPackage.id;
      if (callBack) {
        return sessionPackage;
      } else {
        setCreateData((prevCreateData) => ({
          ...prevCreateData,
          sessionPackage: sessionPackage,
        }));
      }
    }
  }
  const calculationFetch = async (couponCode = '') => {
    const priceData = {
      location: createData.location,
      age: createData.ageInterval,
      package: createData.sessionPackage,
      timesPerWeek: createData.timesPerWeek,
      currencyCode: currency.code,
      couponCode: couponCode,
    };
    dispatch(Actions.priceActions.priceCalculateAction(priceData)).then(
      (res) => {
        setShowLoader(false);
      }
    );
  };

  const handleSelectChange = (target, isMulti = false, takeKey = false) => {
    let name = target.name.charAt(0).toLowerCase() + target.name.slice(1);
    let value = target.value;
    const optionValue = takeKey ? target.key : parseInt(value);

    if (isMulti) {
      if (createData[name]) {
        if (createData[name]?.findIndex((o) => o === optionValue) >= 0) {
          const updatedArray = createData[name].filter(
            (value) => value !== optionValue
          );
          setCreateData({
            ...createData,
            [name]: updatedArray,
          });
          if (updatedArray?.length === 0) {
            const updatedFilter = { ...createData, [name]: null };
            setCreateData(updatedFilter);
          } else {
            setCreateData({
              ...createData,
              [name]: updatedArray,
            });
          }
        } else {
          const updatedArray = createData[name];
          updatedArray.push(optionValue);

          setCreateData({
            ...createData,
            [name]: updatedArray,
          });
        }
      } else {
        setCreateData({
          ...createData,
          [name]: [optionValue],
        });
      }
    }

    if (!isMulti) {
      if (createData[name] === optionValue) {
        const { [[name]]: _, ...updatedBadgeCount } = createData;
        setCreateData(updatedBadgeCount);
      } else {
        setCreateData({
          ...createData,
          [name]: optionValue,
        });
      }
    }
  };

  function formatLearningPurposes(learningPurposes) {
    if (!learningPurposes || learningPurposes.length === 0) {
      return '2';
    }

    return learningPurposes.join(',');
  }
  const selectedLanguageLevels = [];
  if (levelGroup && levelGroup?.adult && levelGroup?.kids) {
    if (createData?.userArea === 2) {
      selectedLanguageLevels.push(
        ...staticDatas.LanguageLevels[levelGroup?.kids]
      );
    } else if (
      createData?.userArea !== 2 &&
      createData?.userArea !== undefined
    ) {
      selectedLanguageLevels.push(
        ...staticDatas.LanguageLevels[levelGroup?.adult]
      );
    }
  }

  let results = [];
  results = sessionPackage?.reduce(function (results, mins) {
    if (
      (mins.person === createData.person &&
        mins.displayName.includes('Online') &&
        isOnline) ||
      (mins.displayName.includes('Kids') && isKids)
    ) {
      (results[mins?.person] = results[mins?.person] || []).push(mins);
    }
    return results;
  }, {});

  useEffect(() => {
    if (myState === undefined) {
      const pathname = location.pathname;
      navigate(pathname.substring(0, pathname.lastIndexOf('/')), {
        replace: true,
      });
    }
  }, [myState]);

  useEffect(() => {
    if (currency.code !== undefined && sessionPackage.length > 0) {
      const minute = parseInt(searchParams.get('minute')) || (isKids ? 30 : 60);
      const person = parseInt(searchParams.get('person')) || 1;
      const sessionPackage = handleMatchingId(minute, true);
      const ageId = !isKids
        ? 4
        : parseInt(searchParams.get('ageInterval')) || 1;
      const timesPerWeek = parseInt(searchParams.get('timesPerWeek')) || 1;
      setCreateData((prevCreateData) => {
        const updatedCreateData = {
          ...myState,
          ...prevCreateData,
          timesPerWeek: timesPerWeek,
          person: person,
          ageInterval: ageId,
          sessionPackage: sessionPackage,
          minute: minute,
          currency: currency.code,
          languageLevels:
            location?.state?.languageLevels !== null &&
            location?.state?.languageLevels !== undefined
              ? location?.state?.languageLevels
              : !isLoggedIn
              ? holdFilter?.LanguageLevels || null
              : userInfo.languageLevel,
          learningPurpose: location?.state?.learningPurpose || null,
        };
        if (location.search.includes('ageInterval')) {
          updatedCreateData.ageIntervalView = location?.state?.ageIntervalView;
        }

        return updatedCreateData;
      });
    }
  }, [sessionPackage, currency]);

  useEffect(() => {
    if (
      createData.userArea !== undefined &&
      createData.sessionPackage !== undefined
    ) {
      const updatedFilter = {
        ...createData,
        ...myState,
        languageLevels: isGift
          ? null
          : isChild !== null &&
            ((!isChild && createData.userArea === 2) ||
              (isChild && createData.userArea !== 2))
          ? null
          : createData.languageLevels || null,
      };
      setCreateData(updatedFilter);
    }
  }, [isChild, createData.userArea, createData.sessionPackage]);

  useEffect(() => {
    const pathUrl = location.pathname;
    if (
      createData.sessionPackage !== undefined &&
      !pathUrl.includes('timesPerWeek') &&
      !pathUrl.includes('person') &&
      !pathUrl.includes('minute')
    ) {
      const { timesPerWeek, person, minute } = createData;
      searchParams.set('timesPerWeek', timesPerWeek);
      searchParams.set('person', person);
      searchParams.set('minute', minute);

      navigate(pathUrl + '?' + searchParams.toString(), {
        replace: true,
        state: {
          ...location.state,
          ...createData,
        },
      });
    }
  }, [createData]);

  useEffect(() => {
    if (
      createData?.sessionPackage !== undefined &&
      createData.person !== undefined &&
      createData.minute !== undefined
    ) {
      handleMatchingId(createData.minute, createData.person);
    }
  }, [createData.person, createData.minute]);

  useEffect(() => {
    if (
      myState?.trialStatus === 2 &&
      createData?.sessionPackage !== undefined &&
      currency?.code !== undefined
    ) {
      if (showLoader === null) {
        setShowLoader(true);
        calculationFetch(promoCode?.couponCode);
      } else if (!showLoader && showLoader !== null && selfUpdate === '') {
        setShowLoader(true);
        calculationFetch(promoCode?.couponCode);
      }
    }
  }, [createData?.sessionPackage, currency, createData.timesPerWeek]);

  useEffect(() => {
    if (checkOrderItemDetails) {
      setOpenModal(true);
    }
  }, [checkOrderItemDetails]);

  useEffect(() => {
    if (
      calculationResult?.totalCostItems?.length > 0 &&
      holdFilter?.Person !== undefined
    ) {
      triggerFinalViewItem('begin_view_item');
    }
  }, [calculationResult, holdFilter]);

  useEffect(() => {
    if (sessionPackage.length === 0) {
      dispatch(Actions.sessionPackageActions.getAllAction());
    }
    if (isKids && ageInterval.length === 0) {
      dispatch(Actions.ageIntervalActions.getAllAction(2));
    }
    if (levelGroup === null) {
      dispatch(
        Actions.preferenceActions.getLevelGroupAction(createData?.userArea ?? 0)
      );
    }
  }, []);

  useEffect(() => {
    return () => {
      if (calculationResult?.totalCostItems?.length > 0) {
        triggerFinalViewItem('final_view_item');
      }
      dispatch(Actions.priceActions.cleanState());
      setPromoCode({ couponCode: '' });
      setModalState({
        loginModal: { isOpen: false },
        basketModal: { isOpen: false },
      });
      setCreateData({});
    };
  }, []);

  useEffect(() => {
    if (
      createData?.currency !== undefined &&
      createData?.userArea !== undefined &&
      createData?.trialStatus !== undefined
    ) {
      const filterObject = {
        UserArea: createData.userArea,
        TrialStatus: createData.trialStatus,
        LearningPurpose: createData.learningPurpose,
        LanguageLevels: createData.languageLevels,
        AgeId: createData.ageInterval,
        Locations: createData.location,
        MonthDuration: createData.monthDuration,
        CouponCode: createData.couponCode,
        AgeIntervalView: createData.ageIntervalView,
        Minute: createData.minute,
        TimesPerWeek: createData.timesPerWeek,
        Person: createData.person,
      };
      sessionStorage.setItem(storageKeys.userLevel, createData?.languageLevels);
      dispatch(Actions.filterActions.holdFilter(filterObject));
    }
  }, [createData, calculationResult]);

  const content = (
    <div className="container mb-5 pb-5">
      <div className="d-flex container px-md-5 py-sm-5 py-3 align-items-center justify-content-evenly">
        {isLoggedIn ? (
          <React.Fragment>
            <ProgressLine
              marginLeft={0}
              text={t('progressLine.package')}
              textMarginLeft={0}
              icon={ButtonIcon.CheckCircle}
              iconColor={TextColors.Success}
            />
            <ProgressLine
              marginLeft={0}
              text={t('progressLine.selection')}
              textMarginLeft={0}
              icon={ButtonIcon.CheckCircle}
              iconColor={TextColors.Secondary}
            />
            <ProgressLine
              marginLeft={0}
              text={t('progressLine.pay')}
              textMarginLeft={0}
              lastItem
              icon={ButtonIcon.CheckCircle}
              iconColor={TextColors.Secondary}
            />
          </React.Fragment>
        ) : !isLoggedIn ? (
          <React.Fragment>
            <ProgressLine
              marginLeft={0}
              text={t('progressLine.package')}
              textMarginLeft={0}
              icon={ButtonIcon.CheckCircle}
              iconColor={TextColors.Success}
            />
            <ProgressLine
              marginLeft={0}
              text={t('progressLine.selection')}
              textMarginLeft={0}
              icon={ButtonIcon.CheckCircle}
              iconColor={TextColors.Secondary}
            />
            <ProgressLine
              marginLeft={0}
              text={t('progressLine.login_register')}
              textMarginLeft={0}
              icon={ButtonIcon.CheckCircle}
              iconColor={TextColors.Secondary}
            />

            <ProgressLine
              marginLeft={0}
              text={t('progressLine.pay')}
              lastItem
              textMarginLeft={0}
              icon={ButtonIcon.CheckCircle}
              iconColor={TextColors.Secondary}
            />
          </React.Fragment>
        ) : null}
      </div>
      <div className="d-inline-block py-2">
        <Button
          type={ButtonType.Successbg}
          icon={ButtonIcon.ArrowLeft}
          iconColor={TextColors.Light}
          bold
          onClick={goBack}
        />
      </div>
      <div className="row">
        <div className="col-12">
          <Card
            cardRounded
            body={
              <React.Fragment>
                <div>
                  {isKids && myState?.trialStatus !== 0 && (
                    <div className="d-flex align-items-center">
                      <div className="flex-shrink-0">
                        <FontAwesomeIcon
                          className="text-info"
                          size="2x"
                          icon={faCircleExclamation}
                        />
                      </div>
                      <div className="flex-grow-1 ps-3 pb-1">
                        {t('subscription.private_kids_info_message', {
                          minute: createData?.minute,
                          person: createData?.person,
                        })}
                      </div>
                    </div>
                  )}
                </div>
                {myState?.trialStatus !== 2 ? (
                  <div className="container justify-content-center my-3 d-flex align-middle">
                    <div className="col-lg-6 col-12">
                      <div className="col mx-auto text-center">
                        <HeadTags
                          hSize={5}
                          strong
                          text={t('onlineGroup.personalize')}
                          textColor={TextColors.Custom}
                        />
                      </div>
                      <div className="col-12 py-1 mx-0 px-1 text-nowrap">
                        {t('onlineGroup.learnin_purpose')}
                        <Card
                          cardStyle={{
                            maxHeight: '100px',
                          }}
                          bodyRounded
                          padding={0}
                          outerBackgroud={`border border-1 border-success`}
                          body={
                            <SubscriptionDropdown
                              options={learningPurpose}
                              arrowUp
                              arrowSVG
                              isFilter
                              isMulti
                              id={'learningPurpose'}
                              title={t('onlineGroup.learning_purpose')}
                              handleChange={(target) =>
                                handleSelectChange(target, true)
                              }
                              propNames={{
                                key: 'key',
                                value: 'value',
                                name: 'key',
                              }}
                              selectedOptions={learningPurpose.filter(
                                (service) =>
                                  createData?.learningPurpose?.includes(
                                    parseInt(service.value)
                                  )
                              )}
                            />
                          }
                        />
                      </div>
                      {!isGift && (
                        <div
                          className="col-12 py-1 mx-0 px-1 text-nowrap"
                          onMouseEnter={() => setError(null)}
                        >
                          {t('onlineGroup.language_level')}
                          <Card
                            cardStyle={{
                              maxHeight: '100px',
                            }}
                            bodyRounded
                            padding={0}
                            outerBackgroud={`border border-1 border-success`}
                            body={
                              userInfo.languageLevel === undefined ||
                              userInfo.languageLevel === '' ||
                              (!isChild && createData?.userArea === 2) ||
                              (isChild && createData?.userArea !== 2) ? (
                                <SubscriptionDropdown
                                  id={'languageLevels'}
                                  title={
                                    createData?.languageLevels !== null &&
                                    createData?.languageLevels !== undefined &&
                                    createData?.languageLevels !== ''
                                      ? t(`${createData?.languageLevels}`)
                                      : t('onlineGroup.level_selection')
                                  }
                                  options={selectedLanguageLevels}
                                  propNames={{
                                    key: 'key',
                                    value: 'value',
                                    name: 'key',
                                  }}
                                  arrowUp
                                  isFilter
                                  arrowSVG
                                  addIndex
                                  handleChange={(target) =>
                                    handleSelectChange(target, false, true)
                                  }
                                />
                              ) : (
                                <label className="text-nowrap">
                                  <Tooltip
                                    bottom={10}
                                    textMS={3}
                                    id={'online_onetoone_filter'}
                                    text={
                                      <span className="text-danger">
                                        {t('personalInformation.tooltip')}
                                      </span>
                                    }
                                  >
                                    <SubscriptionDropdown
                                      noClickAble
                                      id={'languageLevels'}
                                      title={t(`${userInfo?.languageLevel}`)}
                                    />
                                  </Tooltip>
                                </label>
                              )
                            }
                          />
                        </div>
                      )}
                      {isKids && (
                        <div
                          className="col-md-12 py-1 mx-0 px-1 text-nowrap"
                          onMouseEnter={() => setError(null)}
                        >
                          {t('onlineGroup.age_group')}
                          <Card
                            cardStyle={{
                              maxHeight: '100px',
                            }}
                            bodyRounded
                            padding={0}
                            outerBackgroud={`border border-1 border-success`}
                            body={
                              <SubscriptionDropdown
                                options={ageInterval}
                                isFilter
                                arrowUp
                                arrowSVG
                                forCalendar
                                id={'ageInterval'}
                                title={t(createData.ageIntervalView)}
                                handleChange={(target) =>
                                  onSelectPersonalize(target, 'ageInterval')
                                }
                                propNames={{
                                  key: 'name',
                                  value: 'value',
                                  name: 'key',
                                }}
                              />
                            }
                          />
                        </div>
                      )}
                      <div className="row mt-1 ms-4 justify-content-center">
                        <div className="col-md-6 py-1 mx-0 px-1 text-nowrap">
                          {error !== null && error}
                        </div>
                      </div>
                      <div className="row mt-2 justify-content-center">
                        <div className="col-md-6 py-1 mx-0 px-1 text-nowrap">
                          <Button
                            textColor={TextColors.Light}
                            text={
                              !isGift
                                ? createData.speakerName
                                  ? t('onlineGroup.select_session')
                                  : t('onlineGroup.select_mentor')
                                : t('onlineGroup.buy_gift')
                            }
                            bold
                            disabled={
                              createData.learningPurpose &&
                              createData.languageLevels
                                ? false
                                : true
                            }
                            type={
                              createData.learningPurpose &&
                              createData.languageLevels
                                ? ButtonType.Successbg
                                : ButtonType.Secondary
                            }
                            buttonCol={12}
                            onClick={onSelectMentor}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="row my-3">
                    <div className="col-md-5 col-12 py-2">
                      <div className="col-12">
                        <h5 className="text-custom mx-lg-auto my-lg-3 py-1">
                          <strong>{t('onlineGroup.personalize')}</strong>
                        </h5>
                      </div>
                      {!isGift && (
                        <div
                          className="col-12 py-1 mx-0 px-1 text-nowrap"
                          onMouseEnter={() => setError(null)}
                        >
                          {t('onlineGroup.language_level')}
                          <Card
                            cardStyle={{
                              maxHeight: '100px',
                            }}
                            bodyRounded
                            padding={0}
                            outerBackgroud={`border border-1 border-success`}
                            body={
                              userInfo.languageLevel === undefined ||
                              userInfo.languageLevel === '' ||
                              (!isChild && createData?.userArea === 2) ||
                              (isChild && createData?.userArea !== 2) ? (
                                <SubscriptionDropdown
                                  options={selectedLanguageLevels}
                                  arrowUp
                                  isFilter
                                  arrowSVG
                                  addIndex
                                  id={'languageLevels'}
                                  title={
                                    createData?.languageLevels !== null &&
                                    createData?.languageLevels !== undefined &&
                                    createData?.languageLevels !== ''
                                      ? t(`${createData?.languageLevels}`)
                                      : t('onlineGroup.level_selection')
                                  }
                                  handleChange={(target) =>
                                    handleSelectChange(target, false, true)
                                  }
                                  propNames={{
                                    key: 'key',
                                    value: 'value',
                                    name: 'key',
                                  }}
                                />
                              ) : (
                                <label className="text-nowrap">
                                  <Tooltip
                                    bottom={10}
                                    textMS={3}
                                    id={'online_onetoone_filter'}
                                    text={
                                      <span className="text-danger">
                                        {t('personalInformation.tooltip')}
                                      </span>
                                    }
                                  >
                                    <SubscriptionDropdown
                                      noClickAble
                                      addIndex
                                      id={'languageLevels'}
                                      title={t(`${userInfo?.languageLevel}`)}
                                    />
                                  </Tooltip>
                                </label>
                              )
                            }
                          />
                        </div>
                      )}
                      <div className="col-12 py-1 mx-0 px-1 text-nowrap">
                        {t('onlineGroup.how_many_days_a_week_do_you_want')}
                        <Card
                          cardStyle={{
                            maxHeight: '100px',
                          }}
                          bodyRounded
                          padding={0}
                          outerBackgroud={`border border-1 border-success`}
                          body={
                            <SubscriptionDropdown
                              options={TimesPerWeek}
                              arrowUp
                              arrowSVG
                              forCalendar
                              title={t('onlineGroup.times_per_week', {
                                perWeek: createData?.timesPerWeek,
                              })}
                              id={'timesPerWeek'}
                              handleChange={(target) => {
                                onSelectPersonalize(target);
                              }}
                              propNames={{
                                value: 'perWeek',
                                name: 'perWeek',
                              }}
                            />
                          }
                        />
                      </div>
                      {!isKids && (
                        <div className="col-md-12 py-1 mx-0 px-1 text-nowrap">
                          {t('onlineGroup.session_duration_selection')}
                          <Card
                            cardStyle={{
                              maxHeight: '100px',
                            }}
                            bodyRounded
                            padding={0}
                            outerBackgroud={`border border-1 border-success`}
                            body={
                              <SubscriptionDropdown
                                options={results[createData?.person]?.sort(
                                  (a, b) => a.minute - b.minute
                                )}
                                id={'minute'}
                                arrowUp
                                arrowSVG
                                forCalendar
                                title={t('onlineGroup.minutes', {
                                  minutes: createData?.minute,
                                })}
                                handleChange={(target) => {
                                  onSelectPersonalize(target);
                                  handleMatchingId(target.value);
                                }}
                                propNames={{
                                  key: 'key',
                                  value: 'minute',
                                  name: 'minute',
                                }}
                              />
                            }
                          />
                        </div>
                      )}
                      {isKids && (
                        <div
                          className="col-md-12 py-1 mx-0 px-1 text-nowrap"
                          onMouseEnter={() => setError(null)}
                        >
                          {t('onlineGroup.age_group')}
                          <Card
                            cardStyle={{
                              maxHeight: '100px',
                            }}
                            bodyRounded
                            padding={0}
                            outerBackgroud={`border border-1 border-success`}
                            body={
                              <SubscriptionDropdown
                                options={ageInterval}
                                arrowUp
                                arrowSVG
                                forCalendar
                                isFilter
                                id={'ageInterval'}
                                title={t(createData.ageIntervalView)}
                                handleChange={(target) =>
                                  onSelectPersonalize(target, 'ageInterval')
                                }
                                propNames={{
                                  key: 'name',
                                  value: 'value',
                                  name: 'key',
                                }}
                              />
                            }
                          />
                        </div>
                      )}
                    </div>
                    <div className="col-md-7 col-12 py-2">
                      <div className="mx-lg-auto ps-lg-5 my-lg-3 py-1">
                        <HeadTags
                          hSize={5}
                          strong
                          text={t('onlineGroup.select_subscription_lenght')}
                          textColor={TextColors.Custom}
                        />
                      </div>
                      {showLoader ? (
                        <div className="d-flex mx-auto align-items-center py-3 justify-content-center">
                          <div className="spinner-border" role="status"></div>
                        </div>
                      ) : (
                        calculationResult?.totalCostItems &&
                        calculationResult?.totalCostItems?.map(
                          (value, index) => (
                            <div
                              key={`subs_div_${index}`}
                              className="ms-lg-5 me-lg-5 py-1"
                            >
                              <Link
                                id={index}
                                className="text-decoration-none"
                                onClick={onSelectSubsLenght}
                                key={`subs_lenght_link_${index}`}
                                tabIndex={index}
                                title={`${value.month || 1} ${t(
                                  'onlineGroup.month'
                                )}  , ${
                                  createData?.timesPerWeek !== undefined
                                    ? createData?.timesPerWeek
                                    : '1'
                                } x ${
                                  createData?.minute !== undefined
                                    ? createData?.minute
                                    : '60'
                                }  ${t('onlineGroup.minute')}, ${
                                  value.pricePerSession
                                }  ${currency.symbol} `}
                              >
                                <Card
                                  bodyRounded
                                  cardStyle={{
                                    maxHeight: '100px',
                                  }}
                                  discountBody={
                                    value.discountPercent &&
                                    value.discountPercent !== 0 ? (
                                      <div className="d-flex justify-content-center">
                                        <label className="d-sm-flex justify-content-center me-sm-1 px-2 rounded-top rounded-4 bg-danger text-white fw-bold">
                                          {value.discountPercent +
                                            value?.couponDiscountPercent}
                                          % {t('onlineGroup.discount')}
                                        </label>
                                      </div>
                                    ) : undefined
                                  }
                                  body={
                                    <div className="d-flex justify-content-between my-3">
                                      <div className="d-sm-flex flex-sm-column">
                                        <div className="text-dark fs-sm-5">
                                          <strong>
                                            {value.month}{' '}
                                            {t('onlineGroup.month')}
                                          </strong>
                                        </div>
                                        <div className="text-dark fs-sm-6 fw-bold">
                                          {value.timesPerWeek * value.month * 4}
                                          x{value.packageMinutes}{' '}
                                          {t('onlineGroup.minute')}
                                        </div>
                                      </div>
                                      <div className="d-sm-flex flex-sm-column">
                                        <div className="text-muted">
                                          <strong className="text-dark fs-sm-5">
                                            {value.netAmount} {currency.symbol}
                                          </strong>{' '}
                                          / {t('onlineGroup.total')}
                                        </div>
                                        <div className="text-muted">
                                          <strong className="text-dark fs-sm-5">
                                            {value.pricePerSession}{' '}
                                            {currency.symbol}
                                          </strong>{' '}
                                          / {t('onlineGroup.session')}
                                        </div>
                                      </div>
                                    </div>
                                  }
                                  outerBackgroud={`border ${
                                    createData?.monthDuration - 1 === index
                                      ? 'border-5'
                                      : 'border-1'
                                  } border-success`}
                                  key={`subs_lenght_card_${index}`}
                                />
                              </Link>
                            </div>
                          )
                        )
                      )}
                    </div>
                    <div className="d-flex ms-sm-5 align-items-center me-sm-5 mt-sm-5">
                      <div className="d-flex">
                        <InputLabelOnBorder
                          id={'couponCode'}
                          labelText={t('onlineGroup.promo_code')}
                          inputType={InputType.Text}
                          inputValue={promoCode?.couponCode}
                          placeholder={t('onlineGroup.promo_code')}
                          onChange={onChangeText}
                          callBack={(e) => onClickPromo(e)}
                        />
                      </div>
                      <div className="d-flex justify-content-end mt-2 ms-1">
                        <SubmitButton
                          stateName={'price'}
                          onClick={onClickPromo}
                          objectLengthRule={
                            promoCode?.couponCode === '' ? true : false
                          }
                        />
                      </div>
                    </div>
                    {calculationResult?.totalCostItems !== undefined && (
                      <div className="container col-md-6">
                        <div className="row mt-4 justify-content-center">
                          <div className="col-12 text-center">
                            <h3>
                              <strong>{t('onlineGroup.order_summary')}</strong>
                            </h3>
                          </div>
                        </div>
                        <div className="row mt-4 border-bottom">
                          <div className="col-8">
                            <div className="text-muted">
                              {
                                calculationResult?.totalCostItems.at(
                                  createData?.monthDuration - 1
                                ).month
                              }{' '}
                              {t('onlineGroup.month_fee')}
                            </div>
                            {calculationResult?.totalCostItems.at(
                              createData?.monthDuration - 1
                            ).discountPercent !== 0 && (
                              <div>
                                <strong>
                                  {
                                    calculationResult?.totalCostItems.at(
                                      createData?.monthDuration - 1
                                    ).discountPercent
                                  }
                                  % {t('onlineGroup.discount')}
                                </strong>
                              </div>
                            )}
                            {calculationResult?.totalCostItems.at(
                              createData?.monthDuration - 1
                            ).couponDiscountPercent !== 0 && (
                              <div>
                                <strong>
                                  {
                                    calculationResult?.totalCostItems.at(
                                      createData?.monthDuration - 1
                                    ).couponCode
                                  }
                                  , {t('onlineGroup.discount')}
                                </strong>
                              </div>
                            )}
                          </div>
                          <div className="col-4">
                            <div className="text-muted text-end">
                              {
                                calculationResult?.totalCostItems.at(
                                  createData?.monthDuration - 1
                                ).totalAmount
                              }{' '}
                              {currency.symbol}
                            </div>
                            {calculationResult?.totalCostItems.at(
                              createData?.monthDuration - 1
                            ).discountPercent !== 0 && (
                              <div className="text-muted text-end">
                                <strong>
                                  -
                                  {
                                    calculationResult?.totalCostItems.at(
                                      createData?.monthDuration - 1
                                    ).discountAmount
                                  }{' '}
                                  {currency.symbol}
                                </strong>
                              </div>
                            )}
                            {calculationResult?.totalCostItems.at(
                              createData?.monthDuration - 1
                            ).couponDiscountPercent !== 0 && (
                              <div className="text-muted text-end">
                                <strong>
                                  -
                                  {
                                    calculationResult?.totalCostItems.at(
                                      createData?.monthDuration - 1
                                    ).couponDiscountAmount
                                  }{' '}
                                  {currency.symbol}
                                </strong>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="row mt-4 text-center border-bottom">
                          <div className="col">
                            <strong>
                              {
                                calculationResult?.totalCostItems.at(
                                  createData?.monthDuration - 1
                                ).month
                              }{' '}
                              {t('onlineGroup.month_fee')}
                            </strong>
                          </div>
                          <div className="col">
                            <strong>
                              {
                                calculationResult?.totalCostItems.at(
                                  createData?.monthDuration - 1
                                ).netAmount
                              }{' '}
                              {currency.symbol}
                            </strong>
                            <span className="ms-3 fw-bold">
                              {t('orderBasket.total')}
                            </span>
                          </div>
                        </div>
                        <div className="row mt-1 ms-4 justify-content-center">
                          <div className="col-md-6 py-1 mx-0 px-1 text-nowrap">
                            {error !== null && error}
                          </div>
                        </div>
                        <div className="row mt-2 justify-content-center">
                          <div className="col-6 py-1 mx-0 px-1 text-nowrap">
                            <Button
                              textColor={TextColors.Light}
                              text={
                                !isGift
                                  ? createData.trialStatus === 2
                                    ? t('onlineGroup.buy')
                                    : t('onlineGroup.select_mentor')
                                  : t('onlineGroup.buy_gift')
                              }
                              bold
                              type={ButtonType.Successbg}
                              buttonCol={12}
                              onClick={onSelectMentor}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </React.Fragment>
            }
          />
        </div>
      </div>
      <Modal
        key={'basket_removing'}
        isVisible={modalState.basketModal.isOpen}
        styleProps={{ maxWidth: 550 }}
        content={
          <React.Fragment>
            <p>{t('onlineGroup.basket_remove_warning')}</p>
            <div className="d-flex justify-content-evenly">
              <React.Fragment>
                <Button
                  text={t('onlineGroup.cancel')}
                  type={ButtonType.Danger}
                  outline
                  bold
                  onClick={() => handleModal(false)}
                  buttonCol={4}
                />
                <Button
                  text={t('onlineGroup.continue')}
                  type={ButtonType.Success}
                  outline
                  bold
                  onClick={() => handleModal(true)}
                  buttonCol={4}
                />
              </React.Fragment>
            </div>
          </React.Fragment>
        }
        onClose={() => handleModal(false)}
      />
      <Modal
        key={'login_or_register_question'}
        isVisible={modalState.loginModal.isOpen}
        styleProps={{ maxWidth: 500 }}
        title={t('calendar.please_log_in_to_buy_sessions')}
        titleMS={0}
        content={
          <div className="d-flex justify-content-evenly">
            <React.Fragment>
              <Button
                text={t('navbar.log_in')}
                type={ButtonType.Success}
                outline
                bold
                onClick={() => {
                  navigate('/account/login');
                  handleModal(false);
                }}
                buttonCol={4}
              />
              <Button
                text={t('navbar.register')}
                type={ButtonType.Successbg}
                bold
                textColor={TextColors.Light}
                onClick={() => {
                  navigate('/account/register');
                  handleModal(false);
                }}
                buttonCol={4}
              />
            </React.Fragment>
          </div>
        }
        onClose={() => handleModal(false)}
      />
      {promoCode.couponCode !== '' && (
        <Toast
          key={'promoCode'}
          id={'promoCode'}
          stateName={'price'}
          onClose={(val) => {
            if (val) {
              dispatch(Actions.priceActions.cleanState(true));
              setPromoCode({ ...promoCode });
            } else {
              setPromoCode({
                couponCode: '',
              });
              setCreateData({ ...createData, couponCode: null });
              calculationFetch();
            }
          }}
        />
      )}
      {notifyMessage !== '' && (
        <ToastWithParams
          key={'kidsadultissue'}
          id={'kidsadultissue'}
          content={notifyMessage}
          onClose={() => {
            setNotifyMessage('');
          }}
          autoClose={false}
          info={true}
        />
      )}
    </div>
  );
  return useLoader() ? <Loader /> : content;
};
export default PrivatePackage;
