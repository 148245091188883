import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from '../../../components/Common/Button/Button';
import Card from '../../../components/Common/Card/Card';
import HeadTags from '../../../components/Common/HeadTags/HeadTags';
import InputLabelOnBorder from '../../../components/Common/Input/InputLabelOnBorder';
import CustomFileInput from '../../../components/Common/FileInput/FileUpload';
import Toast from '../../../components/Common/Popup/Toast';
import SubmitButton from '../../../components/Common/Button/SubmitButton';
import {
  ButtonIcon,
  ButtonType,
  TextColors,
  InputType,
  ButtonSize,
} from '../../../utils/ComponentEnums';
import Claims from '../../../utils/Claims';
import Actions from '../../../store/redux/actions';
import addDay from '../../../hooks/useAddDay';
import checkClaims from '../../../hooks/useCheckClaims';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const Update = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const campaignd = location?.state?.id;
  const currentUserClaim = useSelector((state) => state.auth.claim);
  const campaign = useSelector((state) => state.campaign.getById);
  const [updateData, setUpdateData] = useState({});
  const [dataChanged, setDataChanged] = useState(true);
  const goBack = () => {
    navigate(-1);
    dispatch(Actions.campaignActions.cleanState());
  };
  const [imageSrc, setImageSrc] = useState('');
  const redirect = '/campaign-list';

  const onChangeText = (e) => {
    e.preventDefault();
    const { id, value } = e.target;
    setUpdateData({ ...updateData, [id]: value });
    if (value) {
      setDataChanged(false);
    }
  };

  const onSave = (e) => {
    e.preventDefault();
    if (Object.keys(updateData).length > 0) {
      dispatch(Actions.campaignActions.updateAction(updateData, campaignd));
    }
  };
  function FetchById() {
    dispatch(Actions.campaignActions.getByIdAction(campaignd));
  }

  useEffect(() => {
    FetchById();
    return () => {
      dispatch(Actions.campaignActions.cleanState());
    };
  }, []);

  useEffect(() => {
    if (Object.keys(campaign).length > 0) {
      setUpdateData({ ...campaign });
    }
  }, [campaign]);

  const onChangeDate = (e) => {
    e.preventDefault();
    const { id, value } = e.target;
    if (value !== '') {
      const [year, month, day] = value.split('-').map(Number);
      const normalDateTime = addDay(
        `00:00`,
        `${day}-${month}-${year}`,
        false,
        false,
        true
      );
      setUpdateData({
        ...updateData,
        [id]: normalDateTime,
        [`${id}View`]: value,
      });
      setDataChanged(false);
    } else {
      setUpdateData((prevState) => {
        const { [id]: _, [`${id}View`]: __, ...rest } = prevState;
        return rest;
      });
      setDataChanged(false);
    }
  };

  const convertToLocalDate = (dateString) => {
    const date = new Date(dateString);
    const userTimezoneOffset = date.getTimezoneOffset() * 60000;
    return new Date(date - userTimezoneOffset);
  };

  useEffect(() => {
    if (Object.keys(campaign).length > 0) {
      const localStartDate = convertToLocalDate(campaign.startDate);
      const yearStart = localStartDate.getFullYear();
      const monthStart = ('0' + (localStartDate.getMonth() + 1)).slice(-2);
      const dayStart = ('0' + localStartDate.getDate()).slice(-2);
      const startDateView = `${yearStart}-${monthStart}-${dayStart}`;

      const localEndDate = convertToLocalDate(campaign.endDate);
      const yearEnd = localEndDate.getFullYear();
      const monthEnd = ('0' + (localEndDate.getMonth() + 1)).slice(-2);
      const dayEnd = ('0' + localEndDate.getDate()).slice(-2);
      const endDateView = `${yearEnd}-${monthEnd}-${dayEnd}`;

      setUpdateData({
        ...campaign,
        startDateView: startDateView,
        endDateView: endDateView,
      });
    }
  }, [campaign]);

  const addDetailRow = () => {
    setUpdateData((prevData) => {
      const maxId = Math.max(
        ...prevData.campaignImages.map((image) => image.id)
      );
      return {
        ...prevData,
        campaignImages: [
          ...prevData.campaignImages,
          {
            id: maxId + 1,
            imageName: '',
            imageSrc: '',
            imageExtension: '',
          },
        ],
      };
    });
  };

  const deleteDetailRow = (index) => {
    const updatedDetails = [...updateData.campaignImages];
    updatedDetails.splice(index, 1);
    setUpdateData({ ...updateData, campaignImages: updatedDetails });
    setDataChanged(false);
  };

  const onChangeDetails = (index, field, value) => {
    const updatedDetails = [...updateData.campaignImages];
    if (field === 'imageSrc') {
      updatedDetails[index]['imageSrc'] = value.base64String;
      updatedDetails[index]['imageExtension'] = value.extension;
      updatedDetails[index]['url'] = value.fileName;
      updatedDetails[index]['imgSrc'] = value.imgSrc;
      setUpdateData({ ...updateData, campaignImages: updatedDetails });
    } else {
      updatedDetails[index][field] = value;
      setUpdateData({ ...updateData, campaignImages: updatedDetails });
    }
    setDataChanged(false);
  };

  const content = (
    <div className="container-fluid">
      <div className="d-flex justify-content-center mt-0 mt-md-5">
        <div className="col-12">
          <Card
            body={
              <React.Fragment>
                <div className="d-sm-flex align-items-center justify-content-start ms-sm-2">
                  <div className="col-12 col-md-1 mt-sm-3">
                    <Button
                      type={ButtonType.Successbg}
                      icon={ButtonIcon.ArrowLeft}
                      iconColor={TextColors.Light}
                      bold
                      onClick={goBack}
                      buttonCol={12}
                    />
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-center mb-4 mt-2 mt-md-0 form-div-border">
                  <HeadTags hSize={5} strong text={t('campaign.update_form')} />
                </div>
                <div className="row">
                  <div className="col-12 col-md-6">
                    <InputLabelOnBorder
                      id={'title'}
                      inputType={InputType.Text}
                      labelText={t('campaign.title')}
                      inputValue={updateData.title || ''}
                      onChange={onChangeText}
                    />
                  </div>
                  <div className="col-12 col-md-6 mt-md-0 mt-2">
                    <InputLabelOnBorder
                      id={'name'}
                      inputType={InputType.Text}
                      labelText={t('common.name')}
                      inputValue={updateData.name || ''}
                      onChange={onChangeText}
                      required
                    />
                  </div>
                  <div className="col-md-6 mt-4">
                    <div className="col-12 d-flex justify-content-center">
                      {
                        <img
                          className={'img-fluid rounded-2 me-3'}
                          src={imageSrc ? imageSrc : updateData.image}
                          alt={imageSrc ? imageSrc : updateData.image}
                          height={90}
                          style={{ maxHeight: '90px' }}
                        />
                      }
                    </div>
                    <CustomFileInput
                      id={'image'}
                      hidden={true}
                      buttonText={t('fileUpload.upload_picture')}
                      textColor={TextColors.Light}
                      fileType={'Image'}
                      buttonColor={ButtonType.Successbg}
                      func={(val) => {
                        if (!val) {
                          setUpdateData({});
                          setImageSrc('');
                          setDataChanged(false);
                        } else {
                          setUpdateData(
                            Object.assign({}, campaign, {
                              image: val.base64String,
                              extension: val.extension,
                              url: val.fileName,
                            })
                          );
                          setImageSrc(val.imgSrc);
                          setDataChanged(false);
                        }
                      }}
                    />
                  </div>
                  <div className="col-12 col-md-6 mt-2 mt-md-2">
                    <InputLabelOnBorder
                      id={'startDate'}
                      inputType={InputType.Date}
                      labelText={t('common.start_date')}
                      inputValue={updateData.startDateView || ''}
                      onChange={onChangeDate}
                    />
                  </div>
                  <div className="col-12 col-md-6 mt-2 mt-md-2">
                    <InputLabelOnBorder
                      id={'endDate'}
                      inputType={InputType.Date}
                      labelText={t('common.end_date')}
                      inputValue={updateData.endDateView || ''}
                      onChange={onChangeDate}
                    />
                  </div>
                  <div className="col-12 mt-4">
                    <label
                      className="ms-2 position-absolute"
                      style={{
                        marginTop: '-1.25rem',
                        fontSize: '.9rem',
                      }}
                    >
                      <span className="h6 small bg-white text-muted px-1">
                        {t('campaign.content')}
                      </span>
                    </label>
                    <CKEditor
                      editor={ClassicEditor}
                      data={
                        dataChanged
                          ? campaign?.content || ''
                          : updateData?.content || ''
                      }
                      onChange={(event, editor) => {
                        const data = editor?.getData();
                        const dataToUpdate = (prevData, data) => {
                          const newData = { ...prevData, content: data };
                          return newData;
                        };
                        setDataChanged(
                          dataToUpdate?.content !== updateData?.content
                            ? false
                            : true
                        );
                        setUpdateData((prevData) =>
                          dataToUpdate(prevData, data)
                        );
                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  <p className="mt-1">{t('campaign.explaining')}</p>
                  {updateData?.campaignImages?.map((detail, index) => (
                    <React.Fragment key={index}>
                      <div className="d-md-flex align-items-center justify-content-md-evenly mt-md-4">
                        <div className="col-12 col-md-4 mt-2 mt-md-0">
                          <InputLabelOnBorder
                            id={`details[${index}].imageName`}
                            inputType={InputType.Text}
                            labelText={t('campaign.contentImage')}
                            inputValue={detail.imageName}
                            onChange={(e) =>
                              onChangeDetails(
                                index,
                                'imageName',
                                e.target.value
                              )
                            }
                          />
                        </div>
                        <div className="col-12 col-md-6 mt-md-2 mt-1">
                          <div className="col-12 d-flex justify-content-center">
                            {
                              <img
                                className={'img-fluid rounded-2 me-3'}
                                src={detail.imgSrc || detail.imageSrc}
                                alt={detail.imgSrc || detail.imageSrc}
                                height={90}
                                style={{ maxHeight: '90px' }}
                              />
                            }
                          </div>
                          <CustomFileInput
                            id={`details[${index}].imageSrc`}
                            key={`details[${index}].imageSrc`}
                            hidden={true}
                            buttonText={t('fileUpload.upload_picture')}
                            textColor={TextColors.Light}
                            buttonColor={ButtonType.Successbg}
                            fileType={'Image'}
                            func={(val) => {
                              onChangeDetails(index, 'imageSrc', val);
                            }}
                          />
                        </div>
                        <div className="col-12 col-md-1 mt-2 mt-md-0">
                          <Button
                            buttonCol={12}
                            type={ButtonType.Danger}
                            size={ButtonSize.Small}
                            icon={ButtonIcon.TrashCan}
                            onClick={() => deleteDetailRow(index)}
                          />
                        </div>
                      </div>
                    </React.Fragment>
                  ))}
                  <div className="col-12 col-md-3 mt-3">
                    <Button
                      type={ButtonType.Primary}
                      buttonCol={12}
                      text={t('campaign.contentImageAdd')}
                      onClick={addDetailRow}
                    />
                  </div>
                  <div className="d-flex justify-content-center justify-content-md-end mt-3">
                    <div className="col-12 col-md-2">
                      <SubmitButton
                        stateName={'campaign'}
                        onClick={onSave}
                        objectLengthRule={dataChanged}
                      />
                    </div>
                  </div>
                </div>
              </React.Fragment>
            }
          />
        </div>
      </div>
      <Toast
        stateName={'campaign'}
        onClose={(val) => {
          if (val) {
            setImageSrc('');
            setUpdateData({});
            dispatch(Actions.campaignActions.cleanState());
            navigate(redirect);
          }
        }}
      />
    </div>
  );

  return checkClaims(
    currentUserClaim,
    Claims.backofficeClaims.banner.update,
    content
  );
};
export default Update;
