import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';

const Toast = ({ stateName, onClose, id, navigate, autoClose = false }) => {
  const { t } = useTranslation();
  const isSuccess = useSelector((state) => state[stateName].isSuccess);
  const validationResponse = useSelector(
    (state) => state[stateName].validationMessage
  );
  const [handleError, setHandleError] = useState('');
  const [stateId, setStateId] = useState(null);

  const customToastStyle = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 999999,
    width: '400px',
  };
  const handleOnClose = (close = false) => {
    onClose(close);
    setHandleError('');
    setStateId(null);
    if (navigate !== undefined && !close) {
      navigate();
    }
  };

  useEffect(() => {
    if (isSuccess !== null) {
      if (isSuccess && validationResponse !== '') {
        setHandleError(
          <div>
            {t(`${validationResponse}`)
              ?.split('{br}')
              ?.map((item, index) => (
                <span
                  key={index}
                  className="d-flex position-relative justify-content-center"
                  style={{ maxWidth: '550px' }}
                >
                  <strong>{item}</strong>
                </span>
              ))}
          </div>
        );
      } else if (!isSuccess && validationResponse !== '') {
        setHandleError(
          <div>
            {t(`${validationResponse}`)
              ?.split('{br}')
              ?.map((item, index) => (
                <span
                  key={index}
                  className="d-flex position-relative justify-content-center"
                  style={{ maxWidth: '550px' }}
                >
                  <strong>
                    {item.includes('required')
                      ? item.split('required').map((part, i) => (
                          <React.Fragment key={i}>
                            {i > 0 && (
                              <span style={{ color: 'red' }}>
                                {t('required')}
                              </span>
                            )}
                            {part}
                          </React.Fragment>
                        ))
                      : item}
                  </strong>
                </span>
              ))}
          </div>
        );
      }
      setStateId(id);
    }
  }, [isSuccess, validationResponse]);

  useEffect(() => {
    const systemError = validationResponse?.includes('.system');
    customToastStyle.width =
      validationResponse?.length < 15 ? '300px' : '400px';
    if (handleError !== '' && !isSuccess && systemError) {
      toast.error(handleError, {
        onClose: () => {
          handleOnClose(false);
        },
        containerId: id,
      });
    } else if (handleError !== '' && !isSuccess) {
      toast.warning(handleError, {
        onClose: () => {
          handleOnClose(false);
        },
        containerId: id,
      });
    } else if (handleError !== '' && isSuccess) {
      toast.success(handleError, {
        onClose: () => {
          handleOnClose(true);
        },
        containerId: id,
      });
    }
  }, [handleError]);

  return (
    handleError !== '' &&
    stateId === id && (
      <React.Fragment key={`${stateName}_component_${id}`}>
        <div className="overlay"></div>
        <ToastContainer
          key={`${stateName}_component_${id}`}
          containerId={id}
          style={customToastStyle}
          limit={1}
          autoClose={
            autoClose && isSuccess
              ? 2000
              : autoClose && !isSuccess
              ? 3000
              : autoClose
          }
          closeOnClick
          theme="light"
        />
      </React.Fragment>
    )
  );
};
export default Toast;
